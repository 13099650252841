

.oh {
    overflow: hidden;
}

.pr {
    position: relative;
}

.z_100 {
    z-index: 100;
}


.tc {
    text-align: center;
}

.bghp:hover, .cw {
    color: #fff;
}

.mg__0 {
    margin: 0;
}

.mt__5 {
    margin-top: 5px;
}

.mb__5 {
    margin-bottom: 5px;
}

.page-head {
    padding: 50px 0;
    color: #ededed;
}

.page-head:before {
    content: '';
    opacity: .5;
    position: absolute;
    background: #000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.page-head h1 {
    font-size: 22px;
    line-height: 20px;
    font-weight: 700;
}

.page_head_cat_heading {
    padding: 29px 0
}

@media (min-width: 768px){
    .page_head_cat_heading {
        padding: 29px 0
    } 
}

.page_head_cat_heading::before {
    background-color: #6ec1cd;
    opacity: 1.0;
}

.page_head_cat_heading .parallax-inner {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    background-position: center center;
}