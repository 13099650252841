.paymentPictureSection {
  display: flex;
  justify-content: space-evenly;
  padding: 40px 0;
}

.paymentPictureSection img {
  width: 165px;
}

@media (max-width: 782px) {
  .paymentPictureSection {
    flex-direction: column;
    display: flex;
    justify-content: center;
    gap: unset;
    padding: 40px 0;
    align-items: center;
  }
  .paymentPictureSection img {
    width: 80%;
  }
}
