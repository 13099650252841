@import "../../css/photography.css";

.wrapper-event-photography{

h2 {
    font-family: "playfair display";
    font-weight: bold;
}

p {
     font-family: "dosis";
     font-size: 1rem;
}
.banner-photography .pl-12 {
    padding-left: 15%;}

    section{
        margin-top: 20px;
    }

.re-cover-event{
 width: 300px;
 font-size: 59px;
 opacity: .8;
}

.php-photography img{
    height: auto;
}

.php-photography h2 {
    width: fit-content;
}
}
