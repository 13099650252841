.banner-container-collections {
    min-height:130px;
    background-color: #6ec1cd;
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner-container-collections h2 {
    color:white;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
}