.dn, .dnt4 {
    display: none;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.in_flex {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
}

.pr {
    position: relative;
}

.lh__1 {
    line-height: 1;
}

.mt__30 {
    margin-top: 30px;
}

.mb__60 {
    margin-bottom: 60px;
}
.mt__40 {
    margin-top: 40px;
}

.mr__10 {
    margin-right: 10px;
}
.mr__5 {
    margin-right: 5px;
}
.ml__5 {
    margin-left: 5px;
}

.fs__50 {
    font-size: 50px;
}

.al_center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.tr {
    text-align: right;
}
.tu {
    text-transform: uppercase;
}
.tc {
    text-align: center;
}

.fl_center {
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
}

.fl_between {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.op__0 {
    opacity: 0;
}

.grid-sizer, .pe_none {
    pointer-events: none;
}

.facl {
    display: inline-block;
    font: 14px/1 fakalles;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.facl-angle-down::before {
    content: '\f107'
}
.pe-7s-close:before {
    content: "\e680";
}

.pegk {
    display: inline-block;
    font-family: Pe-icon-7-stroke;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: #222;
    transition: .3s;
}

a, a:active, a:focus, a:hover {
    text-decoration: none;
    outline: 0;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cat_view a {
    border: 1px solid currentcolor;
    color: #878787;
    --active-view-cl: #878787;
    height: 26px;
    transition: .25s;
}

.cat_view a.view_6, 
.cat_view a.view_list {
    width: 29px;
}

.cat_view a.view_4 {
    width: 42px;
}

.cat_view a.view_3 {
    width: 55px;
}

.cat_view a.view_12 {
    width: 28px;
}

.cat_view a.view_15 {
    width: 68px;
}

.cat_view a.view_2 {
    width: 81px;
}

// .dev_view_cat.dev_desktop a.view_3, 
// .dev_view_cat.dev_tablet a.view_3, 
// .dev_view_cat.dev_mobile a.view_6 {
//     color: #222;
//     --active-view-cl: #222;
// }

.swatch .nt-swatch, a, button, input {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

.cat_view a:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 20px;
    background: currentcolor;
    background: var(--active-view-cl);
    transition: .3s;
}

.cat_view a.view_list:before {
    right: 2px;
    height: 5px;
    width: 23px;
    box-shadow: 0 7.3px 0 currentColor,0 15.1px 0 currentColor;
    box-shadow: 0 7.3px 0 var(--active-view-cl),0 15.1px 0 var(--active-view-cl);
}

.cat_view a.view_6:before {
    box-shadow: 13px 0 0 currentColor,13px 0 0 currentColor;
    box-shadow: 13px 0 0 var(--active-view-cl),13px 0 0 var(--active-view-cl);
}

.cat_view a.view_4:before {
    box-shadow: 13px 0 0 currentColor,26px 0 0 currentColor;
    box-shadow: 13px 0 0 var(--active-view-cl),26px 0 0 var(--active-view-cl);
}

.cat_view a.view_2:before {
    box-shadow: 13px 0 0 currentColor,26px 0 0 currentColor,39px 0 0 currentColor,52px 0 0 currentColor,65px 0 0 currentColor;
    box-shadow: 13px 0 0 var(--active-view-cl),26px 0 0 var(--active-view-cl),39px 0 0 var(--active-view-cl),52px 0 0 var(--active-view-cl),65px 0 0 var(--active-view-cl);
}

.cat_view a.view_3:before {
    box-shadow: 13px 0 0 currentColor,26px 0 0 currentColor,39px 0 0 currentColor;
    box-shadow: 13px 0 0 var(--active-view-cl),26px 0 0 var(--active-view-cl),39px 0 0 var(--active-view-cl);
}

.cat_view a.view_15:before {
    box-shadow: 13px 0 0 currentColor,26px 0 0 currentColor,39px 0 0 currentColor,52px 0 0 currentColor;
    box-shadow: 13px 0 0 var(--active-view-cl),26px 0 0 var(--active-view-cl),39px 0 0 var(--active-view-cl),52px 0 0 var(--active-view-cl);
}

.cat_view a.view_12:before {
    width: 22px;
}

.cat_sortby>a {
    position: relative;
    font-size: 14px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cat_sortby:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    transition: opacity .35s ease-in-out;
    background: #000;
    display: block;
    z-index: 4500;
}

.cat_sortby_js.opended:before {
    pointer-events: auto;
    opacity: .4;
}

.sortby_pick i {
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
}

.cat_sortby_js.opended .sortby_pick i, .nt_lt_fake.opended .header_picker:after, .style__simple .nt_lt_fake.opended>i {
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

.nt_sortby {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: auto;
    box-shadow: 0 1px 5px 2px rgb(0 0 0 / 10%);
    display: block;
    z-index: 5000;
    font-size: 17px;
    visibility: hidden;
    transition: visibility .35s ease-in-out;
    cursor: pointer;
    background: #fff;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    transition: visibility .2s ease-in-out,transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
    text-align: left;
}

.btn_filter, .btn_sidebar, .cat_sortby>a {
    color: #878787;
}

.cat_sortby_js.opended .nt_sortby {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
}

svg.ic_triangle_svg {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
}

svg.ic_triangle_svg {
    display: block;
    top: calc(100% + 3px);
    top: -webkit-calc(100% + 3px);
    right: 20px;
    width: 18px;
    height: 8px;
    z-index: 50;
    -webkit-filter: drop-shadow(0 -2px 2px rgba(0,0,0,.12));
    filter: drop-shadow(0 -2px 2px rgba(0,0,0,.12));
    transition: opacity .25s ease-in-out,transform .25s ease-in-out,visibility .25s ease-in-out,-webkit-transform .25s ease-in-out;
    will-change: transform;
    -webkit-transform: scale(.9);
    transform: scale(.9);
}

.nt_sortby .ic_triangle_svg {
    display: none;
}

.cat_sortby_js.opended svg.ic_triangle_svg, .nt_lt_fake.opended svg.ic_triangle_svg, .nt_lt_fake.opended ul {
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1.001);
    transform: scale(1.001);
    transition: opacity .4s cubic-bezier(0,1,.4,1),transform .4s cubic-bezier(.18,1.25,.4,1),visibility .4s linear,-webkit-transform .4s cubic-bezier(.18,1.25,.4,1);
}

.cat_view a.view-active {
    pointer-events: none;
}

.cat_view a.view-active, .cat_view a:hover, .cat_view.on_list_view_true a.view_list {
    color: #222;
    --active-view-cl: #222;
}

.nt_sortby .h3, .nt_sortby h3 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 15px;
    border-bottom: 1px solid #e1e3e4;
    font-size: 16px;
    color: #222;
}

.wrap_sortby {
    z-index: 50;
    padding: 15px 0;
    max-height: 85vh;
    max-height: calc(100vh - 50px);
    max-height: -webkit-calc(100vh - 50px);
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
}

.wrap_sortby a {
    display: block;
    position: relative;
    padding: 8px 15px;
    font-size: 14px;
    color: #878787;
}

.wrap_sortby a:hover, .wrap_sortby a.selected, .nt_lt_fake ul>li:hover, .nt_lt_fake ul>li.is-selected, .nt_lt_fake ul>li.is-selected-nt {
    background: rgba(204,158,108,.08);
    color: #cc9e6c;
}

.nt-pagination {
    border-top: 1px solid #ddd;
}

.page-numbers {
    display: inline-block;
    list-style: none;
    margin: 10px 0;
    padding: 5px 10px;
}

.page-numbers li {
    float: left;
    margin: 0 10px;
    color: #222;
}

.page-numbers li a {
    color: #222;
}

.page-numbers li a:hover, .page-numbers.current {
    color: #ec0101;
}

@media (min-width: 641px){
    .nt_sortby {
        z-index: 5000;
        position: relative;
        width: auto;
        height: auto;
        -webkit-transform: none!important;
        transform: none!important;
        transition: none;
        box-shadow: none;
        border-radius: 3px;
    }
    .cat_sortby_js.opended:before {
        opacity: 0;
    }
    .nt_sortby .ic_triangle_svg {
        display: block;
    }

    .nt_sortby .h3, .nt_sortby h3 {
        display: none;
    }

    .wrap_sortby {
        position: absolute;
        width: auto;
        height: auto;
        top: 10px;
        left: auto;
        right: 0;
        bottom: auto;
        min-width: 190px;
        box-shadow: 0 1px 5px 2px rgb(0 0 0 / 10%);
        visibility: hidden;
        opacity: 0;
        -webkit-transform: scale(.9);
        transform: scale(.9);
        background: #fff;
        color: #677279;
        z-index: 4;
        cursor: auto;
        border-radius: 3px;
        transition: opacity .25s ease-in-out,transform .25s ease-in-out,visibility .25s ease-in-out,-webkit-transform .25s ease-in-out;
        will-change: transform;
        max-height: none;
        overflow: auto;
    }
    .cat_sortby_js.opended .nt_sortby .wrap_sortby {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1.001);
        transform: scale(1.001);
        transition: opacity .4s cubic-bezier(0,1,.4,1),transform .4s cubic-bezier(.18,1.25,.4,1),visibility .4s linear,-webkit-transform .4s cubic-bezier(.18,1.25,.4,1);
    }
}

@media (min-width: 768px){

    .sr_txt {
        display: inline-block;
    }
    .cat_view .dev_mobile, .mb_img_slide, .mb_img_slide.nt_bg_lz, .sr_txt_mb {
        display: none;
    }
    .cat_view .dev_tablet {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .cat_view .dev_mobile, .mb_img_slide, .mb_img_slide.nt_bg_lz, .sr_txt_mb {
        display: none;
    }

    .cat_sortby>a {
        border: 1px solid #ddd;
        border-radius: 40px;
        padding: 7px 7px 7px 15px;
        min-width: 190px;
    }
}

@media (min-width: 1025px){
    .cat_toolbar {
        margin-bottom: 10px;
        margin-top: 40px;
    }
    .cat_view .dev_desktop {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .cat_view .dev_tablet {
        display: none;
    }
}