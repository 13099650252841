.loading--wrapper{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    background: rgba(0,0,0,0.6);
    img {
        width: auto;
    }
    span{
        display: block;
        color: white;
        font-size: 20px;
    }
    .progress-bar {
        width: 270px;
        margin-top: 11px;
        border-radius: 8px;
        border: 1px solid #000;
        background-color: transparent;
        .progress-bar--inner{
            padding: 0.01em 16px;
            background-color: #fff;
            width: 0%;
            color: #000;
            border-radius: 8px;
            transition: 0.6s all ease-out;
        }
    }
}