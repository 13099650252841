// @import "../css/footer.css";
@import "../css/footer.css";

.footer {
    padding-top: 30px;
    padding-left: 50px
}

.footerLeftContent {
    padding-top: 20px;
    width: 28%;
}

.footerLeftContent h2 {

    font-size: 30px;
    font-family: 'Playfair Display';
    font-size: 23px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.footerLeftIcons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0px;
    width: 100%;
    .icons-container{
        display: flex;
    }
}

.footer{
.information {
    // border: 2px solid black;
    width: 160px;
    height: 80px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #121212;
    margin-bottom: 3rem;
}

.footerIcons {
    width: 60px;
    height: 60px;
}


.contact-info {
    // border: 2px solid black;
    width: 100%;
    height: 290px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 18px;
    // margin-top:25px;
}

p {
    margin-bottom: 10px;
    a{
    color: white;
    }
    a:hover{
        text-decoration: none;
    }
    
}
h4 {
    font-family: 'Playfair Display';
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 20px;

}

.footerLeftContents {
    padding-top: 30px;
    width: 22%;
}

h2 {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    // line-height: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.info-2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 0;
    width: 100%;
    font-size: 17px;
    font-weight: 400;

}


.footerRightContent {
    width: 50%;
    padding-top: 50px;
    margin-bottom: 45px;
}
}

.footer-rights{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}

.footer-rights img{
    width: 36px;
    margin-left: 1rem;
}


@media screen and (max-width:450px ) {
    .footer{
        flex-direction: column;
        justify-items: center;
        padding-left: 20px;
        padding-right: 20px;
        h4,p{
            text-align: center;
        }   
    }
    .footerLeftContent,.footerLeftContents,.footerRightContent{
        width: 100%;
    }
    .footer{
        .footerLeftContent,.footerLeftContents,.footerRightContent{
            width: 100%;
            padding-top: 0;
        }
        .info-2,.info-small{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 0;
            padding-bottom: 0;
        }
        .footerLeftIcons, .contact-info{
            align-items: center;
            text-align: center;
        }
    }

    .footer-rights{
        flex-direction: column;
    }
    .footer-rights p{
        margin-bottom: 0.27rem !important;
    }
}