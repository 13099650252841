// import coverPhoto from "../../../images/images/logo-cover.png"

///////////////////////////////banner style/////////////////////////////
.banner-container {
    font-family: "playfair display";
    font-weight: bold;
    font-size: 59px;
    opacity: 0.9;
    position: relative;
    margin-top: -80px;
}

@media only screen and (max-width: 500px) {
    .banner-container {
        margin-top: 40px;
        min-height: 700px;
    }
}
.banner-container h1 {
    font-family: "playfair display";
    font-weight: bolder;
    font-size: 70px;
    opacity: 0.9;
    background-size: contain;
    position: relative;
    top: -540px;
    margin-left: 100px;
}
@media only screen and (max-width: 500px) {
    .banner-container h1 {
        font-family: "playfair display";
        font-weight: bolder;
        font-size: 50px;
        opacity: 0.9;
        background-size: cover;
        position: relative;
        top: -190px;
        margin-left: 20px;
    }
}

.banner-container h2 {
    font-family: "playfair display";
    font-weight: bold;
    font-size: 50px;
    opacity: 0.9;
    background-size: contain;
    position: relative;
    top: -520px;
    margin-left: 100px;
}

@media only screen and (max-width: 500px) {
    .banner-container h2 {
        font-family: "playfair display";
        font-weight: bold;
        font-size: 30px;
        opacity: 0.9;
        background-size: cover;
        position: relative;
        top: -180px;
        margin-left: 20px;
    }
}

///////////////////////////////////////////////////////////////logoDesign//////////////////
.logo-icons-container {
    display: flex;
    text-align: center;
    position: relative;
    margin-top: -370px;
    justify-content: center;
    overflow-wrap: break-word;

}
.logo-icons-container p{
    font-size: 1rem;

}

@media only screen and (max-width: 500px) {
    .logo-icons-container {
        display: flex;
        flex-direction: column;


    }
}

.logo-icons-container h5 {

    font-family: "playfair display";
    font-weight: bolder;
    font-size: 20px;
    color: #2c2b2b;
    justify-content: center;

}

.card {
    width: 200px;
    height: 200px;
}

.card-body {
    display: flex;
    align-items: center;
}

.d-flex-logo {
    display: flex;
    flex-direction: row;


}

@media only screen and (max-width: 500px) {
    .d-flex-logo {
        display: flex;
        flex-direction: column;

        align-items: center;
    }
}

.rounded-circle {
    border-radius: 50%;
}

.logo-icons-container {
    .icons-container {
        padding: 0px 50px;
        flex-direction: column;
        width: 300px;
        align-items: center;
        display: flex;
    }
}

.icon-image-drawing {
    font-size: 80px;
    width: 100px;
    // opacity: .9;
}

.icon-image {
    // font-size: 80px;
    width: 130px;
    opacity: .9;
    display: flex;
}

/////////////////////////////////////////brand text//////////////////////////////
.text-data-container {
    display: flex;
    padding: 20px;
    justify-content: center;
    width: 1200px;
    margin: auto;
}

@media only screen and (max-width: 500px) {
    .text-data-container {

        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

.text-data-container-font {
    font-size: 18px;
    color: black;
    opacity: .9;
    text-align: justify;

}

.text-data-container h3 {
    font-family: "playfair display";
    font-size: 22px;
    color: #2e2c2c;
    font-size: 1000;
    margin-bottom: 20px;
}

@media only screen and (max-width: 500px) {

    .d-flex-logo,
    .flex-row-logo {
        display: flex;
        flex-direction: column;
   }
}

.heading-logo-text {
    font-family: "playfair display";
    font-weight: bolder;
    font-size: 40px;
    color: #383737;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 30px;
}
@media only screen and (max-width: 500px) {

    .text-data-container h3 {
        font-family: "playfair display";
        font-size: 18px;
        color: #2e2c2c;
        font-size: 900;
        margin-bottom: 20px;
        margin-top: 20px;
        text-align: left;
    }
}

.data-icon {
    margin-right: 20px;
    // margin-top: 10px;
    font-size: 30px;
    margin-top: -10px;
    // background-color: white;
    // height: 30px;
}

@media only screen and (max-width: 500px) {
    .data-icon {
        margin-right: 10px;
        // margin-top: 10px;
        font-size: 20px;
        margin-top: 10px;
        // background-color: white;
        height: 10px;
    }
}

.column-flex-container {
    width: 50%;
}

@media only screen and (max-width: 500px) {
    .column-flex-container {
        width: 100%;
    }
}

.column-flex-contaoner-wrapper {
    display: flex;
    width: 500px;

}

@media only screen and (max-width: 500px) {
    .column-flex-contaoner-wrapper {
        width: 100%;
    }
}

///////////////////////////////////work sample//////////////////////
.work-sample-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.work-sample-container h5 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    color: #2e2c2c;

}


.logo-worksection-img {
    width: 150px
}

.logo-work-container {
    max-width: 800px;
    display: flex;
    margin-top: 60px;
    margin-bottom: 30px;

    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

//////////////////////////////////////////////////
.card-main-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
/// 
.card-container-logo {
    display: flex;
    justify-content: space-evenly;
    max-width: 1440px;

}
@media only screen and (max-width: 500px) {
    .card-container-logo {
        display: flex;
        justify-content: center;
        max-width: 450px;
        flex-direction: column;
        width: 100%;
    align-items: center;
    }
}
.card-element-logo {
    width: 450px;
    border: none;
    min-height: 550px;
    box-shadow: 0px 0px 10px 10px #dbd8d8;
    background-color: white;
}
@media only screen and (max-width: 500px) {
    .card-element-logo {
        width: 90%;
        border: none;
        height: auto;
        box-shadow: 0px 0px 5px 5px #cac6c6;

        background-color: white;
    }
}
.card-header {
    background-color: #40c3cf;
    color: white;
    font-family: "playfair display";
    font-weight: bolder;
    font-size: 20px;
    text-align: center;
    height: 70px;
    padding-top: 20px;


}

.card-body-logo {
    background-color: white;
    border-bottom: solid 1px gray;

}

.card-title-logo {
    border-bottom: solid 2px gray;
    width: 100%;
    height: 40px;
    font-family: "playfair display";
    font-weight: bolder;
    font-size: 20px;
    color: black;
    justify-content: center;
    display: flex;
    align-items: center;

}

.card-text-logo {
    color: black;
    opacity: 0.8;
    margin-left: 10px;
    font-size: 18px;
    text-align: justify;
    width: 95%;

}
@media only screen and (max-width: 500px) {
    .card-text-logo {
        text-align: justify;
        width: 95%;
    }
}

.footer-email-button {
    display: flex;
    justify-content: space-around;
    margin-top: auto;
    margin-bottom: auto;
    min-height: 60px;
    align-items: center;


}

.button-style-logo {
    padding: 10px 10px;
    background: white;
    border-radius: 10px;
    border: none;
    color: black;
    opacity: 0.8;
    width: 150px;
    background: #667354;
    color: #fff;
    border: none;
}

.card-footer {
    background-color: white;

}

.bg-secondary {
    background-color: white;
}
.card-one-margin{
    margin-right: 60px;
}
@media only screen and (max-width: 500px) {
    .card-one-margin{
        margin-right: 0px;
}}