.guidelines--wrapper{
.heading-main {
    margin-top: 30px;
  }
  
  .guidelines {
    background: linear-gradient(90deg, #fab5db 0%, #d2cfee 100%);
    // background: linear-gradient(45deg, #FAB5DB, #D2CFEE);
    padding: 30px 0px;
    img {
      width: 100%;
      // width: 300px;
      // height: 350px;
      // object-fit: cover;
    }
  }
  
  .guidelines p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .guidelines-form label {
    display: block;
    font-size: 15px;
    font-weight: 900;
    font-family: "playfair display";
  }
  .guidelines-form select {
    width: 60%;
    background: transparent;
    padding: 10px;
    -webkit-appearance: none;
    border: 2px solid;
    border-radius: 5px;
    margin-bottom: 30px;
    font-family:"dosis", sans-serif;
  }
  .brdr-left {
    border-left: 2px solid;
  }
  .theme-btn {
    background: linear-gradient(45deg, #b76bb3, transparent);
    // display: flex;
    // margin: auto;
    padding: 20px 10px;
    border: none;
    border-radius: 50px;
    box-shadow: 6px 5px 3px -2px #999;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .order-btn {
    margin: 50px auto auto;
  }
}
  @media (max-width: 767px) {
    .guidelines--wrapper{
      .brdr-left {
        border-left: 0px solid;
      }
    }
  }
  
  .guidelines--wrapper{
    .col-md-6{
      h2{
        margin-top: 10px;
        margin-bottom: 10px;
  
      }
    }
  }