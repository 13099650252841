

body {
//   background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.swiper-container {
    float: right;
    width: 600px;
    height: auto;
    // margin-bottom: 10px;
    padding-right: 120px;
    
    
}
@media only screen and (max-width: 500px) {
    .swiper-container {
        float: none;
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        padding-right: 30px;
        padding-left: 30px;
    }
}


.swiper {
  width: 100%;
  height: 880px;
  margin-top: 20px;
//   text-align: right;
  margin-bottom: 0px;
  border-radius: 10px;
//   background: #c1e1d2;
}
@media only screen and (max-width: 500px) {
    .swiper {
        height: 700px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 10px;
    }
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #d1e5fc;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  // width: 150px;
  width: auto;
  height: 400px;
  object-fit: cover;
  display: flex;
  margin: auto;
}
@media only screen and (max-width: 500px) {
    .swiper-slide img {
        display: flex;
        height: 300px;
        width: auto;
        justify-content: space-between;
        // width: auto;
        flex-direction: row;
        // height: auto;
        // align-items: center;
        // justify-content: center;
        // object-fit: contain;
    }
}


.content-new-carusal{
    font-size: 25px;
  color: black;
  opacity: 0.8;
//   bottom: 0px;
//   right: 0px;
  padding: 40px 70px 0px 150px;
  width:800px;
  line-height: 50px;
  margin-top: 40px;
  
}
@media only screen and (max-width: 500px) {
    .content-new-carusal{
    // display: flex;
    font-size: 15px;
    padding: 0px 10px 0px;
  width:90%;
  line-height: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
    }
    }
.heading{
    font-size: 35px;
  color: black;
//   opacity: 0.8;
//   bottom: 0px;
//   right: 0px;
//   padding: 20px 100px 0px;
//   width:900px;
//   line-height: 40px;
  

}
@media only screen and (max-width: 500px) {
    .heading{
    // display: flex;
    font-size: 18px;
    padding: 0px 10px 0px;
  width:90%;
  margin-top: 0px;
    }
    }
.beforeAfter-new-carusal{
    font-size: 30px;
  color: black;
  opacity: 0.8;
//   bottom: 0px;
//   right: 0px;
 
}
@media only screen and (max-width: 500px) {
.beforeAfter-new-carusal{
// display: flex;
font-size: 24px;
}
}

// .image-inner-new-carusal {
//     width: 150px;
   
//   }
//   @media only screen and (max-width: 500px) {
//       .image-inner-new-carusal {
//         width: 150px;
//       }
//   }
// .image-container-new-carusal {
//     animation: slide-up 3s ease-in-out infinite;
//   }
  
//   @keyframes slide-up {
//     from {
//       transform: translateY(0);
//     }
//     to {
//       transform: translateY(-100%);
//     }
//   }
  
  .swiper-pagination-bullet-active{
    background-color: #00e2a1;
  }
  
  .swiper-text-container{
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 500px) {
    .swiper-text-container{
    // display: flex;
    display: block;
    
    }
    }
    