.printing-services--wrapper{
.theme-btn {
    background: linear-gradient(45deg, #b76bb3, transparent);
    display: block;
    padding: 20px 10px;
    border: none;
    border-radius: 50px;
    box-shadow: 6px 5px 3px -2px #999;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .services-img {
    img {
      object-fit: cover;
    }
  }
  img {
    width: 100%;
    // height: 60%;
  }
  
  .lower-sec {
    padding-top: 30px;
  }
  
  .lower-sec span {
    display: block;
    margin-bottom: 5px;
  }
  
  .lower-sec span:nth-child(2) {
    margin-bottom: 25px;
  }
  
  .services-tittle h2 {
    margin: 40px 0px;
    font-family: "Playfair Display";
    font-weight: bold;
    font-size: 50px;
  }
  
  .printing-services {
    background: #fffee8;
    // padding: 20px 0px;
  }
  
  .bg-blue {
    background: #b3e4f5;
  }
  
  .bg-green {
    background: #c8f5e0;
  }
  
  .bg-purple {
    background: #d2ceef;
  }
  
  .services-box {
    box-shadow: 6px 6px 4px 0px #aaa;
    height: 480px;
    margin-bottom: 25px;
  }
  
  .services-box h3 {
    font-size: 35px;
    font-weight: bold;
    font-family: "Playfair Display";
  }
  
  .services {
    border-radius: 80px 80px 0px 160px;
    margin-bottom: 25px;
    height: 650px;
    width: 360px;
  }
  
  .services h3 {
    font-size: 30px;
    padding-left: 20px;
    padding-top: 20px;
    font-weight: bold;
    font-family: "Playfair Display";
  }
  
  .services img {
    border-radius: 80px 80px 0px 0px;
  }
  
  .services p {
    text-align: right;
    padding-right: 10px;
  }
  
  // Ecommerce
  .pt-60 {
    padding-top: 60px;
  }
  
  .img-txt {
    position: absolute;
    right: 15px;
    padding: 5px 15px;
    bottom: 10px;
    font-weight: 900;
    color: #fff;
    background: #a79393;
  }
  
  .img-txt:before,
  .top-img-txt:before {
    content: "\f111";
    padding-right: 10px;
  }
  
  .top-img-txt {
    position: absolute;
    right: 15px;
    padding: 5px 15px;
    top: 180px;
    font-weight: 900;
    color: #fff;
    background: #a79393;
  }
  
  .guidelines {
    background: linear-gradient(45deg, #fab5db, #d2cfee);
    padding: 30px 0px;
  }
  
  .guidelines p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .guidelines-form label {
    display: block;
    font-size: 15px;
    font-weight: 900;
  }
  
  .guidelines-form select {
    width: 60%;
    background: transparent;
    padding: 10px;
    -webkit-appearance: none;
    border: 2px solid;
    border-radius: 5px;
    margin-bottom: 30px;
  }
  
  .brdr-left {
    border-left: 2px solid;
  }
  
  .theme-btn {
    background: linear-gradient(45deg, #b76bb3, transparent);
    display: block;
    padding: 20px 10px;
    border: none;
    border-radius: 50px;
    box-shadow: 6px 5px 3px -2px #999;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .txt-center {
    text-align: center;
  }
  
  .txt-bold {
    font-weight: bold;
  }
  
  /* img {
  width: 100%;
  } */
  .lower-sec {
    padding-top: 30px;
  }
  
  .lower-sec span {
    display: block;
    margin-bottom: 5px;
  }
  
  .lower-sec span:nth-child(2) {
    margin-bottom: 25px;
  }
  
  .services-tittle h1 {
    margin: 40px 0px;
    font-family: "Playfair Display";
    font-weight: bold;
    font-size: 50px;
  }
  
  .printing-services {
    background: #fffee8;
    // padding: 20px 0px;
  }
  
  .bg-blue {
    background: #b3e4f5;
  }
  
  .bg-green {
    background: #c8f5e0;
  }
  
  .bg-lightgreen {
    background: #eaffed;
  }
  
  .bg-purple {
    background: #d2ceef;
  }
  
  .bg-pink {
    background: #ffeaea;
  }
  
  .bg-yellow {
    background: #ecf8a7;
  }
  
  .services-box {
    box-shadow: 6px 6px 4px 0px #aaa;
    margin-bottom: 25px;
    img{
      height: 70%;
    }
  
    .heading-h3{
      text-align: center;
    }
  }
  
  .services-box-tittle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
  }
  
  .services-box-tittle div {
    font-size: 35px;
    font-weight: bold;
    width: 100%;
    font-family: "Playfair Display";
  }
  
  
  .services h3 {
    font-size: 34Pgpx;
    padding-left: 20px;
    font-weight: bold;
    font-family: "Playfair Display";
  }
  
  .services img {
    border-radius: 80px 80px 0px 0px;
  }
  
  .services p {
    text-align: right;
    padding-right: 10px;
  }
  
  .review-box {
    background: #cfe4d2;
    text-align: center;
    padding: 40px 30px;
    border-radius: 10px;
    margin-bottom: 25px;
  }
  
  .review-box p {
    text-align: left;
    font-size: 30px;
    min-height: 220px;
  }
  
  .review-box .checked {
    color: orange;
    font-size: 30px;
  }
  
  .review-box .name {
    display: block;
    font-weight: bold;
    font-weight: 900;
    font-size: 25px;
    padding-top: 20px;
  }
  
  .review-box:before {
    content: "\201c";
    position: absolute;
    font-size: 150px;
    left: 45px;
    top: -80px;
    font-family: "FontAwesome";
    font-weight: bold;
    color: #498352;
  }
  
  .bg-gradient {
    background: radial-gradient(#fff, #dbd6d6);
  }
  
  .editing-services-right {
    padding: 30px;
  }
  
  .editing-services-right h3 {
    font-size: 25px;
    padding: 40px 0px;
    margin: 0px;
    font-weight: bold;
    font-family: "Playfair Display";
  }
  
  .btn-pink {
    margin-top: 50px;
    box-shadow: 6px 6px 4px 0px #aaa;
    background: #f9d7d7;
    display: block;
    padding: 10px;
    margin-left: auto;
    width: 70%;
    margin-right: auto;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    font-weight: bold;
    font-family: "Playfair Display";
  }
  
  .btn-green {
    margin-top: 50px;
    box-shadow: 6px 6px 4px 0px #aaa;
    background: #c5fbcd;
    display: block;
    padding: 10px;
    margin-left: auto;
    width: 70%;
    margin-right: auto;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    font-weight: bold;
    font-family: "Playfair Display";
  }
  
  .btn-yellow {
    margin-top: 50px;
    box-shadow: 6px 6px 4px 0px #aaa;
    background: #dce897;
    display: block;
    padding: 10px;
    margin-left: auto;
    width: 70%;
    margin-right: auto;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Playfair Display";
  }
  
  .img-center {
    width: 310px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .mb-20 {
    margin-bottom: 20px;
  }
  
  .bg-darkgreen {
    background: #98f46e;
  }
  
  .b-fam {
    font-family: "Playfair Display";
    font-weight: bold;
  }
  
  .bg-lightpink {
    background: #ffeded;
  }
  
  .photo-delivery h1 {
    padding: 20px 0px;
    font-family: "Playfair Display";
    font-weight: bold;
    font-size: 40px;
  }
  
  .photo-sec-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 20px;
    padding-right: 15px;
  }
  
  .photo-sec-txt {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-top: 35px;
  }
  
  .photo-txt {
    padding-top: 60px;
  }
  
  .retouching-sec-left {
    padding: 40px 20px 20px 20px;
    background: #f4e2f1;
  }
  
  .retouching-sec-right {
    padding: 40px 20px 20px 20px;
    background: #efeeef;
  }
  
  .retouching-sec-left h3,
  .retouching-sec-right h3 {
    padding-top: 30px;
    font-family: "Playfair Display";
    font-weight: bold;
  }
  
  .retouching-sec-left h4,
  .retouching-sec-right h4 {
    font-family: "Playfair Display";
    font-weight: bold;
  }
  
  .retouching-sec-left p,
  .retouching-sec-right p {
    padding: 5px 20px;
    min-height: 160px;
  }
  
  .retouching-btn {
    background: linear-gradient(45deg, #b76bb3, transparent);
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: "Playfair Display";
    padding: 20px 10px;
    border: none;
    border-radius: 50px;
    box-shadow: 7px 7px 3px -2px #999;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .banner h1 {
    font-family: "Playfair Display";
    font-size: 72px;
    font-style: normal;
    font-weight: 800;
    line-height: 80px;
    letter-spacing: 0em;
  }
  
  .banner p {
    font-family: "Dosis";
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
  }
  
  .pt-60 {
    padding-top: 60px;
  }
  
  .img-txt {
    position: absolute;
    right: 15px;
    padding: 5px 15px;
    bottom: 10px;
    font-weight: 900;
    color: #fff;
    background: #a79393;
  }
  
  .img-txt:before,
  .top-img-txt:before {
    content: "\f111";
    padding-right: 10px;
  }
  
  .top-img-txt {
    position: absolute;
    right: 15px;
    padding: 5px 15px;
    top: 180px;
    font-weight: 900;
    color: #fff;
    background: #a79393;
  }
}
  /*--------------Media Query--------*/
  
  @media (max-width: 767px) {
    .printing-services--wrapper{
    .btn-pink {
      width: 100%;
    }
  
    .banner h1 {
      font-size: 40px;
      line-height: 40px;
    }
  
    .banner p {
      padding-bottom: 20px;
    }
    .services {
      width: 100%;
    }
  }
  }
  
  @media (min-width: 992px) {
    .printing-services--wrapper{
    .bg-gradient {
      border-radius: 10px 0px 0px 10px;
    }
  
    .bg-pink,
    .bg-lightgreen,
    .bg-yellow {
      border-radius: 0px 10px 10px 0px;
    }
  
    .editing-services-right {
      height: 310px;
    }
  }
  }
  
  .printing-services--wrapper{
  .photography-services img,
  .printing-services img {
    height: 65%;
  }
  
  a {
    text-decoration: none;
    color: black;
  }
  
  a:link {
    text-decoration: underline;
  }
  
  a:hover {
    text-decoration: none;
  }
  
  .heading-h3{
    text-align: start;
  }
  
  .content{
    margin-top: 20px;
  }
}
  