/////////////////////////////////banner///////////////////////////////////////
.banner-container__cv-page {
    display: flex;
}

.banner-text__cv-page {
    width: 50%;
}

@media (max-width: 500px) {
    .banner-text__cv-page {
        width: 100%;
        position: relative;
        margin-top: 300px;
    }

}

.banner-text__cv-page h1 {
    font-family: "playfair display";
    font-weight: bold;
    font-size: 70px;
    opacity: 0.8;
    padding: 40px 50px 20px;
    line-height: 90px;
}

@media (max-width: 500px) {
    .banner-text__cv-page h1 {

        font-size: 50px;

        padding: 40px 20px 20px;
        line-height: 70px;
    }
}

.banner-text__cv-page p {
    font-family: Dosis, sans-serif;
    font-weight: normal;
    font-size: 1rem;
    opacity: 1;
    padding: 10px 50px;
}

@media (max-width: 500px) {
    .banner-text__cv-page p {
        font-family: Dosis, sans-serif;
        font-weight: normal;
        font-size: 17px;
        opacity: 0.8;
        padding: 10px 20px;
    }
}

.banner-img__cv-page {
    right: 0px;
    position: absolute;
}

///////////////////////////////cards/////////////////////////////////////////////
.card-body-cv{
    border-bottom: 2px solid gray;
    padding: 10px 20px;
    
}
.card-title-cv{
    
    margin-bottom: 0px;
    
}
.card-container-heading__cv-page {
    font-family: "playfair display";
    font-weight: bold;
    font-size: 55px;
    opacity: 0.8;
    text-align: center;
    margin-top: 100px;
    padding: 40px;
}

@media (max-width: 500px) {
    .card-container-heading__cv-page {
        font-family: "playfair display";
        font-weight: bold;
        font-size: 55px;
        opacity: 0.8;
        text-align: center;
        margin-top: 0px;
        padding: 40px;
    }
}

.card-container__cv-page {
    min-height: 300px;
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: start;
    align-items: left;
    @media screen and (max-width: 768px) {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.card-container__cv-page .card-img-top{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100%;
    width: 100%;
}

.card__cv-page {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    // margin-right: 30px;

}

.card-form-container {
    display: flex;
}

@media (max-width: 500px) {
    .card-form-container {
        display: flex;
        flex-direction: column;
        margin: auto;
    }
}

.list-group-item,
.card__cv-page {
    max-width: 280px;
    background-color: #A9C9FF;
background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);



    font-size: 15px;
    margin: 20px 15px;
    border-radius: 10px;
}

@media (max-width: 500px) {
    .card__cv-page {
        min-width: 100%;

        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        border-radius: 10px;
    }
}
.card-display__cv-page {
    max-width: 100%;
    background-color: #A9C9FF;
background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);

    font-size: 15px;
    margin: 20px 15px;
    border-radius: 10px;
}

@media (max-width: 500px) {
    .card-display__cv-page {
        min-width: 100%;

        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        border-radius: 10px;
    }
}
.price__cv-page {
    color: green;
    float: right;
}
.price-isPaid__cv-page {
    float: right;
    color: black;

}

.button__cv-page {
    background-color: rgb(50, 50, 93);
    color: white;
    margin-bottom: 10px;
}

.button__cv-page:hover {
    background-color: rgb(50, 50, 93);
    color: white;
}

.card-container__cv-page{
ul {
    list-style-type: none;
    margin-right: 20px;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
}

.card-body {
    padding-top: 10px;
    padding-bottom: 0px;

}

li {
    list-style-type: none;

    padding-top: 10px;
}
}

///////////////////////////////////////////purchase//////////////////////////////////
.cv-display-container {
    margin-bottom: 30px;

}

@media (max-width: 500px) {
    .cv-display-container {
        margin-bottom: 30px;
        width: 100%;
        margin: auto;

    }
}

.card-container-heading__cv-page-purchase {
    font-family: "playfair display";
    font-weight: bold;
    font-size: 55px;
    opacity: 0.7;
    text-align: center;
    margin-top: 5px;
    padding: 40px;

}

@media (max-width: 500px) {
    .card-container-heading__cv-page-purchase {
        font-family: "playfair display";
        font-weight: bold;
        font-size: 36px;
        opacity: 0.7;
        text-align: center;
        margin-top: 5px;
        padding: 20px;

    }
}

/////////////////////////////PURCHASE FORM ./////////////////////
.form-heading-cv {
    font-size: 20px;
    margin-bottom: 10px;
}

.form-container-cv {
    display: flex;
    flex-direction: column;
}

.input-field-cv {
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    width: 100%;
}

///////////////////////////////form/////////////////
.display-page-form-container {
    width: 50%;
    margin-bottom: 50px;

}

.form-container-cv{
    .formControll {
        display: flex;
        flex-direction: column;
        
        gap: 6px;
        width: 100%;
    }
    
    .formControll label {
        color: #777771;
        font-weight: 700;
        line-height: 1.7em;
        font-family: "playfair display";
    }

     
  .formControll input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  select {
    padding: 10px;
    outline: none;
    border-color: #777771;
    background-color: #ededed;
    color: #000000;
    border-width: 3px;
    border-style: solid;
  }
  
  .formPlaceOrder {
    border-radius: 0.5em !important;
    background-color: #17a8e3;
    color: #ffffff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 60%);
    transition: 1s;
    width: fit-content;
    min-width: 120px;
    max-width: unset;
    height: auto;
    min-height: unset;
    max-height: unset;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 0 10px 0 0;
    padding: 13px 15px;
    border: 0;
    border-radius: 0;
    background-image: unset;
    line-height: 1.45em;
    text-align: center;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
  }
  
  .formPlaceOrder:hover {
    background-color: #008fca;
  }
  
  .formControll input[type="text"]:hover,
  input[type="email"]:hover,
  input[type="number"]:hover,
  input[type="tel"]:hover,
  select:hover {
    background-color: #ededed;
    border: 3px solid #17a8e3;
  }
  
  .formControll input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  input[type="number"]:focus,
  select:focus {
    background-color: #ededed;
    border: 3px solid #17a8e3;
  }
  
  input[type="file"]::file-selector-button {
    border-radius: 0.2em;
    background-color: #1faf97;
    color: #ffffff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 60%);
    transition: 1s;
    width: auto;
    min-width: 120px;
    max-width: unset;
    height: auto;
    min-height: unset;
    max-height: unset;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 0 10px 0 0;
    padding: 13px 15px;
    border: 0;
    border-radius: 0;
    background-image: unset;
    line-height: 1.45em;
    text-align: center;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
  }
  
  input[type="file"]::file-selector-button:hover {
    background-color: #159c85;
  }

  #error-handler {
    display: flex;
    justify-content: center;
    color: #ff4e5d;
  }
}

@media (max-width: 500px) {
    .display-page-form-container {
        width: 80%;
        margin: auto;

    }
}


.cv-page{
.display-none {
    display: none;
  }
  
  .modal-header,.modal-footer{
    border:none
  }
  
  .modal-footer{
    margin-left:auto ;
    margin-right: auto;
    button{
      margin-left:50px;
      padding: 10px 20px;
      background: none;
      outline: none;
    }
  }
  .modal-header{
      h5{
        font-family: 'Playfair Display';
        font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 43px;
  color: #000000;
      }
  }
  .modal-content{
    background: #F2FAE3;
  }
  .modal-body{
    p{
      font-family: 'Dosis';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px
    }
  
    .photo-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      img{
        width: 30%;
      }
    }
  }
}

.cv-card__img:hover .cv-card__btn{
    opacity: 1;
    bottom: 50px;
}

.cv-card__btn{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50%;
    transition: bottom 300ms;
    opacity: 0;
    background-color: rgb(50, 50, 93);
    color: white;
    @media screen and (max-width: 1024px) {
        opacity: 1;
        bottom: 50px;
    }
}

.cv-card__btn:hover{
    color:white;
}