.wrapper__product-page{

.cd {
    color: #222;
}
.fs__18 {
    font-size: 18px;
}
.pl__5 {
    padding-left: 5px;
}
.pr__5 {
    padding-right: 5px;
}
.rtl_false .tooltip_top_left:hover .tt_txt, .rtl_true .tooltip_top_right:hover .tt_txt {
    -webkit-transform: translateX(-100%) translateY(-8px);
    -moz-transform: translateX(-100%) translateY(-8px);
    transform: translateX(-100%) translateY(-8px);
  }
  .rtl_false .tooltip_top_left .tt_txt, .rtl_true .tooltip_top_right .tt_txt {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%);
    margin-left: 12px;
  }
  .rtl_false .tooltip_top_left .tt_txt, .rtl_false .tooltip_top_left::before, .rtl_true .tooltip_top_right .tt_txt, .rtl_true .tooltip_top_right::before {
    bottom: 100%;
    left: 50%;
  }
  .ttip_nt:hover .tt_txt, .ttip_nt:hover::before {
    visibility: visible;
    opacity: 1;
    -webkit-transition-delay: .1s;
    -moz-transition-delay: .1s;
    transition-delay: .1s;
  }
  .ttip_nt .tt_txt {
    background: #383838;
    color: #fff;
  }
  .ttip_nt .tt_txt {
    display: inline-block;
    background: #383838;
    color: #fff;
    padding: 8px 10px;
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
  }
  .ttip_nt .tt_txt, .ttip_nt::before {
    position: absolute;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
      transition-delay: 0s;
  }
  .ttip_nt .tt_txt {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
  .tt_txt {
    font-size: 0;
    opacity: 0;
    visibility: hidden;
  }
  element {
    display: inline-block;
  }
    @media (min-width: 1025px)
  {.atc-slide:not(.btn_full_true) .single_add_to_cart_button, .atc-slide:not(.btn_full_true) .variations_button {
    width: auto;
    min-width: 160px;
    max-width: 100%;
  }}
  .btn_txt_3 .single_add_to_cart_button, .btn_txt_3 .variations_form .shopify-payment-button__button--unbranded {
    text-transform: uppercase;
  }
  .btn_des_1 .add_to_cart_button, .btn_des_1 .out_stock, .btn_des_1 .single_add_to_cart_button, .btn_des_1 .variations_button .nt_add_cp, .btn_des_1 .variations_button .nt_add_w, .btn_des_1 .variations_form .quantity, .btn_des_1 .variations_form .shopify-payment-button__button--unbranded {
    border-radius: 40px !important;
  }
  .single_add_to_cart_button {
    background: #cc9e6c;
    color: #fff;
  }
  .iccl-grid::before {
    content: "\e917";
  }
  .la-angle-right::before {
    content: "\f105";
  }
  .rtl_false .tooltip_bottom_left::before, .rtl_true .tooltip_bottom_right::before {
    border-bottom-color: #383838;
  }
  .rtl_false .tooltip_bottom_left::before, .rtl_true .tooltip_bottom_right::before {
    margin-top: -11px;
    left: calc(50% - 6px);
  }
  .tt_txt, .rtl_true .tooltip_bottom_right .tt_txt {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%);
    margin-left: 12px;
  }
  .rtl_false .tooltip_bottom_left .tt_txt, .rtl_false .tooltip_bottom_left::before, .rtl_true .tooltip_bottom_right .tt_txt, .rtl_true .tooltip_bottom_right::before {
    top: 100%;
    left: 50%;
  }
  .ttip_nt .tt_txt {
    background: #383838;
    color: #fff;
  }
  .ttip_nt .tt_txt {
    display: inline-block;
    background: #383838;
    color: #fff;
    padding: 8px 10px;
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
  }
  .ttip_nt .tt_txt, .ttip_nt::before {
    position: absolute;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
  }
  .rtl_false .tooltip_bottom_left .tt_txt, .rtl_false .tooltip_bottom_left::before, .rtl_true .tooltip_bottom_right .tt_txt, .rtl_true .tooltip_bottom_right::before {
    top: 100%;
    left: 50%;
  }
  .rtl_false .tooltip_bottom_left::before, .rtl_true .tooltip_bottom_right::before {
    margin-top: -11px;
    left: calc(50% - 6px);
  }
  .rtl_false .tooltip_bottom_left::before, .rtl_true .tooltip_bottom_right::before {
    border-bottom-color: #383838;
  }
  .ttip_nt .tt_txt, .ttip_nt::before {
    position: absolute;
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    transition: 0.3s;
  }
  .ttip_nt .tt_txt, .ttip_nt::before {
    position: absolute;
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    transition: 0.3s;
    transition-delay: 0s;
  }
  .rtl_false .tooltip_bottom_left .tt_txt, .rtl_true .tooltip_bottom_right .tt_txt {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%);
    margin-left: 12px;
  }
  .rtl_false .tooltip_bottom_left .tt_txt, .rtl_false .tooltip_bottom_left::before, .rtl_true .tooltip_bottom_right .tt_txt, .rtl_true .tooltip_bottom_right::before {
    top: 100%;
    left: 50%;
  }
  .ttip_nt .tt_txt {
    background: #383838;
    color: #fff;
  }
  .ttip_nt .tt_txt {
    display: inline-block;
    background: #383838;
    color: #fff;
    padding: 8px 10px;
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
  }
  .ttip_nt .tt_txt, .ttip_nt::before {
    position: absolute;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    transition: .3s;
  }
  .ttip_nt .tt_txt {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
  .tt_txt {
    font-size: 0;
    opacity: 0;
    visibility: hidden;
  }
  .rtl_false .tooltip_bottom_left .tt_txt, .rtl_true .tooltip_bottom_right .tt_txt {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
  margin-left: 12px;
}
.rtl_false .tooltip_bottom_left .tt_txt, .rtl_false .tooltip_bottom_left::before, .rtl_true .tooltip_bottom_right .tt_txt, .rtl_true .tooltip_bottom_right::before {
  top: 100%;
  left: 50%;
}
.ttip_nt .tt_txt {
  background: #383838;
  color: #fff;
}
.ttip_nt .tt_txt {
  display: inline-block;
  background: #383838;
  color: #fff;
  padding: 8px 10px;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
}
.ttip_nt .tt_txt, .ttip_nt::before {
  position: absolute;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
}
.ttip_nt .tt_txt {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
.tt_txt {
  font-size: 0;
  opacity: 0;
  visibility: hidden;
}
.la-angle-right::before {
    content: "\f105";
  }
  .facl-angle-right::before {
    content: '\f105';
  }

  

  .carousel-cell:before {
    display: block;
    text-align: center;
    // content: counter(gallery-cell);
    // line-height: 200px;
    font-size: 80px;
    color: white;
  }
  .carousel {
  background: #EEE;
}

.carousel-cell {
  width: 100%;
  height: 100%;
  margin-right: 10px;
  background: #8C8;
  border-radius: 5px;
  counter-increment: gallery-cell;
}
}


.rf--slider  .flickity-prev-next-button {
  z-index: 2;
}

.zoom-image {
	position: relative;
}

.zoom-image::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: transparent;
}

@media only screen and (min-width: 1024px) {
	.zoom-image::before {
		display: none;
	}
}


.tab-icon-wrapper{
  margin-bottom:1em;
}
.BenefitItem{
  display:flex;
  align-items:center;
  min-height: 36px;
  margin-bottom:5px;
}
.BenefitItem__Icon{
  align-self: center;
  justify-self: center;
  margin-right: 14px;
  width:40px;
  height:40px;
}
.BenefitItem__Icon img{
  max-width:100%;
  display:block;
  width:unset !important;
  height:unset !important;
}
.BenefitItem .BenefitItem__Text {
  align-self: center;
  color: #000;
  font-size: 15px;
  line-height: 16px;
}