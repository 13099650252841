.photoPrintingSection {
  padding: 5px;
  height: 495px;
}

.photoPrintingSizesChart {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 80%;
}

.photoSize {
  border-collapse: collapse;
  /* width: 340px; */
  width: 90%;
}

.photoSize td,
.photoSize th {
  border: 1px solid #ddd;
  padding: 8px;
}

.photoSize tr:nth-child(even) {
  background-color: #f2f2f2;
}

.photoSize tr:hover {
  background-color: #ddd;
}

.photoSize th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #384e5a;
  color: white;
}
