.header--wrapper{
  // height: 100px;
// top: 0px;
display: flow-root;
position: sticky;
z-index: 3;
.active
{
  color: #00e2a1;
  background: transparent;

  &:after {
    content: "" !important;
  }
}

.header {
  color: #000000;
  //   font-family: "Lato", sans-serif;
  font-weight: 400;
  font-family:  'Playfair Display',serif;
  background-color: #ffffff;

  li {
    cursor: pointer;
  }
}


.navbar {
  font-family: "Poppins";
  color: black;
  background-color: #ffffff;
  height: 100px;
  padding: 10px;
  width: 100%;
  display: flex;
  text-decoration: none;

  
  button,
  span {
    color: #000000 !important;
  }

  .flex-end {
    justify-content: flex-end;
  }

}

.active {
  color: #00e2a1 !important;
  background: transparent;

  &:after {
    content: "" !important;
  }
}

.navbar-brand {
  font-family: "Alfa Slab One", cursive;
  text-decoration: none;

  h1 {
    font-size: 2rem !important;
    text-decoration: none;

  }
}

.navbar .nav-link {
  font-family: "Playfair Display";
  font-weight: bold;
  width: 187px;
  text-align: center;
  text-decoration: none;
  color: #000000;
}
}
.nested-menu{
  display: none;
  position: absolute;
left: -100%;
min-width: 9.9rem;
}
.dropdown-item:hover .nested-menu{
  display: block;
  position: absolute;
left: -105%;
min-width: 9.9rem;
margin-top: -20px;
}
.dropdown-item-nested{
background-color: #ffffff;
border:.5px solid gray
}
@media (max-width: 767px) {
  .header--wrapper{
    top: 0px;
display: flow-root;
position: sticky;
z-index: 3;
// height: 100px;
  .navbar-brand h1 {
    font-size: 1.4rem !important;
    /* width: 100%; */
    position: relative;
    margin-left: auto;
    margin-right: auto;
    bottom: 23px;
  }

  .navbar {
    height: 80px;
  }

  button {
    position: absolute;
    border: none;
  }

  a {
    width: 100%;
    height:100%
  }
}

}

.header--wrapper{
  top: 0px;
display: flow-root;
position: sticky;
z-index: 3;
.logo {
  font-family: "Playfair Display", serif;
  font-size: 72px !important;
  font-weight: 800 !important;
  line-height: 96px;
  letter-spacing: 0em;
  width: fit-content;
}

.nav-link-typography {
  font-family: "Playfair Display", serif;
  font-size: 18px !important;
  font-weight: 800 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: none;

}



#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  cursor: pointer;

  nav {
  font-family: "Playfair Display", serif;
  font-size: 18px !important;
  font-weight: 800 !important;
    width: 85%;
    height: 100%;
    background: white;
    overflow-y: auto;
    .top-div {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 60px;
      width: 100%;
      border-bottom: 3px solid pink;
      background-color: rgba(0, 0, 0, .05);
    }

    ul {
      height: 100%;
      width: 100%;
      list-style: none;
      padding: 0px;

      li {
        line-height: 60px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);

        a {
          position: relative;
          color: black;
          text-decoration: none;
          font-size: 16px;
          padding-left: 20px;
          font-weight: 500;
          display: block;
          width: 100%;
          height: 100%;

          span {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            font-size: 22px;
            transition: transform 0.4s;
          }
          .inner-icon{
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            font-size: 22px;
            transition: transform 0.4s;

            span{
              position: relative;
              right: unset;
              transform: none;
            }
          }

        }
        a:hover{
          background: rgba(0, 0, 0, .1);
        }

        a.click span::before{
          content: '\f068';
        }
      }

      ul{
        position: static;
        display: none;
        li{
          line-height: 60px;
          border: none;
          border-top: 1px solid rgba(0, 0, 0, .1);
            a{
              font-size: 15px;
              color: #4a4a4a;
              padding-left: 40px;
            }
        }
        ul{
          li{
            a{
              color: grey;
              padding-left: 80px;
            }
          }
        }
      }
      ul.active{
        display: block;
      }
    }

  }
  
  .closebtn {
    position: static;
    border: none;
    background: black;
    height: 50px;
    width: 50px;

    i {
      color: white;
      font-size: 35px;
      -webkit-text-stroke: 1px black;
    }
  }
}
}