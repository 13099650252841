.printing-delivery--wrapper{
.homeSection {
    position: relative;
    height: 100vh;
    width: 100%;
    justify-content: left;
    display: flex;
    align-items: center;
    background-image: url("../../images/images/photos-printing-delivery/photos-printing-delivery-dubai.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-size: 100% 100%;
  }
  
  .homeSection::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    // background-color: rgb(0 0 0 / 36%);
    background-color: transparent;
  }
  
  .homeSectionLeftContent {
    color: white;
    z-index: 1;
    margin: auto 0;
    padding: 25px;
    // display: flex;
    justify-content: center;
  }
  
  .homeSectionLeftContent .heading {
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 63.75px;
    line-height: 57px;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
}
  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .printing-delivery--wrapper{
  .homeSectionRightContent {
    z-index: 1;
    animation: 3s slideInFromLeft;
  }
  
  .homeSectionLeftContent .description {
    font-size: 25px;
    line-height: 28px;
    margin-top: 17px;
    margin-bottom: 39px;
    font-weight: 400;
    z-index: 1;
  }
  
  .homeSectionLeftContent .orderButton {
    width: max-content;
    z-index: 1;
    padding: 15px;
     border: none; 
    background-color: #2b2626;
    color: cornsilk;
    font-size: 20px;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #8d354e, #ffda34);
    outline: none;
  }
  
  .homeContext {
    display: flex;
  }
  
  .homeSectionLeftContent .orderButton:hover {
    background-color: #0000008a;
    cursor: pointer;
  }
  
  .homeSectionRightImage {
    height: 570px;
  }
}
  
  @media (max-width: 815px) {
    .printing-delivery--wrapper{
    .homeSectionRightImage {
      height: 415px;
    }
  }
  }
  
  @media (max-width: 450px) {
    .printing-delivery--wrapper{
    .serviceSectionLeftContent .heading {
      font-weight: 500;
      font-size: 18px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  
    .serviceSectionLeftContent p {
      font-size: 14px;
    }
  }
  }
  
  @media (max-width: 782px) {
    .printing-delivery--wrapper{
    .homeSection {
      height: 100%;
    }
    .homeSectionLeftContent .orderButton {
      width: max-content;
      z-index: 1;
      padding: 15px;
      border: 1px solid white;
      background-color: #2b2626;
      color: white;
      font-size: 20px;
    }
    .homeSectionLeftContent .description {
      font-size: 17px;
      line-height: 28px;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 15px;
      font-weight: 400;
      z-index: 1;
    }
    .homeSectionLeftContent .heading {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 40.75px;
      line-height: 45px;
    }
    .homeSection {
      flex-direction: column;
      z-index: 1;
      padding-top: 70px;
      height: 40vh;
    }
    .homeSectionRightContent {
      display: flex;
      justify-content: center;
      z-index: 1;
    }
    .homeSectionLeftContent {
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      padding: unset;
    }
  }
  }
  
  /*--------------Media Query--------*/
  
  @media (max-width: 767px) {
    .printing-delivery--wrapper{
      .btn-pink {
        width: 100%;
      }
      .banner h1 {
        font-size: 40px;
        line-height: 40px;
      }
      .banner p {
        padding-bottom: 20px;
      }
    }
  }
  @media (min-width: 992px) {
    .printing-delivery--wrapper{
      .bg-gradient {
        border-radius: 10px 0px 0px 10px;
      }
      .bg-pink,
      .bg-lightgreen,
      .bg-yellow {
        border-radius: 0px 10px 10px 0px;
      }
      .editing-services-right {
        height: 310px;
      }
    }
  }
  
  .printing-delivery--wrapper{
    .printing-delivery {
      margin: 0px;
    }
    
    .test {
      margin-bottom: 0px;
      padding: 15px;
    }
    .photoPrintingSizesChart {
      width: 70%;
      margin: auto;
    }
    .tariq {
      width: 80%;
      margin: auto;
    }
    .free-shipping-extra-bold {
      font-family: "Playfair Display", serif;
      font-size: 32px;
      font-weight: 800 !important;
      line-height: 43px;
      letter-spacing: 0em;
    }
    .free-shipping-medium {
      font-family: "Playfair Display", serif;
      font-size: 32px;
      font-weight: 500 !important;
      line-height: 43px !important;
      letter-spacing: 0em !important;
    }
    
    .free-shipping-sub-heading {
      font-family: "Playfair Display", serif;
      font-size: 16px;
      font-weight: 800 !important;
      line-height: 21px;
      letter-spacing: 0em;
    }
    .p-15 {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    
    .container{
      padding-top: 20px;
      padding-bottom:20px;
    }
  }