.selectOptionsCustom-chart {
  padding: 3px;
  padding-left: 0;
  padding-right: 0;
  outline: none;
  border-color: #777771;
  background-color: #ededed;
  color: #000000;
  border-width: 3px;
  border-style: solid;
  min-width: 200px;
  max-width: 445px;
  width: 100%;

  margin: auto;
  display: block;
  height: 50px;
  // padding-left: 20px;
  margin-bottom: 15px;
}

.selectOptionsCustom-chart:hover {
  background-color: #ededed;
  border: 3px solid #17a8e3;
}
.margin-bottom {
  margin-bottom: 70px;
}
@media only screen and (max-width: 500px) {
  .selectOptionsCustom-chart {
    max-width: 300px;
  }
}
@media only screen and (max-width: 500px) {
  .margin-bottom {
    margin-bottom: 30px;
  }
}
