@import "../../css/photography.css";

.wrapper-caricature{

    .banner-caricature-left{
        @media (max-width: 480px){
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

.banner-heading{
    text-align: center;
    font: normal normal 900 50px/40px Playfair Display;
    letter-spacing: 0px;
    color: #707071;
    text-transform: uppercase;

    @media (max-width: 480px){
        font: normal normal 900 33px/34px Playfair Display;
    }
}

.banner-text{
    text-align: center;
    font: normal normal 300 45px/65px Dosis, sans-serif;
    letter-spacing: 0px;
    color: #707071;

    @media (max-width: 480px){
        font: normal normal 300 27px/50px Dosis, sans-serif;
    }
}

.banner-row{
    padding-left: 30px;
    @media (max-width: 480px){
        padding-left: 0;
        margin: 0;
    }
}

.banner-items{
    font-family: "Playfair Display";
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 480px){
        flex-direction: column;
    }
}
.banner-item{
    font: normal normal 900 26px/36px Helvetica Neue;
    letter-spacing: 0px;
    color: #707070;
    text-transform: uppercase;
    margin: 0;

    @media (max-width: 480px){
        font: normal normal 900 20px/26px Helvetica Neue;
        margin-top: 10px;
    }
}

.caricature-head{
    background: #EFEFEF 0% 0% no-repeat padding-box;
    text-align: center;

    h2{
        text-align: center;
        font: normal normal 900 60px/74px Helvetica Neue;
        letter-spacing: 0px;
        color: #707070;

        @media (max-width: 480px){
            font: normal normal 900 36px/40px Helvetica Neue;
        }
    }
}

.caricature-img--wrapper{
    padding: 20px;
}

.caricature-img{
    padding-top: 140%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


p {
     font-family: "dosis";
     font-size: 1rem;
}
.banner-caricature .pl-12 {
    padding-left: 15%;}

    section{
        margin-top: 20px;
    }

.re-cover-event{
 width: 300px;
 font-size: 59px;
 opacity: .8;
}

.php-photography img{
    height: auto;
}

.php-photography h2 {
    width: fit-content;
}

.caricature-btn--wrapper{
    padding: 48px 0;
    text-align: center;
}

.caricature-btn{
    background: #707071 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    font: normal normal 900 22px/35px Playfair Display;
    padding: 1rem 3rem;
    letter-spacing: 0px;
    color: #EFEFEF;
    outline: none;

    @media (max-width: 480px){
        padding: 1rem 2rem;
    }
}

}
