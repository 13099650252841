.countingSection {
  display: flex;
  justify-content: space-evenly;
  height: 464px;
  align-items: center;
  background: black;
  color: white;
}

.countingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.countingValue {
  letter-spacing: 0.5px;
  font-size: 16px;
  line-height: 25px;
  padding: 2%;
}

.countingNumbers {
  font-size: 92px;
  line-height: 92px;
  font-weight: 600;
  margin-bottom: 5px;
  position: relative;
}

@media (max-width: 782px) {
  .countingSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .countingNumbers {
    font-size: 60px;
    line-height: 92px;
    font-weight: 600;
    margin-bottom: 5px;
    position: relative;
  }
}
