.comming-soon-container{
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
        p{
            color: green;
            font-size: 28px;
        }
}