.page-loader {
    width: 100vw;
    height: 80vh;
    margin: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .page-loader--inner {
    width: 8vmax;
    height: 8vmax;
    border-right: 4px solid #000000;
    border-radius: 100%;
    animation: spinRight 800ms linear infinite;
    
    &:before, &:after {
      content: '';
      width: 6vmax;
      height: 6vmax;
      display: block;
      position: absolute;
      top: calc(50% - 3vmax);
      left: calc(50% - 3vmax);
      border-left: 3px solid #000000;
      border-radius: 100%;
      animation: spinLeft 800ms linear infinite;
    }
    
    &:after {
      width: 4vmax;
      height: 4vmax;
      top: calc(50% - 2vmax);
      left: calc(50% - 2vmax);
      border: 0;
      border-right: 2px solid #000000;
      animation: none;
    }
  }
  
  @keyframes spinLeft {
    from {transform:rotate(0deg);}
    to {transform:rotate(720deg);}
  }
  
  @keyframes spinRight {
    from {transform:rotate(360deg);}
    to {transform:rotate(0deg);}
  }