@import "../../css/photography.css";

.wrapper-wedding-photography{
h2 {
    font-family: "playfair display";
    font-weight: bold;
}
p{
    
    font-family: "dosis";
    font-size: 1rem;
}
.row h2{
    font-size: 32px;
}
.row h2.pl-custom{
    padding-left: 40px;
}
.row li{

    font-family: "dosis";
}

.banner-photography .pl-12 {
    padding-left: 12%;}

section{
    margin-top: 20px;
}

.container-photography h2{
    text-align: center;
}
.row .re-cover{
    font-size: 59px;
    opacity: .8;
  }
}
