@import "../../css/photoRetouching.css";
.photo-retouching-wrapper{
.guidelines {
    background: linear-gradient(45deg, #FAB5DB, #D2CFEE);
    padding: 30px 0px;
}

.guidelines p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
}

.guidelines-form label {
    display: block;
    font-size: 15px;
    font-weight: 900;
}

.guidelines-form select {
    width: 60%;
    background: transparent;
    padding: 10px;
    // -webkit-appearance: none;
    border: 2px solid;
    border-radius: 5px;
    margin-bottom: 30px;
}

.brdr-left {
    border-left: 2px solid;
}

.theme-btn {
    background: linear-gradient(45deg, #b76bb3, transparent);
    display: block;
    padding: 20px 10px;
    border: none;
    border-radius: 50px;
    box-shadow: 6px 5px 3px -2px #999;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
}

.txt-center {
    text-align: center;
}

.txt-bold {
    font-weight: bold;
}

img {
    width: 100%;
}

.lower-sec {
    padding-top: 30px;
}

.lower-sec span {
    display: block;
    margin-bottom: 5px;
}

.lower-sec span:nth-child(2) {
    margin-bottom: 25px;
}

.services-tittle h1 {
    margin: 40px 0px;
    font-family: "Playfair Display";
    font-weight: bold;
    font-size: 50px;
}

.printing-services {
    background: #FFFEE8;
    padding: 20px 0px;
}

.bg-blue {
    background: #B3E4F5;
}

.bg-green {
    background: #C8F5E0;
}

.bg-lightgreen {
    background: #EAFFED;
}

.bg-purple {
    background: #D2CEEF;
}

.bg-pink {
    background: #FFEAEA;
}

.bg-yellow {
    background: #ECF8A7;
}

.services-box {
    box-shadow: 6px 6px 4px 0px #aaa;
    margin-bottom: 25px;
}

.services-box-tittle {
    display: flex;
    align-items: center;
    height: 180px;
}

.services-box-tittle div {
    font-size: 35px;
    font-weight: bold;
    width: 100%;
    font-family: "Playfair Display";
}

.services {
    border-radius: 80px 80px 0px 130px;
    height: 500px;
    margin-bottom: 25px;
}

.services h3 {
    font-size: 35px;
    padding-left: 20px;
    font-weight: bold;
    font-family: 'Playfair Display';
}

.services img {
    border-radius: 80px 80px 0px 0px;
}

.services p {
    text-align: right;
    padding-right: 10px;
}

.review-box {
    background: #CFE4D2;
    text-align: center;
    padding: 40px 30px;
    border-radius: 10px;
    margin-bottom: 25px;
}

.review-box p {
    text-align: left;
    font-size: 30px;
    min-height: 220px;
}
.container{
    // margin-top: 20px;

    h2{
        margin: 40px 0px;
        font-weight: bold;
    }
}
.review-box .checked {
    color: orange;
    font-size: 30px;
}

.review-box .name {
    display: block;
    font-weight: bold;
    font-weight: 900;
    font-size: 25px;
    padding-top: 20px;
}

.review-box:before {
    content: "\201c";
    position: absolute;
    font-size: 150px;
    left: 45px;
    top: -80px;
    font-family: 'FontAwesome';
    font-weight: bold;
    color: #498352;
}

.bg-gradient {
    background: radial-gradient(#fff, #dbd6d6);
}

.editing-services-right {
    padding: 30px;
}

.editing-services-right h3 {
    font-size: 25px;
    padding: 40px 0px;
    margin: 0px;
    font-weight: bold;
    font-family: 'Playfair Display';
}

.btn-pink {
    margin-top: 50px;
    box-shadow: 6px 6px 4px 0px #aaa;
    background: #F9D7D7;
    display: block;
    padding: 10px;
    margin-left: auto;
    width: 70%;
    margin-right: auto;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    font-weight: bold;
    font-family: 'Playfair Display';
}

.btn-green {
    margin-top: 50px;
    box-shadow: 6px 6px 4px 0px #aaa;
    background: #C5FBCD;
    display: block;
    padding: 10px;
    margin-left: auto;
    width: 70%;
    margin-right: auto;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    font-weight: bold;
    font-family: 'Playfair Display';
}

.btn-yellow {
    margin-top: 50px;
    box-shadow: 6px 6px 4px 0px #aaa;
    background: #DCE897;
    display: block;
    padding: 10px;
    margin-left: auto;
    width: 70%;
    margin-right: auto;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Playfair Display';
}

.img-center {
    width: 310px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.col-md-6 {
    // padding-right: 0px;
    width:"100%";
    // display: flex;
  }
.mb-20 {
    margin-bottom: 20px;
}

.bg-darkgreen {
    background: #98F46E;
}

.b-fam {
    font-family: 'Playfair Display';
    font-weight: bold;
}

.bg-lightpink {
    background: #FFEDED;
}

.photo-delivery h1 {
    padding: 20px 0px;
    font-family: 'Playfair Display';
    font-weight: bold;
    font-size: 40px;
}

.photo-sec-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 20px;
    padding-right: 15px;
}

.photo-sec-txt {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-top: 35px;
}

.photo-txt {
    padding-top: 60px;
}

.retouching-sec-left {
    padding: 40px 20px 20px 20px;
    background: #F4E2F1;
    height: 600px;
    margin-bottom: 30px;
}

.retouching-sec-right {
    padding: 40px 20px 20px 20px;
    background: #EFEEEF;
    height: 600px;
    margin-bottom: 30px;
}

.retouching-sec-left h3,
.retouching-sec-right h3 {
    padding-top: 30px;
    font-family: 'Playfair Display';
    font-weight: bold;
}

.retouching-sec-left h4,
.retouching-sec-right h4 {
    font-family: 'Playfair Display';
    font-weight: bold;
}

.retouching-sec-left p,
.retouching-sec-right p {
    padding: 5px 20px;
    min-height: 160px;
}

.retouching-btn {
    background: linear-gradient(45deg, #b76bb3, transparent);
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Playfair Display';
    padding: 20px 10px;
    border: none;
    border-radius: 50px;
    box-shadow: 7px 7px 3px -2px #999;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
}


.positioning{
    bottom: 60px;
    position: absolute;
    left: 0;
    right: 0;
    margin:0 auto;
    width: 200px;
}

.positioning2{
    bottom: 60px;
    position: absolute;
    left: 0;
    right: 0;
    margin:0 auto;
    width: 290px; 
}

.banner h1 {
    font-family: "Playfair Display";
    font-size: 72px;
    font-style: normal;
    font-weight: 800;
    line-height: 80px;
    letter-spacing: 0em;
}

.banner p {
    font-family: "Dosis";
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
}

.pt-60 {
    padding-top: 60px;
}

.img-txt {
    position: absolute;
    right: 15px;
    padding: 5px 15px;
    bottom: 10px;
    font-weight: 900;
    color: #fff;
    background: #A79393;
}

.img-txt:before,
.top-img-txt:before {
    content: '\f111';
    padding-right: 10px;
}

.top-img-txt {
    position: absolute;
    right: 15px;
    padding: 5px 15px;
    top: 180px;
    font-weight: 900;
    color: #fff;
    background: #A79393;
}
}

/*---------Photo Retouching-----------*/
.photo-retouching-wrapper{

.photo-retouching h1 {
    font-family: 'Playfair Display';
    font-size: 60px;
    padding-top: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 80px;
    letter-spacing: 0em;
}

.photo-retouching p {
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
}

.photo-retouching .lightpink {
    background: #FAF6F1;
}

.photo-retouching .retouching-btn {
    padding: 10px 20px;
    margin-top: 50px;
}
.hero-area-btn{
    margin-top: 20px !important;
}

.tools {
    background: #EFCEBB;
}

.tools .tools-img {
    text-align: center;
}

.tools .tools-img img {
    width: 72px;
    height: 72px;
    padding: 10px;
}
/*---------Photo Retouching End-----------*/
.margin-left-15 {
    margin-left: 15px;
}

.length-full {
    grid-column-start: 1;
    grid-column-end: 3;

    .custom-dropzone {
        border: 3px solid #777777;
        // background: rgba(0,0,0,.02);
        background-color: #ededed;

        h2 {
            font-weight: normal;
        }
    }
}
}


/*--------- Retouching Form Start -----------*/

.retouching-form-input label {
    display: block;
    font-weight: bold;
    font-size: 15px;
}

.retouching-form-input input {
    width: 100%;
    padding: 5px 20px;
    border: 2px solid;
    border-radius: 5px;
    margin-bottom: 20px;
}

.checkbox-input {
    padding: 20px 0px;
}

.retouching-form-input textarea {
    width: 100%;
    padding: 5px;
    border: 2px solid;
    border-radius: 5px;
    margin-bottom: 20px;
}

p.txt-center {
        font-family: "dosis";
        
      }

/*--------------Media Query--------*/

@media (max-width: 767px) {
    .btn-pink {
        width: 100%;
    }

    .banner h1 {
        font-size: 40px;
        line-height: 40px;
    }

    .banner p {
        padding-bottom: 20px;
    }


    .retouching-sec-left {
        height: 750px;
    }
    
    .retouching-sec-right {
        height: 750px;
    }
    
}

@media (min-width: 992px) {
    .bg-gradient {
        border-radius: 10px 0px 0px 10px;
    }

    .bg-pink,
    .bg-lightgreen,
    .bg-yellow {
        border-radius: 0px 10px 10px 0px;
    }

    .editing-services-right {
        height: 310px;
    }
}

.hero-area-text-retouching{
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1{
        font-size: 72px;
    }
    p{
        margin-bottom: 20px;
        margin-top: 12px;
    }
}
@media (max-width: 767px){
    .retouching-btnn {
  position: relative;
  border: none;
}
}

@media (max-width: 767px){
    .photo-retouching-wrapper{
        h2{
            margin: 40px 0px;
            font-weight: bold;
        }
    }
}