@import "../../css//photography.css";

.img-form{
    .validation-message{
        padding-left: 10px;
        // margin-top: -10px;
    }
    h2{
        font-family: "playfair display";
        font-weight: bold;
    }
}
