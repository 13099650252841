@import "./css/header.css";
@import "./css/footer.css";
// @import "./css/body.css";
// @import "./css/contact.css";
// @import "./css/countingSection.css";
// @import "./css/faq.css";
// @import "./css/home.css";
// @import "./css/paymentPictureSection.css";
// @import "./css/photoDeliveryPage.css";
// @import "./css/photoPrintingSection.css";
// @import "./css/service.css";
// @import "./css/review.css";
// @import "./css/photoPrintingPageNew.css";

@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: "fakalles";
  src: local("fakalles"),
   url("./fonts/fakalles.woff2") format("opentype");
}

@font-face {
  font-family: "Pe-icon-7-stroke";
  src: local('Pe-icon-7-stroke'), url('./fonts/Pe-icon-7-stroke.woff') format("opentype");
}

@font-face {
  font-family: "iconKalles";
  src: local('iconKalles'), url('./fonts/iconKalles.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-weight: inherit;
  font-size: 15px;
  font-size: 1rem;
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(0.5);
  }
}

.whatsAppInformation {
  animation: 2s zoomIn;
  position: fixed;
  bottom: 0px;
  right: -25px;
  z-index: 1
}

.whatsAppIcon {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.stickyCart {
  animation: 2s zoomIn;
  position: fixed;
  bottom: 8px;
  left: 8px;
  z-index: 1
}

.stickyCartIcon {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
}


*{
  font-family: "Dosis", sans-serif;
}
html {
  scroll-behavior: smooth;
}

.full-width-dialog {
  width: inherit !important;
  padding: none;
  // max-width: 100vw !important;
}

.mat-dialog-container {
  display: block;
  padding: 5px !important;
}

// @media (max-width: 550px) {
//   .full-width-dialog {
//     max-width: 100vw !important;
//   }
// }

.txt-center {
  text-align: center;
  font-family: "playfair display";
}
p.txt-center {
  font-family: "dosis";
  margin-top:30px;
}
.txt-bold {
  font-weight: bold;
}
.bg-blue {
  background: #b3e4f5;
}
.bg-green {
  background: #c8f5e0;
}
.bg-lightgreen {
  background: #eaffed;
}
.bg-purple {
  background: #d2ceef;
}
.bg-pink {
  background: #ffeaea;
}
.bg-yellow {
  background: #ecf8a7;
}
.bg-black {
  background: black;
}

.bg-faq {
  background-color: #f7f7f7;
}
.brands-container {
  padding: 0 !important;
}

.playfair-heading-800 {
  font-family: "Playfair Display", serif;
  font-size: 36px !important;
  font-weight: 800 !important;
  line-height: 48px !important;
  letter-spacing: 0em !important;
}
.dosis-600 {
  font-family: "Dosis", sans-serif;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 23px !important;
  letter-spacing: 0em !important;
}

.cover-photo-heading {
  font-family: "Playfair Display", serif;
  font-size: 72px !important;
  font-weight: 800 !important;
  line-height: 96px !important;
  letter-spacing: 0em !important;
}
.cover-photo-content {
  font-family: "Dosis", sans-serif;
  font-size: 24px !important;
  font-weight: 800 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
}

.heading-h2 {
  font-family: "Playfair Display", serif;
  font-size: 48px !important;
  font-weight: 800 !important;
  line-height: 64px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  margin: 25px;
}
.heading-h3 {
  font-family: "Playfair Display", serif;
  font-size: 30px !important;
  font-weight: 800 !important;
  line-height: 43px !important;
  letter-spacing: 0em !important;
  text-align: center;
}
.content {
  font-family: "Dosis", sans-serif;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
}

.margin-30 {
  margin: 30px;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}


@media only screen and (max-width: 767px) {
  .cover-photo-heading {
    font-size: 60px !important;
  }

  .heading-h2{
    font-size: 31px !important;
  }

}

.stripe-field{
  padding: 15px 3px 10px 8px;
  border-bottom: 1px solid rgba(0,0,0,0.12);
}


.thumb--img{
  align-items: center;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 140px;
  justify-content: center;
  margin: 10px;
  max-width: 180px;
  min-height: 140px;
  min-width: 180px;
  padding: 0px 20px;
  position: relative;
  max-width: unset!important;
  min-width: unset!important;
  padding: 0!important;
}

.thumb--img-item{
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100px;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  opacity: 0.8;
  padding-top: 100px;
}

.badge{
  align-items: center;
  background: #bbb;
  border-radius: 50%;
  color: #333;
  cursor: pointer;
  display: flex;
  height: 22px;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 22px;
  opacity: 0;
}

.thumb--img img{
  border-radius: 5px;
  max-height: 100%;
  opacity: .8;
  width: auto;
}

.thumb--img:hover .badge,.thumb--img:focus .badge{
  opacity: 1;
}

.q-counter {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.q-counter input {
  width: 50px;
  border: 0;
  line-height: 30px;
  font-size: 20px;
  text-align: center;
  background: #32325d !important;
  color: #fff !important;
  appearance: none;
  outline: 0;
  padding: 0 !important;
  border: none !important;
}
.q-counter span {
  display: block;
  font-size: 25px;
  padding: 0 10px;
  cursor: pointer;
  color: #32325d;
  user-select: none;
}

.fslightbox-slide-btn{
  background: white !important;
}
.fslightbox-svg-path{
  fill: #000000 !important;
}

.fslightbox-slide-btn-container:hover .fslightbox-svg-path {
  fill: rgba(0,0,0,0.6) !important;
}

@media (min-width: 1025px) {
  .col-lg-15 {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: unset;
  right: 100%;
  // margin-left: .1rem;
  // margin-right: .1rem;
}