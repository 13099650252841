.accordion {
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
  font-size: 29.75px;
  line-height: 25.5px;
  background-color: #f7f7f7;
}

.faqLine {
  margin-top: 0px;
  margin-bottom: 0px;
}

.faqHeading {
  padding-top: 40px;
  font-size: 41.25px;
  line-height: 37.5px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: #444;
  padding-bottom: 40px;
}

.faqSection {
  padding: 0px 110px;
  margin-top: 70px;
  margin-bottom: 70px;
  background-color: #f7f7f7;
}

.active,
.accordion:hover {
  color: blue;
}

.accordion:after {
  content: "\002B";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  border: 2px solid;
  border-radius: 50%;
  padding: 0px 3px;
}

.active:after {
  content: "\2212";
}

.panel {
  padding: 0 18px;
  /* background-color: white; */
  background-color: #f7f7f7;

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

@media (max-width: 782px) {
  .faqSection {
    padding: 0px 20px;
  }
  .accordion {
    color: #444;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    transition: 0.4s;
    font-size: 23px;
    background-color: #f7f7f7;
  }
  .accordion:after {
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
    border: 2px solid;
    border-radius: 50%;
    padding: 0px 5px;
  }
}
