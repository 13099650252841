@import "../../css/faq.css";

.wrapper-dacument-container{
.document-container {
    margin: 0px 60px 30px 60px;

    .inner-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;
    }
}

.p-container {
    width: 55%;
    font-size: 1rem;
}

.img-container {
    width: 40%;

    img {
        width: 100%;
    }
}
}

@media (max-width: 768px) {
    .wrapper-dacument-container{
    .document-container {
        margin: 0px;
    }

    .inner-container {
        margin: 30px;
    }

    .p-container,
    .img-container {
        width: 100% !important;
    }

    .p-container{
        margin-top: 20px;
    }
}
}