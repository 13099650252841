.photo-printing-form--wrapper{
.contactSection {
    // padding: 5px 90px 2em;
  }
  
  .formContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  
  .formControll {
    display: flex;
    flex-direction: column;
  
    gap: 6px;
    width: 100%;
  }
  
  .formControll label {
    color: #777771;
    font-weight: 700;
    line-height: 1.7em;
    font-family: "playfair display";
    margin-top: 10px;
  }
  
  
  .formOptions {
    padding: 7px 0;
    border-width: 3px;
    border-style: solid;
  }
  
  datalist {
    width: 100%;
    background-color: blue;
  }
  
  .selectForm {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  
  .selectOptions option,
  .selectOptionsCustom option {
    font-size: 16px;
    font-weight: 400;
  }

  .selectOptionsCustom{
    padding: 3px;
    padding-left: 0;
    padding-right: 0;
    outline: none;
    border-color: #777771;
    background-color: #ededed;
    color: #000000;
    border-width: 3px;
    border-style: solid;
  }

  .selectOptionsCustom:hover{
    background-color: #ededed;
    border: 3px solid #17a8e3;
  }
  
  .formControll input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  select {
    padding: 10px;
    outline: none;
    border-color: #777771;
    background-color: #ededed;
    color: #000000;
    border-width: 3px;
    border-style: solid;
  }
  
  .formPlaceOrder {
    border-radius: 0.5em !important;
    background-color: #17a8e3;
    color: #ffffff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 60%);
    transition: 1s;
    width: fit-content;
    min-width: 120px;
    max-width: unset;
    height: auto;
    min-height: unset;
    max-height: unset;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 0 10px 0 0;
    padding: 13px 15px;
    border: 0;
    border-radius: 0;
    background-image: unset;
    line-height: 1.45em;
    text-align: center;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
  }
  
  .formPlaceOrder:hover {
    background-color: #008fca;
  }
  
  .formControll input[type="text"]:hover,
  input[type="email"]:hover,
  input[type="number"]:hover,
  input[type="tel"]:hover,
  select:hover {
    background-color: #ededed;
    border: 3px solid #17a8e3;
  }
  
  .formControll input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  input[type="number"]:focus,
  select:focus {
    background-color: #ededed;
    border: 3px solid #17a8e3;
  }
  
  input[type="file"]::file-selector-button {
    border-radius: 0.2em;
    background-color: #1faf97;
    color: #ffffff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 60%);
    transition: 1s;
    width: auto;
    min-width: 120px;
    max-width: unset;
    height: auto;
    min-height: unset;
    max-height: unset;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 0 10px 0 0;
    padding: 13px 15px;
    border: 0;
    border-radius: 0;
    background-image: unset;
    line-height: 1.45em;
    text-align: center;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
  }
  
  input[type="file"]::file-selector-button:hover {
    background-color: #159c85;
  }
  
  .contactHeading {
    font-size: 46.75px;
    color: #444;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 42.5px;
  }
  
  #map {
    height: 400px;
    width: 100%;
  }
}
  
  @media (max-width: 1000px) {
    .photo-printing-form--wrapper{
    .contactSection {
      // padding: 5px 50px 2em;
      padding: 5px 30px;
    }}
  }
  
  @media (max-width: 782px) {
    .photo-printing-form--wrapper{
      .formContainer {
        grid-template-columns: auto;
        grid-gap: 10px;
      }
    
      .selectForm {
        grid-column-start: unset;
        grid-column-end: unset;
      }
    }
  }
  
  @media (max-width: 450px) {
    .photo-printing-form--wrapper{
      .contactSection h3 {
        font-size: 18px;
      }
      .checkbox-container{
        display: flex;
        align-items: center;
        margin-top: 10px;
        label{
          margin-top: 0px;
          width: 220px;
        }
      }
    }
  }
  
  // payment
  .photo-printing-form--wrapper{
    .form-row {
      width: 70%;
      float: left;
      background-color: #ededed;
    }
    
    #card-element {
      background-color: transparent;
      height: 40px;
      border-radius: 4px;
      border: 1px solid transparent;
      box-shadow: 0 1px 3px 0 #e6ebf1;
      -webkit-transition: box-shadow 150ms ease;
      transition: box-shadow 150ms ease;
    }
    
    #card-element--focus {
      box-shadow: 0 1px 3px 0 #cfd7df;
    }
    
    #card-element--invalid {
      border-color: #fa755a;
    }
    
    #card-element--webkit-autofill {
      background-color: #fefde5 !important;
    }
    
    #submitbutton,
    #tap-btn {
      align-items: flex-start;
      background-attachment: scroll;
      background-clip: border-box;
      background-color: rgb(50, 50, 93);
      background-image: none;
      background-origin: padding-box;
      background-position-x: 0%;
      background-position-y: 0%;
      background-size: auto;
      border-bottom-color: rgb(255, 255, 255);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-bottom-style: none;
      border-bottom-width: 0px;
      border-image-outset: 0px;
      border-image-repeat: stretch;
      border-image-slice: 100%;
      border-image-source: none;
      border-image-width: 1;
      border-left-color: rgb(255, 255, 255);
      border-left-style: none;
      border-left-width: 0px;
      border-right-color: rgb(255, 255, 255);
      border-right-style: none;
      border-right-width: 0px;
      border-top-color: rgb(255, 255, 255);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-top-style: none;
      border-top-width: 0px;
      box-shadow: rgba(50, 50, 93, 0.11) 0px 4px 6px 0px,
        rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
      box-sizing: border-box;
      color: rgb(255, 255, 255);
      cursor: pointer;
      display: block;
      float: left;
      font-family: "Helvetica Neue", Helvetica, sans-serif;
      font-size: 15px;
      font-stretch: 100%;
      font-style: normal;
      font-variant-caps: normal;
      font-variant-east-asian: normal;
      font-variant-ligatures: normal;
      font-variant-numeric: normal;
      font-weight: 600;
      height: 35px;
      letter-spacing: 0.375px;
      line-height: 35px;
      margin-bottom: 0px;
      margin-left: 12px;
      margin-right: 0px;
      margin-top: 28px;
      outline-color: rgb(255, 255, 255);
      outline-style: none;
      outline-width: 0px;
      overflow-x: visible;
      overflow-y: visible;
      padding-bottom: 0px;
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 0px;
      text-align: center;
      text-decoration-color: rgb(255, 255, 255);
      text-decoration-line: none;
      text-decoration-style: solid;
      text-indent: 0px;
      text-rendering: auto;
      text-shadow: none;
      text-size-adjust: 100%;
      text-transform: none;
      transition-delay: 0s;
      transition-duration: 0.15s;
      transition-property: all;
      transition-timing-function: ease;
      white-space: nowrap;
      width: 150.781px;
      word-spacing: 0px;
      writing-mode: horizontal-tb;
      -webkit-appearance: none;
      -webkit-font-smoothing: antialiased;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-border-image: none;
    }
    
    // .ng-invalid.ng-touched {
    //   border: 3px #ff4e5d solid;
    // }
    
    #error-handler {
      display: flex;
      justify-content: center;
      color: #ff4e5d;
    }
    
    // card
    
    .submit-button {
      display: flex;
      width: 100%;
      justify-content: center !important;
    }

    .custom-dropzone {
      align-items : center;
      background:#fff;
      border: 2px dashed #717386;
      border-radius: 5px;
      color: #717386;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      height: 180px;
      overflow-x: auto;
    }
    
    .custom-dropzone {
      border: 3px solid #777777;
      // background: rgba(0,0,0,.02);
      background-color: #ededed;
    
      h2 {
        font-weight: normal;
      }
    }
    
    .payment-form {
      // padding-left: 3%;
      // padding-right: 6%;
    
      .brands-container {
        padding: 0 !important;
      }
    }
    
    ::ng-deep {
      .brands-container {
        padding: 0 !important;
      }
    }
    
    .any-comments {
      grid-column-start: 1;
      grid-column-end: 3;
      textarea {
        height: 180px;
        border: 3px solid #777777;
        background-color: #ededed;
        padding: 10px;
        border-radius: 5px;
      }
    }
    
    .margin-left-15 {
      margin-left: 15px;
    }
  }
  
  