@import "../../css/home.css";

.banner{
  .hero-area-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    // align-items: center;
    margin-left: 20%;
  }
}
  .banner {
    background: white;
  
    .header-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      h1.heading {
        align-items: center;
        // display: flex;
        // flex-direction: column;
      }
  
      .paragraph {
        // display: flex;
        // justify-content: center;
  
        p.description {
          // width: 50%;
        }
      }
    }
  
    .img-div {
      display: flex;
      justify-content: center;
    }
  
    img {
      transform: rotate(-9deg);
      height: 63vh;
      object-fit: cover;
    }
    .pl-12 {
        padding-left: 12px;
    }
    .row{
        margin-left: 0px;
        margin-right: 0px;
    }
  }
  
  @media (max-width: 450px) {
    .banner {
      img {
        height: 43vh;
      }
  
      .hero-area-text {
        margin-left: 0px;
       margin-top: 10px;
       text-align: center;
      }
    }
  }

.orderButton {
  width: max-content;
    z-index: 1;
    padding: 15px;
    background-color: #2b2626;
    color: revert;
    font-size: 22px;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #FFF108, #c7cac2);;
    padding: 20px;
    /* border-color: blueviolet; */
    border: none;
    outline: none;
}
.heading-title {
  h1 {
    font-size: 3rem;
  }
}
.delivery {
  background-color: #378c84;

  color: white;
  font-family: "Poppins";
  .ourServiceSection {
    display: flex;
    padding: 30px;
  }

  .serviceSectionLeftContent {
    padding: 30px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .serviceHeading {
    color: #444;
    font-size: 46.75px;
    padding-bottom: 0px;
    line-height: 42.5px;
    margin-top: 27px;
    padding: 13px 0;
    font-weight: bold;
    letter-spacing: -5px;
    width: 100%;
    display: flex;
    justify-content: center;
    letter-spacing: 1px;
  }

  .serviceSectionLeftContent .head {
    // color: white;
    font-family: 'Playfair Display';
    font-size: 46.75px;
    line-height: 42.5px;
    margin-top: 27 px;
    padding: 13 px 0;
    font-weight: bold;
    letter-spacing: -5px;
    width: 100%;
    display: flex;
    justify-content: center;
    letter-spacing: 1px;
  }

  .serviceSectionRightContent {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .serviceSectionRightContent .sameDayDeliveryImg {
    height: 251px;
  }

  * {
    box-sizing: border-box;
    // align-items: center;
  }

  .column {
    cursor: pointer;
    box-shadow: 0 1px 1px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 12%),
      0 4px 4px rgb(0 0 0 / 12%), 0 8px 8px rgb(0 0 0 / 12%),
      0 16px 16px rgb(0 0 0 / 12%);
    max-width: 600px;
    width: 100%;
    position: relative;
    justify-content: space-around;
    display: flex;
    align-items: center;
    height: 300px;
  }

  .column::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgb(0 0 0 / 36%);
  }

  /* Remove extra left and right margins, due to padding */
  .row {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 21px;
    padding: 28px 10px;
  }

  .card {
    z-index: 1;
    color: white;
    padding: 16px;
    text-align: center;
    /* min-width: 350px; */
  }

  .card h3 {
    color: #ffffff;
    font-family: "Lato", Sans-serif;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 3px;
    text-shadow: 0px 0px 19px rgb(0 0 0 / 30%);
  }

  @media (max-width: 990px) {
    .row {
      display: grid;
      grid-template-columns: auto auto;
    }
  }

  @media (max-width: 782px) {
    .serviceSectionLeftContent .head {
      // color: white;
      font-size: 31px;
    }
    .ourServiceSection {
      flex-direction: column;
    }
    .formContainer {
      grid-template-columns: repeat(auto-fill, 390px);
      grid-gap: 10px;
    }
    .serviceSectionLeftContent {
      /* padding: 20px; */
    }
    .serviceSectionRightContent .sameDayDeliveryImg {
      height: 250px;
    }
    .row {
      display: grid;
      grid-template-columns: auto;
      margin: 0px 109px;
    }
  }

  @media (max-width: 550px) {
    .serviceSectionRightContent .sameDayDeliveryImg {
      height: 170px;
    }
  }
  @media (max-width: 330px) {
    .serviceSectionRightContent .sameDayDeliveryImg {
      height: 120px;
    }
  }
}