.bg-circle {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: #EAFFED;
    top: 0;
    border-radius: 50%;
}

.collection-image .main-img{
    background-size: contain;
    position: relative;
    padding-top: 100%;
}

.collection-info{
    margin-top: 1rem;
}

.collection-title{
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
}