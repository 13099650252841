.wrapper__product-info{
@media (min-width: 1230px)
     { .container {
        max-width: 1200px;
      }}
      .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
      }
      @media (min-width: 768px)
     { .sp-tabs {
        padding-top: 50px;
      }}
      
      @media (min-width: 768px)
     { .sp-single-3 .p-thumb .p-item.last_visible, .ul_tabs.des_mb_2 {
        margin-bottom: 0;
      }}
      .ul_none {
        list-style: none;
      }
      .is-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }
      .fl_center {
        justify-content: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        -ms-justify-content: center;
      }
      .fs__16 {
        font-size: 16px;
      }
      ul {
        list-style: inside;
      }
      .des_style_1.sp-tab {
        padding: 25px 0;
        -webkit-animation: 1s ani-fadeIn;
        animation: 1s ani-fadeIn;
      }
      .bgbl {
        background: #f6f6f8;
      }
      .dn, .dnt4 {
        // display: none;
      }
      .fl_between {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;
      }
      .al_center {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
      .flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .fwm {
        font-weight: 500;
      }
      .cd {
        color: #222;
      }
      .pr {
        position: relative;
      }
      .swatch .nt-swatch, a, button, input {
        -ms-touch-action: manipulation;
        touch-action: manipulation;
      }
      @media (min-width: 768px)
      {.tab-heading .txt_h_tab {
        padding: 10px 20px;
      }}
      .tab-heading .txt_h_tab {
        padding: 10px;
      }
      .tab-heading .nav_link_icon {
        width: 44px;
        height: 44px;
        background-color: #222;
        color: #fff;
      }
      .nav_link_icon {
        width: 12px;
        height: 12px;
        position: relative;
        display: block;
        ms-flex: 0 0 auto;
        flex: 0 0 auto;
      }
      .ml__5 {
        margin-left: 5px;
      }
      @media (min-width: 1025px)
      {.des_style_1 .sp-tab-content {
        display: block !important;
        margin: auto;
      }
    }
    .des_style_1  {
        padding: 25px 0;
        -webkit-animation: 1s ani-fadeIn;
        animation: 1s ani-fadeIn;
      }
      .bgbl {
        background: #f6f6f8;
      }
      .panel {
        padding: 0 18px;
        background-color: white;
        // background-color: #f7f7f7;
        // height: 0%;
        max-height: 100%;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
      }
      table {
        border-spacing: 0;
        border-width: 1px 0 0 1px;
        margin: 1.5em 0 1.75em;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
      }
      table, td, th {
        border: 1px solid #ddd;
          border-top-width: 1px;
          border-right-width: 1px;
          border-bottom-width: 1px;
          border-left-width: 1px;
        vertical-align: middle;
      }
      .ul_tabs li.active a, .ul_tabs li.tab_title_block.active a {
        border-color: #222;
      }
        @media (min-width: 768px)
      {.ul_tabs li.tab_title_block a {
        min-height: 40px;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 40px;
        border: 1px solid transparent;
          border-top-color: transparent;
          border-right-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
        padding: 0 25px;
        margin: 0;
      }}
      .ul_tabs li a:hover, .ul_tabs li.active a {
        color: #222;
      }
      .ul_tabs li.tab_title_block a {
        font-size: 1.2rem;
        font-weight: 600;
        margin: 10px;
        line-height: 1.2;
        border-bottom: 2px solid transparent;
      }
      .truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .cg {
        color: #878787;
      }
      .pr {
        position: relative;
      }
      .db {
        display: block;
      }
      .swatch .nt-swatch, a, button, input {
        -ms-touch-action: manipulation;
        touch-action: manipulation;
      }
      a, a:active, a:focus, a:hover {
        text-decoration: none;
        outline: 0;
      }
      a {
        color: #222;
        transition: .3s;
      }
      .ul_tabs li.active a, .ul_tabs li.tab_title_block.active a {
        border-color: #222;
      }
      .ul_tabs li a:hover, .ul_tabs li.active a {
        color: #222;
      }
      .active::after {
        content: "";
      }
      .active{
        color:""
      }
      .active, .dot:hover {
        background-color: transparent;
      }
      p {
        color: #585759;
        font-size: 1rem;
        margin-bottom: 1.3em;
      }
      strong{
        font-weight: 600;
        font-size: 17px;
      }
      .ul_tabs li.tab_title_block a {
        font-size: 1.2rem;
        font-weight: 600;
        margin: 10px;
        line-height: 1.2;
        border-bottom: 2px solid transparent;
      }
      td, th {
        padding: 10px;
      }
      td {
        border-width: 0 1px 1px 0;
      }
      caption, td, th {
        font-weight: 400;
        text-align: left;
      }
      blockquote p, q p, td p, th p {
        margin: 0;
      }
      p {
        color: #585759;
        font-size: 14px;
        margin-bottom: 1.3em;
        font-family: Poppins;
      }
    }