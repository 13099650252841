
  .mb__60 {
    margin-bottom: 60px;
  }
  .mt__60 {
    margin-top: 60px;
  }
  .cat_space_item {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
}

  @media (min-width: 1025px)
  {.cat_space_30 {
    margin-left: -15px;
    margin-right: -15px;}
  }

  .fl_center {
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
  }

  @media (min-width: 1025px)
  {.cat_space_30 .cat_space_item {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;}
  }

  .oh {
    overflow: hidden;
  }
  .pr {
    position: relative;
  }
  .cat_grid_item__link {
    position: relative;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  .db {
    display: block;
  }
  .equal_nt.ratio1_1 .nt_bg_lz {
    padding-top: 100%;
  }
  .cat_grid_item__overlay.lazyloadt4sed, .nt_bg_lz.pr_lazy_img.lazyloadt4sed, .p-nav.p-nav-ready, .pr_lazy_img.main-img.lazyloadt4sed, .swatch__value.lazyloadt4sed, .swatch__value_pr.lazyloadt4sed, img.lz_op_ef.lazyloadt4sed {
    -webkit-animation: 1.25s cubic-bezier(.26,.54,.32,1) forwards ani-fadeIn;
    animation: 1.25s cubic-bezier(.26,.54,.32,1) forwards ani-fadeIn;
  }
  equal_nt .nt_bg_lz, .pr_lazy_img {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .cat_grid_item__overlay, .hoverz_true .nt_promotion > a {
    -webkit-transition: .6s ease-in-out;
    -moz-transition: .6s ease-in-out;
    -o-transition: .6s ease-in-out;
    transition: .6s ease-in-out;
  }
  .cat_design_1 .cat_grid_item__wrapper, .cat_design_4 .cat_grid_item__wrapper, .cat_design_6 
  .cat_grid_item__wrapper, .cat_design_7 .cat_grid_item__wrapper {
    position: absolute;
    bottom: 20px;
    left: 50%;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #222;
    padding: 12px 20px;
    box-shadow: 1px 1px 0 0 rgba(0,0,0,.1);
    min-width: 150px;
    text-align: center;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .grid-sizer, .pe_none {
    pointer-events: none;
  }
  .h3, .lazyloadt4sed .f__mont, .lazyloadt4sed .f_heading {
    font-family: Poppins;
  }
  .cat_grid_item__wrapper .h3 {
    color: currentColor;
    margin: 0;
  }
  .h3 {
    font-weight: 600;
    color: #222;
    font-size: 1rem;
    letter-spacing: 0px;
    line-height: 1.428;
  }
  .hoverz_true .cat_grid_item:hover .cat_grid_item__overlay, .hoverz_true .nt_promotion:hover > a {
    transform: scale(1.11);
    -webkit-transform: scale(1.11);
    -o-transform: scale(1.11);
    -moz-transform: scale(1.11);
  }