@import "../../css/photography.css";

.wrapper-headshot-photography{
h2 {
    font-family: "playfair display";
    font-weight: bold;
    
}
p{
    
    font-family: "dosis";
    font-size: 1rem;
    // padding-left:20px ;
}
.padding{
    padding-left: 20px;
}
.row h2{
    font-size: 32px;
    
}
.row li{
    font-family: "dosis";
}

.banner-photography img{
    height: auto;
}

.banner-photography .pl-12 {
    padding-left: 15%;
}

section.banner-photography{
    margin-top: 20px;
}
.row .re-cover-headshot{
    font-size: 59px;
    opacity: .8;
  }
}