.photoPrintingSection {
  position: relative;
  height: 100vh;
  width: 100%;
  justify-content: space-around;
  display: flex;
  align-items: center;
  background-image: url("../images/images/photos-printing-delivery/photos-printing-delivery-dubai.jpg");
  background-size: cover;
}

.photoPrintingSection::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgb(0 0 0 / 36%);
}

.headingSizeChart {
  /* color: #444; */
  font-size: 46.75px;
  line-height: 42.5px;
  margin-top: 27px;
  padding: 13px 0;
  font-weight: bold;
  letter-spacing: -5px;
  width: 100%;
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  text-align: center;
  padding-bottom: 25px;
}

.photoPrintingDescription {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.buttonAndNote {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  gap: 10px;
}

.subHeadingSizeChart {
  display: flex;
  justify-content: center;
}
