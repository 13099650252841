.SectionWrapper {
    // padding: 5rem 0;
    .cards-wrapper {
        position: relative;
        // margin: 5rem 0;
        .btn--next, .btn--prev,
        .swiper-button-prev,.swiper-button-next {
            position: absolute;
            top: 50%;
            z-index: 1;
            transform: translateY(-50%);
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            svg {
                font-size: 3rem;
            }
            @media (max-width: 768px) {
                display: none; 
            }
        }
        .btn--next,.swiper-button-next {
            left: 105%;
            height: 0%;
            font-size: 40px;
            color: #498352;
            @media (max-width: 768px) {
                right: 10px;
                left: unset;  
            }
        }
        .swiper-button-next::after{
            content: "\f054";
        }
        .swiper-button-prev::after{
            content: "\f053";
        }
        .btn--prev,.swiper-button-prev {
            right: 105%;
            height: 0%;
            font-size: 40px;
            color: #498352;
            @media (max-width: 768px) {
                left: 10px;
                right: unset;    
            }
        }
    }
}
