.footer {
  background: black;
  background-blend-mode: darken;
  color: white;
  display: flex;
}

.footerIcons {
  width: 50px;
  height: 50px;
}

.footerLeftContent {
  padding: 1px 35px;
  width: 50%;
}

.footerLeftContent .information {
  /* font-size: 12px; */
  font-size: large;
}

.footerLeftIcons {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
  gap: 10px;
}

.footerLeftContent .information p {
  margin-bottom: 6px;
  margin-top: 0px;
}

.footerLeftContent .information .otherLocationText {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
}

.footerRightContent {
  padding: 12px;
  width: -webkit-fill-available;
}

@media (max-width: 450px) {
  .rightHeaderContent {
    list-style: none;
    display: flex;
    /* gap: 10px; */
    font-size: 15px;
    padding-left: 10px;
  }
  .footerLeftContent h2 {
    font-size: 30px;
    padding-top: 15px !important;
  }
  .footerLeftContent h4 {
    font-size: 15px;
  }
}

.faFacebook {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.facebookBackground {
  background-color: white;
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 50%;
}

.facebookBackground i {
  font-size: 25px;
  display: flex;
  align-items: center;
  color: #3b5998;
  justify-content: center;
}

.instagramIcon {
  cursor: pointer;
  background: black;
  border-radius: 10px;
  border: none;
  text-decoration: none;
  height: 50px;
  width: 50px;
}

.facebookIcon {
  cursor: pointer;
  background: #3b5998;
  border-radius: 10px;
  border: none;
  text-decoration: none;
  height: 50px;
  width: 50px;
}

.faInstagram {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none;
  text-align: center;
  width: 100%;
  height: 100%;
}

.faInstagram i {
  font-size: 25px;
  color: white;
}

@media (max-width: 782px) {
  .footer {
    flex-direction: column;
  }
  .footerRightContent {
    padding: unset;
    width: 100%;
    height: 400px;
    padding-top: 2rem !important;
  }
  .footerLeftContent {
    width: unset;
    font-size: large;
  }
}
