.editing-services--wrapper{
.editing-service-img{
    width: 100%;
    height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px 0px 0px 10px;
    object-fit: cover;
}
.no-pad{
    padding: 0;
}

.guidelines {
    background: linear-gradient(45deg, #FAB5DB, #D2CFEE);
    padding: 30px 0px;
}
.guidelines p {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 20px;
}
.guidelines-form label {
	display: block;
	font-size: 15px;
    font-weight: 900;
}
.guidelines-form select {
    width: 60%;
    background: transparent;
    padding: 10px;
    -webkit-appearance: none;
    border: 2px solid;
    border-radius: 5px;
    margin-bottom: 30px;
}
.brdr-left {
    border-left: 2px solid;
}
.theme-btn {
    background: linear-gradient(45deg, #b76bb3, transparent);
    display: block;
    padding: 20px 10px;
    border: none;
    border-radius: 50px;
    box-shadow: 6px 5px 3px -2px #999;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
}
.txt-center {
	text-align: center;
}
.txt-bold {
	font-weight: bold;
}
img {
	width: 100%;
}
.lower-sec {
    padding-top: 30px;
}
.lower-sec span {
    display: block;
    margin-bottom: 5px;
}
.lower-sec span:nth-child(2) {
    margin-bottom: 25px;
}
.services-tittle h1 {
    margin: 40px 0px;
    font-family: "Playfair Display";
    font-weight: bold;
    font-size: 50px;
}
.printing-services {
    background: #FFFEE8;
    padding: 20px 0px;
}
.bg-blue {
    background: #B3E4F5;
}
.bg-green {
    background: #C8F5E0;
}
.bg-lightgreen {
    background: #EAFFED;
}
.bg-purple {
    background: #D2CEEF;
}
.bg-pink {
    background: #FFEAEA;
}
.bg-yellow {
    background: #ECF8A7;
}
.services-box {
    box-shadow: 6px 6px 4px 0px #aaa;
    margin-bottom: 25px;
}
.services-box-tittle {
    display: flex;
    align-items: center;
    height: 180px;
}
.services-box-tittle div {
    font-size: 35px;
    font-weight: bold;
    width: 100%;
    font-family: "Playfair Display";
}
.services {
    border-radius: 80px 80px 0px 130px;
    height: 500px;
    margin-bottom: 25px;
}
.services h3 {
    font-size: 35px;
    padding-left: 20px;
    font-weight: bold;
    font-family: 'Playfair Display';
}
.services img {
    border-radius: 80px 80px 0px 0px;
}
.services p {
    text-align: right;
    padding-right: 10px;
}
.review-box {
    background: #CFE4D2;
    text-align: center;
    padding: 40px 30px;
    border-radius: 10px;
    margin-bottom: 25px;
}
.review-box p {
    text-align: left;
    font-size: 30px;
    min-height: 220px;
}
.review-box .checked {
  color: orange;
  font-size: 30px;
}
.review-box .name {
    display: block;
    font-weight: bold;
    font-weight: 900;
    font-size: 25px;
    padding-top: 20px;
}
.review-box:before {
    content: "\201c";
    position: absolute;
    font-size: 150px;
    left: 45px;
    top: -80px;
    font-family: 'FontAwesome';
    font-weight: bold;
    color: #498352;
}
.bg-gradient {
    background: radial-gradient(#fff, #dbd6d6);
}

.editing-services-right {
    padding: 15px;
}
.editing-services-right h3 {
    font-size: 25px;
    padding: 40px 0px;
    margin: 0px;
    font-weight: bold;
    font-family: 'Playfair Display';
}
.btn-pink {
    // margin-top: 25px;
    box-shadow: 6px 6px 4px 0px #aaa;
    background: #F9D7D7;
    display: block;
    padding: 10px;
    margin-left: auto;
    width: 70%;
    margin-right: auto;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    font-weight: bold;
    font-family: 'Playfair Display';
}
.btn-green {
    // margin-top: 25px;
    box-shadow: 6px 6px 4px 0px #aaa;
    background: #C5FBCD;
    display: block;
    padding: 10px;
    margin-left: auto;
    width: 70%;
    margin-right: auto;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    font-weight: bold;
    font-family: 'Playfair Display';
}
.btn-yellow {
    // margin-top: 25px;
    box-shadow: 6px 6px 4px 0px #aaa;
    background: #DCE897;
    display: block;
    padding: 10px;
    margin-left: auto;
    width: 70%;
    margin-right: auto;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Playfair Display';
}

.btn-purple {
    // margin-top: 25px;
    box-shadow: 6px 6px 4px 0px #aaa;
    background: #bfbcd9;
    display: block;
    padding: 10px;
    margin-left: auto;
    width: 70%;
    margin-right: auto;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Playfair Display';
    margin-top: 50px;
}

.img-center {
    width: 310px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.mb-20 {
    margin-bottom: 20px;
}
.bg-darkgreen {
    background: #98F46E;
}
.b-fam {
    font-family: 'Playfair Display';
    font-weight: bold;
}
.bg-lightpink {
    background: #FFEDED;
}
.photo-delivery h1 {
    padding: 20px 0px;
    font-family: 'Playfair Display';
    font-weight: bold;
    font-size: 40px;
}
.photo-sec-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 20px;
    padding-right: 15px;
}
.photo-sec-txt {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-top: 35px;
}
.photo-txt {
    padding-top: 60px;
}
.retouching-sec-left {
    padding: 40px 20px 20px 20px;
    background: #F4E2F1;
}
.retouching-sec-right {
    padding: 40px 20px 20px 20px;
    background: #EFEEEF;
}
h2{
    font-family: "playfair display";
}
.retouching-sec-left h3, .retouching-sec-right h3 {
    padding-top: 30px;
    font-family: 'Playfair Display';
    font-weight: bold;
}
.retouching-sec-left h4, .retouching-sec-right h4 {
    font-family: 'Playfair Display';
    font-weight: bold;
}
.retouching-sec-left p, .retouching-sec-right p {
    padding: 5px 20px;
    min-height: 160px;
}
.retouching-btn {
    background: linear-gradient(45deg, #b76bb3, transparent);
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Playfair Display';
    padding: 20px 10px;
    border: none;
    border-radius: 50px;
    box-shadow: 7px 7px 3px -2px #999;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
}
}


/*--------------Media Query--------*/

@media (max-width: 767px) {
    .editing-services--wrapper{
        .btn-pink, .btn-green, .btn-yellow {
            width: 100%;
        }
    }
}
@media (min-width: 992px) {
    .editing-services--wrapper{
        .bg-gradient {
            border-radius: 10px 0px 0px 10px;
        }
        .bg-pink, .bg-lightgreen, .bg-yellow {
            border-radius: 0px 10px 10px 0px;
        }
        .editing-services-right {
            height: 310px;
        }
    }
}