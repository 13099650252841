
@import "../../../css/photoDeliveryPage.css";
// .carousel-control-next,
// .carousel-control-prev /*, .carousel-indicators */ {
//     filter: invert(100%);
// }

// .carousel-images{
//     display: flex;
//     background-color: transparent;
// }

.preview-images {
    flex-direction: column;
    display: flex;
    align-items: center;
    // margin-right: 100px;P
    margin-bottom: 20px;
    font-family: "playfair display"
  }
//   .background-transparent{
//       background-color: transparent;
//   }
  
//   .background-images{
//       justify-content: space-evenly;
//       background-color: #E0E0E0;
//   }

// .vertical .carousel-item-next.carousel-item-left,
// .vertical .carousel-item-prev.carousel-item-right {
//     -webkit-transform: translate3d(0, 0, 0);
//             transform: translate3d(0, 0, 0);
// }

// .vertical .carousel-item-next,
// .vertical .active.carousel-item-right {
//     -webkit-transform: translate3d(0, 100%, 0);
//             transform: translate3d(0, 100% 0);
// }

// .vertical .carousel-item-prev,
// .vertical .active.carousel-item-left {
// -webkit-transform: translate3d(0,-100%, 0);
//         transform: translate3d(0,-100%, 0);
// }