.ecomerece-services_wrapper{
  .la, .las {
    font-family: 'Line Awesome Free'; 
    font-weight: 900;
}
.la-angle-right:before {
    content: "\f105";
}
carousel-cell:before {
  display: block;
  text-align: center;
  content: counter(gallery-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
.ttip_nt {
    position: relative;
    display: inline-block;
} 
a {
 color:#222;
 transition:.3s
}
a,
a:active,
a:focus,
a:hover {
 text-decoration:none;
 outline:0
}
a:active,
a:hover {
 color:#cc9e6c
}
em {
 font-style:italic
}
strong {
 font-weight:600
}
img {
 height:auto;
 max-width:100%;
 vertical-align:middle
}
iframe {
 max-width:100%
}
h1,
h2,
h3,
h4,
h5,
h6 {
 margin:.65em 0 .5em;
 line-height:1.428;
 font-family:Poppins;
 color:#222;
 letter-spacing:0
}
.h1,
h1 {
 margin:0;
 font-size:2.6179em
}
h2 {
 font-size:2.0581em
}
h3 {
 font-size:1.618em
}
h4 {
 font-size:1.3em
}
h5 {
 font-size:1.2em
}
h6 {
 font-size:1.1em
}
p {
 margin-bottom:1.3em
}
button,
input,
textarea {
 transition:.3s
}
button,
input,
optgroup,
select,
textarea {
 color:inherit;
 font:inherit
}
button:focus,
input:focus,
textarea:focus {
 outline:0;
 box-shadow:none
}
input[type=date],
input[type=email],
input[type=number],
input[type=password],
input[type=tel],
input[type=text],
input[type=url],
select,
textarea {
 width:100%;
 height:40px;
 line-height:18px;
 transition:border-color .5s;
 box-shadow:none;
 border-radius:0;
 -webkit-appearance:none;
 -moz-appearance:none;
 appearance:none
}
input:-webkit-autofill {
 border-color:#e6e6e6;
 -webkit-box-shadow:0 0 0 1000px #fff inset;
 -webkit-text-fill-color:#878787
}
input:focus:-webkit-autofill {
 border-color:#d9d9d9
}
input:not([type=submit]):not([type=checkbox]),
select,
textarea {
 border:1px solid #ccc;
 font-size:13px;
 outline:0;
 padding:0 15px;
 color:#878787;
 border-radius:0;
 max-width:100%
}
input:not([type=submit]):not([type=checkbox]):focus,
textarea:focus {
 border-color:#222
}
textarea {
 overflow:auto;
 padding:10px 15px;
 min-height:80px
}
a.button,
button,
input[type=submit] {
 cursor:pointer;
 border:2px solid #222;
 padding:5px 25px;
 background:#fff;
 color:#222;
 border-radius:40px;
 font-size:14px;
 font-weight:600;
 min-height:40px
}
a.button {
 display:inline-block;
 background:0 0
}
a.button.button-o-w {
 border-color:initial;
 color:#fff
}
a.button.button_dark {
 background-color:#000;
 border-color:#000;
 color:#fff
}
a.external_btn.truncate.button {
 border-radius:40px
}
a.button:hover,
button:hover,
input[type=submit]:hover {
 border-color:#cc9e6c;
 background-color:#cc9e6c;
//  color:#fff
}
button,
input[type=submit] {
 -webkit-appearance:button
}
[disabled=disabled],
button[disabled=disabled],
input[disabled=disabled] {
 cursor:not-allowed;
 opacity:.5
}
::-webkit-input-placeholder {
 color:#c1c1c1
}
:-moz-placeholder {
 color:#c1c1c1
}
::-moz-placeholder {
 color:#c1c1c1
}
.color_scheme_light ::-webkit-input-placeholder {
 color:rgba(255,255,255,.7)
}
.color_scheme_light :-moz-placeholder {
 color:rgba(255,255,255,.7)
}
.color_scheme_light ::-moz-placeholder {
 color:rgba(255,255,255,.7)
}
.color_scheme_light :-ms-input-placeholder {
 color:rgba(255,255,255,.7)
}
input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
 transition:background-color 5000s ease-in-out;
 -webkit-box-shadow:0 0 0 1000px #fff inset
}
@-moz-document url-prefix() {
 select:-moz-focusring,
 select::-moz-focus-inner {
  color:transparent!important;
  text-shadow:0 0 0 #999!important;
  background-image:none!important;
  border:0
 }
}
input:focus::-webkit-input-placeholder {
 color:transparent
}
input:focus::-moz-placeholder {
 color:transparent
}
input:focus:-ms-input-placeholder {
 color:transparent
}
select {
 padding:0 30px 0 15px;
 max-width:100%;
 width:100%;
 height:40px;
 border:1px solid #e3e3e3;
 vertical-align:middle;
 font-size:14px;
 transition:border-color .5s;
 -webkit-appearance:none;
 -moz-appearance:none;
 appearance:none;
//  background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNiYmIiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPg==);
 background-position:right 10px top 50%;
 background-size:auto 18px;
 background-repeat:no-repeat;
 display:inline-block;
 background-color:transparent;
 box-shadow:none;
 color:#222;
 border-radius:30px
}
.no-js #cart-form_ppr .variations,
.yes-js #cart-form_ppr select.product-select_pr {
 display:none
}
.no-js #cart-form_ppr select {
 margin-bottom:30px
}
.swatch .nt-swatch,
a,
button,
input {
 -ms-touch-action:manipulation;
 touch-action:manipulation
}
.button,
button,
input[type=button],
input[type=reset],
input[type=submit] {
 -webkit-appearance:none;
 -moz-appearance:none;
 appearance:none
}
*,
:after,
:before {
 -webkit-box-sizing:border-box;
 box-sizing:border-box
}
.clearfix:after,
.clearfix:before {
 content:' ';
 display:table
}
.clearfix:after {
 clear:both
}
.screen-reader-text {
 clip:rect(1px,1px,1px,1px);
 height:1px;
 overflow:hidden;
 position:absolute!important;
 width:1px
}
.icon__fallback-text,
.visually-hidden {
 position:absolute!important;
 overflow:hidden;
 clip:rect(0 0 0 0);
 height:1px;
 width:1px;
 margin:-1px;
 padding:0;
 border:0
}
.visibility-hidden {
 visibility:hidden
}
.visually-hidden--inline {
 margin:0;
 height:1em
}
.visually-hidden--static {
 position:static!important
}
.js-focus-hidden:focus {
 outline:0
}
.skip-link:focus {
 clip:auto;
 width:auto;
 height:auto;
 margin:0;
 color:#3a3a3a;
 background-color:#fff;
 padding:10px;
 opacity:1;
//  z-index:10000;
 transition:none
}
a:focus-visible {
 outline:#005fcc auto 1px
}
.dcp_cd_ip {
 opacity:0;
 height:0!important
}
.container {
 width:100%;
 padding-right:15px;
 padding-left:15px;
 margin-right:auto;
 margin-left:auto
}
[data-kl-full-width] {
 width:100vw;
 left:-15px;
 position:relative
}
@media (min-width:1200px) {
 .container {
  max-width:1170px
 }
 [data-kl-full-width] {
  left:calc((-100vw - -1170px)/ 2)
 }
}
@media (min-width:1230px) {
 .container {
  max-width:1200px
 }
}
.container-fluid {
 width:100%;
 padding-right:15px;
 padding-left:15px;
 margin-right:auto;
 margin-left:auto
}
 
// .col,
// .col-1,
// .col-10,
// .col-11,
// .col-12,
// .col-15,
// .col-2,
// .col-3,
// .col-4,
// .col-5,
// .col-6,
// .col-7,
// .col-8,
// .col-9,
// .col-auto,
// .col-lg,
// .col-lg-1,
// .col-lg-10,
// .col-lg-11,
// .col-lg-12,
// .col-lg-15,
// .col-lg-2,
// .col-lg-3,
// .col-lg-4,
// .col-lg-5,
// .col-lg-6,
// .col-lg-7,
// .col-lg-8,
// .col-lg-9,
// .col-lg-auto,
// .col-md,
// .col-md-1,
// .col-md-10,
// .col-md-11,
// .col-md-12,
// .col-md-15,
// .col-md-2,
// .col-md-3,
// .col-md-4,
// .col-md-5,
// .col-md-6,
// .col-md-7,
// .col-md-8,
// .col-md-9,
// .col-md-auto,
// .col-nt-auto,
// .col-sm,
// .col-sm-1,
// .col-sm-10,
// .col-sm-11,
// .col-sm-12,
// .col-sm-2,
// .col-sm-3,
// .col-sm-4,
// .col-sm-5,
// .col-sm-6,
// .col-sm-7,
// .col-sm-8,
// .col-sm-9,
// .col-sm-auto,
// .col-xl,
// .col-xl-1,
// .col-xl-10,
// .col-xl-11,
// .col-xl-12,
// .col-xl-2,
// .col-xl-3,
// .col-xl-4,
// .col-xl-5,
// .col-xl-6,
// .col-xl-7,
// .col-xl-8,
// .col-xl-9,
// .col-xl-auto {
//  position:relative;
//  width:100%;
//  padding-right:7.5px;
//  padding-left:7.5px
// }
// .col {
//  -ms-flex-preferred-size:0;
//  flex-basis:0;
//  -ms-flex-positive:1;
//  flex-grow:1;
//  max-width:100%
// }
// .col-auto {
//  -ms-flex:0 0 auto;
//  flex:0 0 auto;
//  width:auto;
//  max-width:100%
// }
// .col-1 {
//  -ms-flex:0 0 8.333333%;
//  flex:0 0 8.333333%;
//  max-width:8.333333%
// }
// .col-2 {
//  -ms-flex:0 0 16.666667%;
//  flex:0 0 16.666667%;
//  max-width:16.666667%
// }
// .col-3 {
//  -ms-flex:0 0 25%;
//  flex:0 0 25%;
//  max-width:25%
// }
// .col-4 {
//  -ms-flex:0 0 33.333333%;
//  flex:0 0 33.333333%;
//  max-width:33.333333%
// }
// .col-5 {
//  -ms-flex:0 0 41.666667%;
//  flex:0 0 41.666667%;
//  max-width:41.666667%
// }
// .col-6 {
//  -ms-flex:0 0 50%;
//  flex:0 0 50%;
//  max-width:50%
// }
// .col-7 {
//  -ms-flex:0 0 58.333333%;
//  flex:0 0 58.333333%;
//  max-width:58.333333%
// }
// .col-8 {
//  -ms-flex:0 0 66.666667%;
//  flex:0 0 66.666667%;
//  max-width:66.666667%
// }
// .col-9 {
//  -ms-flex:0 0 75%;
//  flex:0 0 75%;
//  max-width:75%
// }
// .col-10 {
//  -ms-flex:0 0 83.333333%;
//  flex:0 0 83.333333%;
//  max-width:83.333333%
// }
// .col-11 {
//  -ms-flex:0 0 91.666667%;
//  flex:0 0 91.666667%;
//  max-width:91.666667%
// }
// .col-12 {
//  -ms-flex:0 0 100%;
//  flex:0 0 100%;
//  max-width:100%
// }
// .col-15 {
//  -ms-flex:0 0 20%;
//  flex:0 0 20%;
//  max-width:20%
// }
// .order-first {
//  -ms-flex-order:-1;
//  order:-1
// }
// .order-last {
//  -ms-flex-order:13;
//  order:13
// }
// .order-0 {
//  -ms-flex-order:0;
//  order:0
// }
// .order-1 {
//  -ms-flex-order:1;
//  order:1
// }
// .order-2 {
//  -ms-flex-order:2;
//  order:2
// }
// .order-3 {
//  -ms-flex-order:3;
//  order:3
// }
// .order-4 {
//  -ms-flex-order:4;
//  order:4
// }
// .order-5 {
//  -ms-flex-order:5;
//  order:5
// }
// .order-6 {
//  -ms-flex-order:6;
//  order:6
// }
// .order-7 {
//  -ms-flex-order:7;
//  order:7
// }
// .order-8 {
//  -ms-flex-order:8;
//  order:8
// }
// .order-9 {
//  -ms-flex-order:9;
//  order:9
// }
// .order-10 {
//  -ms-flex-order:10;
//  order:10
// }
// .order-11 {
//  -ms-flex-order:11;
//  order:11
// }
// .order-12 {
//  -ms-flex-order:12;
//  order:12
// }
// .offset-1 {
//  margin-left:8.333333%
// }
// .offset-2 {
//  margin-left:16.666667%
// }
// .offset-3 {
//  margin-left:25%
// }
// .offset-4 {
//  margin-left:33.333333%
// }
// .offset-5 {
//  margin-left:41.666667%
// }
// .offset-6 {
//  margin-left:50%
// }
// .offset-7 {
//  margin-left:58.333333%
// }
// .offset-8 {
//  margin-left:66.666667%
// }
// .offset-9 {
//  margin-left:75%
// }
// .offset-10 {
//  margin-left:83.333333%
// }
// .offset-11 {
//  margin-left:91.666667%
// }
// .fl_wrap {
//  -ms-flex-wrap:wrap!important;
//  flex-wrap:wrap!important
// }
// .fl_nowrap {
//  -ms-flex-wrap:nowrap!important;
//  flex-wrap:nowrap!important
// }
// .oah {
//  overflow-x:auto;
//  overflow-y:hidden;
//  -webkit-overflow-scrolling:touch
// }
// @media (min-width:576px) {
//  .col-sm {
//   -ms-flex-preferred-size:0;
//   flex-basis:0;
//   -ms-flex-positive:1;
//   flex-grow:1;
//   max-width:100%
//  }
//  .col-sm-auto {
//   -ms-flex:0 0 auto;
//   flex:0 0 auto;
//   width:auto;
//   max-width:100%
//  }
//  .col-sm-1 {
//   -ms-flex:0 0 8.333333%;
//   flex:0 0 8.333333%;
//   max-width:8.333333%
//  }
//  .col-sm-2 {
//   -ms-flex:0 0 16.666667%;
//   flex:0 0 16.666667%;
//   max-width:16.666667%
//  }
//  .col-sm-3 {
//   -ms-flex:0 0 25%;
//   flex:0 0 25%;
//   max-width:25%
//  }
//  .col-sm-4 {
//   -ms-flex:0 0 33.333333%;
//   flex:0 0 33.333333%;
//   max-width:33.333333%
//  }
//  .col-sm-5 {
//   -ms-flex:0 0 41.666667%;
//   flex:0 0 41.666667%;
//   max-width:41.666667%
//  }
//  .col-sm-6 {
//   -ms-flex:0 0 50%;
//   flex:0 0 50%;
//   max-width:50%
//  }
//  .col-sm-7 {
//   -ms-flex:0 0 58.333333%;
//   flex:0 0 58.333333%;
//   max-width:58.333333%
//  }
//  .col-sm-8 {
//   -ms-flex:0 0 66.666667%;
//   flex:0 0 66.666667%;
//   max-width:66.666667%
//  }
//  .col-sm-9 {
//   -ms-flex:0 0 75%;
//   flex:0 0 75%;
//   max-width:75%
//  }
//  .col-sm-10 {
//   -ms-flex:0 0 83.333333%;
//   flex:0 0 83.333333%;
//   max-width:83.333333%
//  }
//  .col-sm-11 {
//   -ms-flex:0 0 91.666667%;
//   flex:0 0 91.666667%;
//   max-width:91.666667%
//  }
//  .col-sm-12 {
//   -ms-flex:0 0 100%;
//   flex:0 0 100%;
//   max-width:100%
//  }
//  .col-sm-15 {
//   -ms-flex:0 0 20%;
//   flex:0 0 20%;
//   max-width:20%
//  }
//  .order-sm-first {
//   -ms-flex-order:-1;
//   order:-1
//  }
//  .order-sm-last {
//   -ms-flex-order:13;
//   order:13
//  }
//  .order-sm-0 {
//   -ms-flex-order:0;
//   order:0
//  }
//  .order-sm-1 {
//   -ms-flex-order:1;
//   order:1
//  }
//  .order-sm-2 {
//   -ms-flex-order:2;
//   order:2
//  }
//  .order-sm-3 {
//   -ms-flex-order:3;
//   order:3
//  }
//  .order-sm-4 {
//   -ms-flex-order:4;
//   order:4
//  }
//  .order-sm-5 {
//   -ms-flex-order:5;
//   order:5
//  }
//  .order-sm-6 {
//   -ms-flex-order:6;
//   order:6
//  }
//  .order-sm-7 {
//   -ms-flex-order:7;
//   order:7
//  }
//  .order-sm-8 {
//   -ms-flex-order:8;
//   order:8
//  }
//  .order-sm-9 {
//   -ms-flex-order:9;
//   order:9
//  }
//  .order-sm-10 {
//   -ms-flex-order:10;
//   order:10
//  }
//  .order-sm-11 {
//   -ms-flex-order:11;
//   order:11
//  }
//  .order-sm-12 {
//   -ms-flex-order:12;
//   order:12
//  }
//  .offset-sm-0 {
//   margin-left:0
//  }
//  .offset-sm-1 {
//   margin-left:8.333333%
//  }
//  .offset-sm-2 {
//   margin-left:16.666667%
//  }
//  .offset-sm-3 {
//   margin-left:25%
//  }
//  .offset-sm-4 {
//   margin-left:33.333333%
//  }
//  .offset-sm-5 {
//   margin-left:41.666667%
//  }
//  .offset-sm-6 {
//   margin-left:50%
//  }
//  .offset-sm-7 {
//   margin-left:58.333333%
//  }
//  .offset-sm-8 {
//   margin-left:66.666667%
//  }
//  .offset-sm-9 {
//   margin-left:75%
//  }
//  .offset-sm-10 {
//   margin-left:83.333333%
//  }
//  .offset-sm-11 {
//   margin-left:91.666667%
//  }
//  .fl_wrap_sm {
//   -ms-flex-wrap:wrap!important;
//   flex-wrap:wrap!important
//  }
//  .fl_nowrap_sm {
//   -ms-flex-wrap:nowrap!important;
//   flex-wrap:nowrap!important
//  }
// }
// @media (min-width:768px) {
//  .col-md {
//   -ms-flex-preferred-size:0;
//   flex-basis:0;
//   -ms-flex-positive:1;
//   flex-grow:1;
//   max-width:100%
//  }
//  .col-md-auto {
//   -ms-flex:0 0 auto;
//   flex:0 0 auto;
//   width:auto;
//   max-width:100%
//  }
//  .col-md-1 {
//   -ms-flex:0 0 8.333333%;
//   flex:0 0 8.333333%;
//   max-width:8.333333%
//  }
//  .col-md-2 {
//   -ms-flex:0 0 16.666667%;
//   flex:0 0 16.666667%;
//   max-width:16.666667%
//  }
//  .col-md-3 {
//   -ms-flex:0 0 25%;
//   flex:0 0 25%;
//   max-width:25%
//  }
//  .col-md-4 {
//   -ms-flex:0 0 33.333333%;
//   flex:0 0 33.333333%;
//   max-width:33.333333%
//  }
//  .col-md-5 {
//   -ms-flex:0 0 41.666667%;
//   flex:0 0 41.666667%;
//   max-width:41.666667%
//  }
//  .col-md-6 {
//   -ms-flex:0 0 50%;
//   flex:0 0 50%;
//   max-width:50%
//  }
//  .col-md-7 {
//   -ms-flex:0 0 58.333333%;
//   flex:0 0 58.333333%;
//   max-width:58.333333%
//  }
//  .col-md-8 {
//   -ms-flex:0 0 66.666667%;
//   flex:0 0 66.666667%;
//   max-width:66.666667%
//  }
//  .col-md-9 {
//   -ms-flex:0 0 75%;
//   flex:0 0 75%;
//   max-width:75%
//  }
//  .col-md-10 {
//   -ms-flex:0 0 83.333333%;
//   flex:0 0 83.333333%;
//   max-width:83.333333%
//  }
//  .col-md-11 {
//   -ms-flex:0 0 91.666667%;
//   flex:0 0 91.666667%;
//   max-width:91.666667%
//  }
//  .col-md-12 {
//   -ms-flex:0 0 100%;
//   flex:0 0 100%;
//   max-width:100%
//  }
//  .col-md-15 {
//   -ms-flex:0 0 20%;
//   flex:0 0 20%;
//   max-width:20%
//  }
//  .order-md-first {
//   -ms-flex-order:-1;
//   order:-1
//  }
//  .order-md-last {
//   -ms-flex-order:13;
//   order:13
//  }
//  .order-md-0 {
//   -ms-flex-order:0;
//   order:0
//  }
//  .order-md-1 {
//   -ms-flex-order:1;
//   order:1
//  }
//  .order-md-2 {
//   -ms-flex-order:2;
//   order:2
//  }
//  .order-md-3 {
//   -ms-flex-order:3;
//   order:3
//  }
//  .order-md-4 {
//   -ms-flex-order:4;
//   order:4
//  }
//  .order-md-5 {
//   -ms-flex-order:5;
//   order:5
//  }
//  .order-md-6 {
//   -ms-flex-order:6;
//   order:6
//  }
//  .order-md-7 {
//   -ms-flex-order:7;
//   order:7
//  }
//  .order-md-8 {
//   -ms-flex-order:8;
//   order:8
//  }
//  .order-md-9 {
//   -ms-flex-order:9;
//   order:9
//  }
//  .order-md-10 {
//   -ms-flex-order:10;
//   order:10
//  }
//  .order-md-11 {
//   -ms-flex-order:11;
//   order:11
//  }
//  .order-md-12 {
//   -ms-flex-order:12;
//   order:12
//  }
//  .offset-md-0 {
//   margin-left:0
//  }
//  .offset-md-1 {
//   margin-left:8.333333%
//  }
//  .offset-md-2 {
//   margin-left:16.666667%
//  }
//  .offset-md-3 {
//   margin-left:25%
//  }
//  .offset-md-4 {
//   margin-left:33.333333%
//  }
//  .offset-md-5 {
//   margin-left:41.666667%
//  }
//  .offset-md-6 {
//   margin-left:50%
//  }
//  .offset-md-7 {
//   margin-left:58.333333%
//  }
//  .offset-md-8 {
//   margin-left:66.666667%
//  }
//  .offset-md-9 {
//   margin-left:75%
//  }
//  .offset-md-10 {
//   margin-left:83.333333%
//  }
//  .offset-md-11 {
//   margin-left:91.666667%
//  }
//  .fl_wrap_md {
//   -ms-flex-wrap:wrap!important;
//   flex-wrap:wrap!important
//  }
//  .fl_nowrap_md {
//   -ms-flex-wrap:nowrap!important;
//   flex-wrap:nowrap!important
//  }
// }
// @media (min-width:1025px) {
//  .col-lg {
//   -ms-flex-preferred-size:0;
//   flex-basis:0;
//   -ms-flex-positive:1;
//   flex-grow:1;
//   max-width:100%
//  }
//  .col-lg-auto {
//   -ms-flex:0 0 auto;
//   flex:0 0 auto;
//   width:auto;
//   max-width:100%
//  }
//  .col-lg-1 {
//   -ms-flex:0 0 8.333333%;
//   flex:0 0 8.333333%;
//   max-width:8.333333%
//  }
//  .col-lg-2 {
//   -ms-flex:0 0 16.666667%;
//   flex:0 0 16.666667%;
//   max-width:16.666667%
//  }
//  .col-lg-3 {
//   -ms-flex:0 0 25%;
//   flex:0 0 25%;
//   max-width:25%
//  }
//  .col-lg-4 {
//   -ms-flex:0 0 33.333333%;
//   flex:0 0 33.333333%;
//   max-width:33.333333%
//  }
//  .col-lg-5 {
//   -ms-flex:0 0 41.666667%;
//   flex:0 0 41.666667%;
//   max-width:41.666667%
//  }
//  .col-lg-6 {
//   -ms-flex:0 0 50%;
//   flex:0 0 50%;
//   max-width:50%
//  }
//  .col-lg-7 {
//   -ms-flex:0 0 58.333333%;
//   flex:0 0 58.333333%;
//   max-width:58.333333%
//  }
//  .col-lg-8 {
//   -ms-flex:0 0 66.666667%;
//   flex:0 0 66.666667%;
//   max-width:66.666667%
//  }
//  .col-lg-9 {
//   -ms-flex:0 0 75%;
//   flex:0 0 75%;
//   max-width:75%
//  }
//  .col-lg-10 {
//   -ms-flex:0 0 83.333333%;
//   flex:0 0 83.333333%;
//   max-width:83.333333%
//  }
//  .col-lg-11 {
//   -ms-flex:0 0 91.666667%;
//   flex:0 0 91.666667%;
//   max-width:91.666667%
//  }
//  .col-lg-12 {
//   -ms-flex:0 0 100%;
//   flex:0 0 100%;
//   max-width:100%
//  }
//  .col-lg-15 {
//   -ms-flex:0 0 20%;
//   flex:0 0 20%;
//   max-width:20%
//  }
//  .order-lg-first {
//   -ms-flex-order:-1;
//   order:-1
//  }
//  .order-lg-last {
//   -ms-flex-order:13;
//   order:13
//  }
//  .order-lg-0 {
//   -ms-flex-order:0;
//   order:0
//  }
//  .order-lg-1 {
//   -ms-flex-order:1;
//   order:1
//  }
//  .order-lg-2 {
//   -ms-flex-order:2;
//   order:2
//  }
//  .order-lg-3 {
//   -ms-flex-order:3;
//   order:3
//  }
//  .order-lg-4 {
//   -ms-flex-order:4;
//   order:4
//  }
//  .order-lg-5 {
//   -ms-flex-order:5;
//   order:5
//  }
//  .order-lg-6 {
//   -ms-flex-order:6;
//   order:6
//  }
//  .order-lg-7 {
//   -ms-flex-order:7;
//   order:7
//  }
//  .order-lg-8 {
//   -ms-flex-order:8;
//   order:8
//  }
//  .order-lg-9 {
//   -ms-flex-order:9;
//   order:9
//  }
//  .order-lg-10 {
//   -ms-flex-order:10;
//   order:10
//  }
//  .order-lg-11 {
//   -ms-flex-order:11;
//   order:11
//  }
//  .order-lg-12 {
//   -ms-flex-order:12;
//   order:12
//  }
//  .offset-lg-0 {
//   margin-left:0
//  }
//  .offset-lg-1 {
//   margin-left:8.333333%
//  }
//  .offset-lg-2 {
//   margin-left:16.666667%
//  }
//  .offset-lg-3 {
//   margin-left:25%
//  }
//  .offset-lg-4 {
//   margin-left:33.333333%
//  }
//  .offset-lg-5 {
//   margin-left:41.666667%
//  }
//  .offset-lg-6 {
//   margin-left:50%
//  }
//  .offset-lg-7 {
//   margin-left:58.333333%
//  }
//  .offset-lg-8 {
//   margin-left:66.666667%
//  }
//  .offset-lg-9 {
//   margin-left:75%
//  }
//  .offset-lg-10 {
//   margin-left:83.333333%
//  }
//  .offset-lg-11 {
//   margin-left:91.666667%
//  }
//  .fl_wrap_lg {
//   -ms-flex-wrap:wrap!important;
//   flex-wrap:wrap!important
//  }
//  .fl_nowrap_lg {
//   -ms-flex-wrap:nowrap!important;
//   flex-wrap:nowrap!important
//  }
// }
// @media (min-width:1200px) {
//  .col-xl {
//   -ms-flex-preferred-size:0;
//   flex-basis:0;
//   -ms-flex-positive:1;
//   flex-grow:1;
//   max-width:100%
//  }
//  .col-xl-auto {
//   -ms-flex:0 0 auto;
//   flex:0 0 auto;
//   width:auto;
//   max-width:100%
//  }
//  .col-xl-1 {
//   -ms-flex:0 0 8.333333%;
//   flex:0 0 8.333333%;
//   max-width:8.333333%
//  }
//  .col-xl-2 {
//   -ms-flex:0 0 16.666667%;
//   flex:0 0 16.666667%;
//   max-width:16.666667%
//  }
//  .col-xl-3 {
//   -ms-flex:0 0 25%;
//   flex:0 0 25%;
//   max-width:25%
//  }
//  .col-xl-4 {
//   -ms-flex:0 0 33.333333%;
//   flex:0 0 33.333333%;
//   max-width:33.333333%
//  }
//  .col-xl-5 {
//   -ms-flex:0 0 41.666667%;
//   flex:0 0 41.666667%;
//   max-width:41.666667%
//  }
//  .col-xl-6 {
//   -ms-flex:0 0 50%;
//   flex:0 0 50%;
//   max-width:50%
//  }
//  .col-xl-7 {
//   -ms-flex:0 0 58.333333%;
//   flex:0 0 58.333333%;
//   max-width:58.333333%
//  }
//  .col-xl-8 {
//   -ms-flex:0 0 66.666667%;
//   flex:0 0 66.666667%;
//   max-width:66.666667%
//  }
//  .col-xl-9 {
//   -ms-flex:0 0 75%;
//   flex:0 0 75%;
//   max-width:75%
//  }
//  .col-xl-10 {
//   -ms-flex:0 0 83.333333%;
//   flex:0 0 83.333333%;
//   max-width:83.333333%
//  }
//  .col-xl-11 {
//   -ms-flex:0 0 91.666667%;
//   flex:0 0 91.666667%;
//   max-width:91.666667%
//  }
//  .col-xl-12 {
//   -ms-flex:0 0 100%;
//   flex:0 0 100%;
//   max-width:100%
//  }
//  .col-xl-15 {
//   -ms-flex:0 0 20%;
//   flex:0 0 20%;
//   max-width:20%
//  }
//  .order-xl-first {
//   -ms-flex-order:-1;
//   order:-1
//  }
//  .order-xl-last {
//   -ms-flex-order:13;
//   order:13
//  }
//  .order-xl-0 {
//   -ms-flex-order:0;
//   order:0
//  }
//  .order-xl-1 {
//   -ms-flex-order:1;
//   order:1
//  }
//  .order-xl-2 {
//   -ms-flex-order:2;
//   order:2
//  }
//  .order-xl-3 {
//   -ms-flex-order:3;
//   order:3
//  }
//  .order-xl-4 {
//   -ms-flex-order:4;
//   order:4
//  }
//  .order-xl-5 {
//   -ms-flex-order:5;
//   order:5
//  }
//  .order-xl-6 {
//   -ms-flex-order:6;
//   order:6
//  }
//  .order-xl-7 {
//   -ms-flex-order:7;
//   order:7
//  }
//  .order-xl-8 {
//   -ms-flex-order:8;
//   order:8
//  }
//  .order-xl-9 {
//   -ms-flex-order:9;
//   order:9
//  }
//  .order-xl-10 {
//   -ms-flex-order:10;
//   order:10
//  }
//  .order-xl-11 {
//   -ms-flex-order:11;
//   order:11
//  }
//  .order-xl-12 {
//   -ms-flex-order:12;
//   order:12
//  }
//  .offset-xl-0 {
//   margin-left:0
//  }
//  .offset-xl-1 {
//   margin-left:8.333333%
//  }
//  .offset-xl-2 {
//   margin-left:16.666667%
//  }
//  .offset-xl-3 {
//   margin-left:25%
//  }
//  .offset-xl-4 {
//   margin-left:33.333333%
//  }
//  .offset-xl-5 {
//   margin-left:41.666667%
//  }
//  .offset-xl-6 {
//   margin-left:50%
//  }
//  .offset-xl-7 {
//   margin-left:58.333333%
//  }
//  .offset-xl-8 {
//   margin-left:66.666667%
//  }
//  .offset-xl-9 {
//   margin-left:75%
//  }
//  .offset-xl-10 {
//   margin-left:83.333333%
//  }
//  .offset-xl-11 {
//   margin-left:91.666667%
//  }
//  .fl_wrap_xl {
//   -ms-flex-wrap:wrap!important;
//   flex-wrap:wrap!important
//  }
//  .fl_nowrap_xl {
//   -ms-flex-wrap:nowrap!important;
//   flex-wrap:nowrap!important
//  }
// }
.row.no-gutters {
 margin-right:0;
 margin-left:0
}
.no-gutters .slideshow__slide,
.no-gutters>.col,
.no-gutters>[class*=col-] {
 padding-right:0;
 padding-left:0
}
.nt_slider:not(.flickity-enabled) {
 display:block!important;
 white-space:nowrap;
 overflow-y:hidden;
 overflow-x:scroll;
 width:auto;
 -ms-overflow-style:-ms-autohiding-scrollbar
}
.nt_slider {
 position:relative;
 scrollbar-width:none
}
.row-slider:not(.flickity-enabled) {
 display:block!important
}
.nt_slider::-webkit-scrollbar {
 width:0!important;
 height:0!important
}
.nt_slider:not(.flickity-enabled)>a {
 width:100%
}
.nt_slider:not(.flickity-enabled)>* {
 display:inline-block!important;
 white-space:normal!important;
 vertical-align:top
}
.flickity-enabled {
 position:relative
}
.flickity-enabled:focus {
 outline:0
}
.flickity-viewport {
 overflow:hidden;
 position:relative;
 width:100%;
 height:100%
}
.flickity-slider {
 position:absolute;
 width:100%;
 height:100%
}
.flickity-enabled.is-draggable {
 -webkit-tap-highlight-color:transparent;
 tap-highlight-color:transparent;
 -webkit-user-select:none;
 -moz-user-select:none;
 -ms-user-select:none;
 user-select:none
}
.flickity-enabled.is-draggable .flickity-viewport {
 cursor:move;
 cursor:-webkit-grab;
 cursor:grab
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
 cursor:-webkit-grabbing;
 cursor:grabbing
}
.flickity-button {
 position:absolute;
 color:#000;
 -webkit-transition:.25s;
 transition:.25s
}
.flickity-button:hover {
 cursor:pointer
}
.flickity-button:focus {
 outline:0;
 box-shadow:none
}
.flickity-button:active {
 opacity:.6
}
.flickity-button:disabled {
 opacity:.3;
 cursor:auto
}
.flickity_prev_disable.flickity_next_disable .flickity-button:disabled {
 display:none
}
.nt_pr .flickity-button:disabled {
 pointer-events:auto
}
.flickity-button-icon {
 fill:currentColor
}
.flickity-prev-next-button {
 top:calc(50% - 20px);
 top:-webkit-calc(50% - 20px);
 border-radius:50%;
 padding:0;
 background-color:transparent
}
.clno-touchevents .flickity-prev-next-button {
 top:calc(50% - 20px);
 top:-webkit-calc(50% - 20px);
 opacity:0;
 -webkit-transform:scale(.7);
 transform:scale(.7);
 transition:.2s ease-in-out
}
.clno-touchevents .flickity-enabled.btn_vi_1 .flickity-prev-next-button,
.clno-touchevents .flickity-enabled:hover .flickity-prev-next-button {
 -webkit-transform:scale(1);
 transform:scale(1);
 opacity:1
}
.clno-touchevents .flickity-enabled.btn_vi_1.btn_owl_3 .flickity-prev-next-button {
 -webkit-transform:rotate(45deg);
 transform:rotate(45deg)
}
.clno-touchevents .flickity-enabled.btn_vi_1 .flickity-prev-next-button[disabled],
.clno-touchevents .flickity-enabled:hover .flickity-prev-next-button[disabled] {
 opacity:.3
}

.flickity-prev-next-button:hover{
  color: #fff
}

.flickity-prev-next-button.previous {
 left:15px
}
.flickity-prev-next-button.next {
 right:15px;
 margin-top: 0px;
}

.flickity-prev-next-button:before {
  display: block;
  content: "\e686";
  font-family: Pe-icon-7-stroke;
  width: 36px;
  font-size: 36px;
  height: 36px;
  line-height: 36px;
  font-weight: 500
}


.flickity-enabled:not(.flickity-rtl) .flickity-prev-next-button.next:before,.flickity-rtl .flickity-prev-next-button.previous:before {
  content: "\e684"
}

.flickity-rtl .flickity-prev-next-button.previous {
 left:auto;
 right:15px
}
.flickity-rtl .flickity-prev-next-button.next {
 right:auto;
 left:15px
}
.flickity-page-dots .dot.is-selected:first-child:last-child,
.flickity-prev-next-button .flickity-button-icon {
 display:none
}
.flickity-page-dots {
 width:100%;
 padding:0;
 margin:0;
 list-style:none;
 text-align:center;
 line-height:1
}
.flickity-rtl .flickity-page-dots {
 direction:rtl
}
.flickity-page-dots .dot {
 display:inline-block;
 width:11px;
 height:11px;
 margin:25px 5px 0;
 border-radius:50%;
 background:#fff;
 border:2px solid rgba(0,0,0,.2);
 cursor:pointer
}
.dot_owl_2 .flickity-page-dots .dot {
 background:rgba(0,0,0,.5);
 border:0
}
.dot_owl_3 .flickity-page-dots .dot {
 width:8px!important;
 height:8px!important;
 border:0;
 border-radius:5px;
 -webkit-transition:350ms;
 transition:350ms
}
.dot_owl_3 .flickity-page-dots .dot.is-selected {
 width:30px!important
}
.dot_owl_1 .flickity-page-dots .dot,
.type_slideshow .dot_owl_1 .flickity-page-dots .dot {
 width:13px;
 height:13px
}
.flickity-page-dots .dot.is-selected {
 background-color:#000
}
.slt4_img img:not(.lazyloadt4sed) {
 -webkit-animation:none;
 animation:none;
 opacity:0!important
}
.dot_owl_2 .flickity-page-dots .dot.is-selected {
 background:rgba(0,0,0,.9)
}
.btn_owl_2 .flickity-prev-next-button,
.btn_owl_3 .flickity-prev-next-button {
 border-radius:0;
 border-width:1.5px
}
.btn_owl_3 .flickity-prev-next-button,
.clno-touchevents .btn_owl_3.flickity-enabled:hover .flickity-prev-next-button {
 -webkit-transform:rotate(45deg);
 transform:rotate(45deg)
}
.btn_owl_3 .flickity-prev-next-button:before {
 -webkit-transform:rotate(-45deg);
 transform:rotate(-45deg)
}
.dot_color_2 .flickity-page-dots .dot {
 background:rgba(255,255,255,.4);
 box-shadow:inset 0 0 3px rgba(0,0,0,.3)
}
.dot_color_2 .flickity-page-dots .dot.is-selected,
.dot_color_2 .flickity-page-dots .dot:after {
 background:#fff
}
.dot_color_3 .flickity-page-dots .dot.is-selected {
 background-color:transparent
}
.dot_color_3 .flickity-page-dots .dot,
.dot_color_3 .flickity-page-dots .dot:after {
 background:#cc9e6c;
 opacity:.4
}
.dot_color_3 .flickity-page-dots .dot.is-selected,
.dot_color_3 .flickity-page-dots .dot:after {
 opacity:1
}
.flickity-enabled.is-fade .flickity-slider>* {
 pointer-events:none;
//  z-index:0;
 opacity:0!important;
 visibility:hidden;
 -webkit-transition:opacity .3s ease-in-out,visibility .3s ease-in-out;
 transition:opacity .3s ease-in-out,visibility .3s ease-in-out
}
.flickity-enabled.is-fade .flickity-slider>.is-selected {
 pointer-events:auto;
//  z-index:auto;
 opacity:1!important;
 visibility:visible;
 -webkit-transition-delay:0s;
 transition-delay:0s
}
// .fade_flick_1 .flickity-slider {
//  -webkit-transform:none!important;
//  transform:none!important
// }
.fade_flick_1.flickity-enabled .p-item,
.fade_flick_1.flickity-enabled .slideshow__slide {
 left:0!important;
 opacity:0;
 visibility:hidden;
 -webkit-transition:opacity .3s ease-in-out,visibility .3s ease-in-out;
 transition:opacity .3s ease-in-out,visibility .3s ease-in-out
}
.rtl_true .fade_flick_1.flickity-enabled .p-item,
.rtl_true .fade_flick_1.flickity-enabled .slideshow__slide {
 left:auto!important;
 right:0!important
}
.browser-Safari.platform-Mac .fade_flick_1.flickity-enabled .p-item {
 -webkit-transition:none;
 transition:none
}
.fade_flick_1 .p-item.is-selected,
.fade_flick_1 .slideshow__slide.is-selected {
 opacity:1;
 visibility:visible;
 -webkit-transition-delay:0s;
 transition-delay:0s
}
.mg__0 {
 margin:0
}
.mt__0 {
 margin-top:0
}
.mt__5 {
 margin-top:5px
}
.mt__7 {
 margin-top:7px
}
.mt__10 {
 margin-top:10px
}
.mt__15 {
 margin-top:15px
}
.mt__20 {
 margin-top:20px
}
.mt__25 {
 margin-top:25px
}
.mt__30 {
 margin-top:30px
}
.mt__35 {
 margin-top:35px
}
.mt__40 {
 margin-top:40px
}
.mt__45 {
 margin-top:45px
}
.mt__50 {
 margin-top:50px
}
.mt__55 {
 margin-top:55px
}
.mt__60 {
 margin-top:60px
}
.mt__65 {
 margin-top:65px
}
.mt__70 {
 margin-top:70px
}
.mt__75 {
 margin-top:75px
}
.mt__80 {
 margin-top:80px
}
.mt__85 {
 margin-top:85px
}
.mt__90 {
 margin-top:90px
}
.mt__95 {
 margin-top:95px
}
.mt__100 {
 margin-top:100px
}
.mr__4 {
 margin-right:4px
}
.mr__7 {
 margin-right:7px
}
.mr__5 {
 margin-right:5px
}
.mr__10 {
 margin-right:10px
}
.mr__15 {
 margin-right:15px
}
.mr__20 {
 margin-right:20px
}
.mr__25 {
 margin-right:25px
}
.mr__30 {
 margin-right:30px
}
.mr__35 {
 margin-right:35px
}
.mr__40 {
 margin-right:40px
}
.mr__45 {
 margin-right:45px
}
.mr__50 {
 margin-right:50px
}
.mr__55 {
 margin-right:55px
}
.mr__60 {
 margin-right:60px
}
.mr__65 {
 margin-right:65px
}
.mr__70 {
 margin-right:70px
}
.mr__75 {
 margin-right:75px
}
.mr__80 {
 margin-right:80px
}
.mr__85 {
 margin-right:85px
}
.mr__90 {
 margin-right:90px
}
.mr__95 {
 margin-right:95px
}
.mr__100 {
 margin-right:100px
}
.mb__5 {
 margin-bottom:5px
}
.mb__10 {
 margin-bottom:10px
}
.mb__15 {
 margin-bottom:15px
}
.mb__20 {
 margin-bottom:20px
}
.mb__25 {
 margin-bottom:25px
}
.mb__30 {
 margin-bottom:30px
}
.mb__35 {
 margin-bottom:35px
}
.mb__40 {
 margin-bottom:40px
}
.mb__45 {
 margin-bottom:45px
}
.mb__50 {
 margin-bottom:50px
}
.mb__55 {
 margin-bottom:55px
}
.mb__60 {
 margin-bottom:60px
}
.mb__65 {
 margin-bottom:65px
}
.mb__70 {
 margin-bottom:70px
}
.mb__75 {
 margin-bottom:75px
}
.mb__80 {
 margin-bottom:80px
}
.mb__85 {
 margin-bottom:85px
}
.mb__90 {
 margin-bottom:90px
}
.mb__95 {
 margin-bottom:95px
}
.mb__100 {
 margin-bottom:100px
}
.ml__4 {
 margin-left:4px
}
.ml__7 {
 margin-left:7px
}
.ml__5 {
 margin-left:5px
}
.ml__10 {
 margin-left:10px
}
.ml__15 {
 margin-left:15px
}
.ml__20 {
 margin-left:20px
}
.ml__25 {
 margin-left:25px
}
.ml__30 {
 margin-left:30px
}
.ml__35 {
 margin-left:35px
}
.ml__40 {
 margin-left:40px
}
.ml__45 {
 margin-left:45px
}
.ml__50 {
 margin-left:50px
}
.ml__55 {
 margin-left:55px
}
.ml__60 {
 margin-left:60px
}
.ml__65 {
 margin-left:65px
}
.ml__70 {
 margin-left:70px
}
.ml__75 {
 margin-left:75px
}
.ml__80 {
 margin-left:80px
}
.ml__85 {
 margin-left:85px
}
.ml__90 {
 margin-left:90px
}
.ml__95 {
 margin-left:95px
}
.ml__100 {
 margin-left:100px
}
.pd__0 {
 padding:0
}
.pt__0 {
 padding-top:0
}
.pt__5 {
 padding-top:5px
}
.pt__10 {
 padding-top:10px
}
.pt__15 {
 padding-top:15px
}
.pt__20 {
 padding-top:20px
}
.pt__25 {
 padding-top:25px
}
.pt__30 {
 padding-top:30px
}
.pt__35 {
 padding-top:35px
}
.pt__40 {
 padding-top:40px
}
.pt__45 {
 padding-top:45px
}
.pt__50 {
 padding-top:50px
}
.pt__55 {
 padding-top:55px
}
.pt__60 {
 padding-top:60px
}
.pt__65 {
 padding-top:65px
}
.pt__70 {
 padding-top:70px
}
.pt__75 {
 padding-top:75px
}
.pt__80 {
 padding-top:80px
}
.pt__85 {
 padding-top:85px
}
.pt__90 {
 padding-top:90px
}
.pt__95 {
 padding-top:95px
}
.pt__100 {
 padding-top:100px
}
.pr__0 {
 padding-right:0
}
.pr__5 {
 padding-right:5px
}
.pr__10 {
 padding-right:10px
}
.pr__15 {
 padding-right:15px
}
.pr__20 {
 padding-right:20px
}
.pr__25 {
 padding-right:25px
}
.pr__30 {
 padding-right:30px
}
.pr__35 {
 padding-right:35px
}
.pr__40 {
 padding-right:40px
}
.pr__45 {
 padding-right:45px
}
.pr__50 {
 padding-right:50px
}
.pr__55 {
 padding-right:55px
}
.pr__60 {
 padding-right:60px
}
.pr__65 {
 padding-right:65px
}
.pr__70 {
 padding-right:70px
}
.pr__75 {
 padding-right:75px
}
.pr__80 {
 padding-right:80px
}
.pr__85 {
 padding-right:85px
}
.pr__90 {
 padding-right:90px
}
.pr__95 {
 padding-right:95px
}
.pr__100 {
 padding-right:100px
}
.pb__0 {
 padding-bottom:0
}
.pb__5 {
 padding-bottom:5px
}
.pb__10 {
 padding-bottom:10px
}
.pb__15 {
 padding-bottom:15px
}
.pb__20 {
 padding-bottom:20px
}
.pb__25 {
 padding-bottom:25px
}
.pb__30 {
 padding-bottom:30px
}
.pb__35 {
 padding-bottom:35px
}
.pb__40 {
 padding-bottom:40px
}
.pb__45 {
 padding-bottom:45px
}
.pb__50 {
 padding-bottom:50px
}
.pb__55 {
 padding-bottom:55px
}
.pb__60 {
 padding-bottom:60px
}
.pb__65 {
 padding-bottom:65px
}
.pb__70 {
 padding-bottom:70px
}
.pb__75 {
 padding-bottom:75px
}
.pb__80 {
 padding-bottom:80px
}
.pb__85 {
 padding-bottom:85px
}
.pb__90 {
 padding-bottom:90px
}
.pb__95 {
 padding-bottom:95px
}
.pb__100 {
 padding-bottom:100px
}
.pl__0 {
 padding-left:0
}
.pl__5 {
 padding-left:5px
}
.pl__10 {
 padding-left:10px
}
.pl__15 {
 padding-left:15px
}
.pl__20 {
 padding-left:20px
}
.pl__25 {
 padding-left:25px
}
.pl__30 {
 padding-left:30px
}
.pl__35 {
 padding-left:35px
}
.pl__40 {
 padding-left:40px
}
.pl__45 {
 padding-left:45px
}
.pl__50 {
 padding-left:50px
}
.pl__55 {
 padding-left:55px
}
.pl__60 {
 padding-left:60px
}
.pl__65 {
 padding-left:65px
}
.pl__70 {
 padding-left:70px
}
.pl__75 {
 padding-left:75px
}
.pl__80 {
 padding-left:80px
}
.pl__85 {
 padding-left:85px
}
.pl__90 {
 padding-left:90px
}
.pl__95 {
 padding-left:95px
}
.pl__100 {
 padding-left:100px
}
.w__100 {
 width:100%
}
.h__100 {
 height:100%
}
.br__40 {
 border-radius:40px
}
.br__50 {
 border-radius:50%
}
.fs__0 {
 font-size:0
}
.fs__10 {
 font-size:10px
}
.fs__12 {
 font-size:12px
}
.fs__13 {
 font-size:13px
}
.fs__14 {
 font-size:14px
}
.fs__15 {
 font-size:15px
}
.fs__16 {
 font-size:16px
}
.fs__18 {
 font-size:18px
}
.fs__20 {
 font-size:20px
}
.fs__24 {
 font-size:24px
}
.fs__25 {
 font-size:25px
}
.fs__30 {
 font-size:30px
}
.fs__35 {
 font-size:35px
}
.fs__40 {
 font-size:40px
}
.fs__45 {
 font-size:45px
}
.fs__50 {
 font-size:50px
}
.fs__55 {
 font-size:55px
}
.fs__60 {
 font-size:60px
}
.fs__80 {
 font-size:80px
}
.fs__90 {
 font-size:90px
}
.db {
 display:block
}
.dn,
.dnt4 {
 display:none
}
.hide,
.js_gift_wrap.dn,
.js_gift_wrap.dnt4 {
 display:none!important
}
.dib {
 display:inline-block
}
.tl {
 text-align:left
}
.tc {
 text-align:center
}
.tr {
 text-align:right
}
.fl {
 float:left
}
.fr {
 float:right
}
.pa {
 position:absolute
}
.pr {
 position:relative
}
.l__0 {
 left:0
}
.r__0 {
 right:0
}
.t__0 {
 top:0
}
.b__0 {
 bottom:0
}
.op__0 {
 opacity:0
}
.ntpf,
.pf {
 position:fixed
}
.pa-center {
 position:absolute;
 left:50%;
 top:50%;
 -webkit-transform:translate3D(-50%,-50%,0);
 -moz-transform:translate3D(-50%,-50%,0);
 -ms-transform:translate3D(-50%,-50%,0);
 -o-transform:translate3D(-50%,-50%,0);
 transform:translate3D(-50%,-50%,0)
}
.nt_full {
 width:100%;
 max-width:100%
}
.nt_co_wide {
 width:100%;
 max-width:630px;
 margin:0 auto
}
.cb {
 color:#000
}
.csi {
 color:#9e9e9e
}
.cra {
 color:#fec42d
}
.cra2 {
 color:#dedede
}
.cd {
 color:#222
}
.chp:hover,
.cp {
 color:#cc9e6c
}
.bghp:hover,
.cw {
 color:#fff
}
.cg {
 color:#878787
}
.cr {
 color:#ec0101
}
.cgreen,
.chg:hover {
 color:#90c11a
}
.bgw {
 background:#fff
}
.bgb {
 background:#000
}
.bgbl {
 background:#f6f6f8
}
.bghp:hover,
.bgp {
 background:#cc9e6c
}
.bgd {
 background:#222
}
.tu {
 text-transform:uppercase
}
.fwb4 {
 font-weight:400
}
.fwm {
 font-weight:500
}
.fwsb {
 font-weight:600
}
.fwb {
 font-weight:700
}
.ls__0 {
 letter-spacing:0
}
.ls__1 {
 letter-spacing:1px
}
.ls__2 {
 letter-spacing:2px
}
.ls__3 {
 letter-spacing:3px
}
.ls__4 {
 letter-spacing:4px
}
.ls__5 {
 letter-spacing:5px
}
.ts__03 {
 transition:.3s
}
.ts__04 {
 transition:.4s
}
.ts__05 {
 transition:.5s
}
.ts_op {
 -webkit-transition:opacity .3s ease-in-out;
 -moz-transition:opacity .3s ease-in-out;
 -o-transition:opacity .3s ease-in-out;
 transition:opacity .3s ease-in-out
}
.lh__1 {
 line-height:1
}
.f__pop {
 font-family:Poppins,
}
.f__libre {
 font-family:Poppins;
 font-style:italic
}
.lazyloadt4sed>.img_first {
 opacity:0;
 display:none
}
.oh {
 overflow:hidden
}
.wsn {
 white-space:nowrap
}
.grid-sizer,
.pe_none {
 pointer-events:none
}
.pe_auto {
 pointer-events:auto
}
.z_100 {
//  z-index:100
}
.z_200 {
//  z-index:200
}
.tt_txt {
 font-size:0;
 opacity:0;
 visibility:hidden
}
.ttip_nt {
 position:relative;
 display:inline-block
}
.flex {
 display:-webkit-box;
 display:-ms-flexbox;
 display:flex
}
.in_flex {
 display:-ms-inline-flexbox;
 display:-webkit-inline-flex;
 display:inline-flex
}
.truncate {
 overflow:hidden;
 text-overflow:ellipsis;
 white-space:nowrap
}
.cover_norepeat {
 background-repeat:no-repeat;
 background-size:cover
}
.txt_under {
 text-decoration:underline!important
}
.flex .grow {
 -webkit-flex:1 1 auto;
 -ms-flex:1 1 auto;
 -webkit-box-flex:1;
 flex:1 1 auto
}
.flex.column,
.in_flex.column {
 -webkit-flex-direction:column;
 -ms-flex-direction:column;
 -webkit-box-orient:vertical;
 -webkit-box-direction:column;
 flex-direction:column
}
.flex.wrap,
.in_flex.wrap {
 -webkit-flex-direction:row;
 -ms-flex-direction:row;
 -webkit-box-orient:horizontal;
 -webkit-box-direction:normal;
 flex-direction:row;
 -webkit-flex-wrap:wrap;
 -ms-flex-wrap:wrap;
 flex-wrap:wrap
}
.fl__row {
 display:-webkit-box;
 display:-ms-flexbox;
 display:flex;
 -ms-flex-flow:row nowrap;
 flex-flow:row nowrap;
 -webkit-box-align:center;
 -ms-flex-align:center;
 align-items:center;
 -ms-flex-pack:justify;
 justify-content:space-between
}
.al_center {
 -webkit-box-align:center;
 -ms-flex-align:center;
 align-items:center
}
.fl_center {
 justify-content:center;
 -ms-flex-pack:center;
 -webkit-justify-content:center;
 -ms-justify-content:center
}
.fl_between {
 -webkit-justify-content:space-between;
 -ms-flex-pack:justify;
 -webkit-box-pack:justify;
 justify-content:space-between
}
.fl_right {
 -webkit-box-pack:end;
 -ms-flex-pack:end;
 justify-content:flex-end
}
.fl_left {
 -webkit-box-pack:start;
 -ms-flex-pack:start;
 justify-content:flex-start
}
.is-flex {
 display:-webkit-box;
 display:-webkit-flex;
 display:-ms-flexbox;
 display:flex;
 -webkit-flex-wrap:wrap;
 -ms-flex-wrap:wrap;
 flex-wrap:wrap
}
.ul_none {
 list-style:none
}
.shopify-error,
.shopify-info,
.shopify-message,
.shopify-warning {
 border:2px solid;
 border-radius:0;
 overflow:hidden;
 margin:20px 0 0;
 padding:14px 25px 14px 55px;
 line-height:1.4;
 position:relative;
 font-size:14px;
 -webkit-box-sizing:border-box;
 -moz-box-sizing:border-box;
 box-sizing:border-box
}
.shopify-error ul,
.shopify-info ul,
.shopify-message ul,
.shopify-warning ul {
 list-style:none
}
.shopify-error i,
.shopify-info i,
.shopify-message i,
.shopify-warning i {
 position:absolute;
 top:50%;
 left:24px;
 line-height:0;
 font-size:18px;
 font-weight:900
}
.shopify-error,
.shopify-info {
 color:#af7d27;
 border-color:#e0b252;
 background-color:transparent
}
.shopify-error a,
.shopify-info a {
 color:#af7d27;
 text-decoration:underline
}
.shopify-error,
.shopify-error a {
 color:#e90505;
 border-color:#e90505
}
.shopify-message {
 color:#3c763d;
 border-color:#459647;
 background-color:transparent
}
.shopify-message a {
 color:#3c763d;
 text-decoration:underline
}
.shopify-warning,
.shopify-warning a {
 color:#f0ad4e
}
.sp_notice {
 position:fixed;
 bottom:0;
 left:0;
 right:0;
 display:-ms-flexbox;
 display:flex;
 -ms-flex-flow:row wrap;
 flex-flow:row wrap;
 -ms-flex-align:center;
 align-items:center;
//  z-index:69900;
 background-color:#e0b252;
 color:#fff;
 cursor:pointer;
 -webkit-transform:translate3d(0,100%,0);
 transform:translate3d(0,100%,0);
 transition:.35s;
 min-height:60px;
 padding:10px 80px 10px 65px;
 border:0
}
.sp_notice .shopify-message {
 background-color:#459647
}
.sp_notice .pegk {
 position:absolute;
 top:0;
 left:auto;
 right:0;
 bottom:0;
 display:-ms-flexbox;
 display:flex;
 -ms-flex-align:center;
 align-items:center;
 -ms-flex-pack:center;
 justify-content:center;
 width:60px;
 background-color:rgba(0,0,0,.1);
 font-weight:500;
 font-size:45px;
 cursor:pointer;
 transition:background-color .25s
}
.sp_notice:hover .pegk {
 background-color:rgba(0,0,0,.2)
}
.sp_notice.show_notice {
 -webkit-transform:none;
 transform:none
}
.nt_menu>li.has-children>a>i {
 color:rgba(82,82,82,.6)
}
#cat_shopify .nav_link_icon {
 display:none
}
html.wf-faKalles-n4-loading .fa47:before,
html.wf-fontawesome5free-n4-loading .fa:before,
html.wf-fontawesome5free-n4-loading .fas:before,
html.wf-peicon7stroke-n4-loading .pegk:before {
 opacity:0
}
.row:after,
.row:before {
 display:none!important
}
.branding>a {
 font-size:1.25em;
 text-transform:uppercase;
 letter-spacing:.1em
}
.header_1 .branding img,
.header_4 .branding img,
.header_5 .branding img,
.header_7 .branding img,
.header_8 .branding img {
 margin:0 auto
}
li.menu-item.branding.ts__05.lh__1 {
 display:block
}
.nt_menu>li.menu-item.branding>a {
 height:auto
}
body:not(.des_header_7) #ntheader {
 position:relative
}
.header-text i {
 font-size:16px;
 vertical-align:middle
}
.header-text p {
 margin:0
}
.shopify-policy__container {
 min-height:60vh;
 margin-top:60px!important;
 margin-bottom:60px!important
}
.shopify-policy__title {
 margin-bottom:50px
}
.icon_cart .js_cat_ttprice .cart_dis_price,
.icon_cart .js_cat_ttprice .cart_ori_price {
 display:none
}
.nt_action>a,
.nt_action>div {
 padding:0 6px
}
.nt_action>a>i,
.nt_action>div>a>i {
 font-size:22px;
 line-height:1
}
.h_icon_pegk .nt_action>a>i,
.h_icon_pegk .nt_action>div>a>i {
 font-size:24px
}
.h_icon_la .nt_action>a>i,
.h_icon_la .nt_action>div>a>i {
 font-size:28px
}
.h_icon_la .nt_action a>i.la-shopping-cart {
 font-size:32px
}
.nt_menu li {
 font-size:14px;
 list-style:none;
 position:relative
}
.nt_menu>li>a {
 padding-right:17.5px;
 padding-left:17.5px
}
.nt_menu>li>a>i {
 font-size:22px;
 margin-right:4px
}
.header_5 .nt_menu>li>a:first-child {
 padding-left:5px
}
.lbc_nav,
.lbc_nav_mb {
 opacity:0
}
.type_btn .button {
 box-shadow:0 5px 15px 0 rgba(0,0,0,.15);
 height:40px
}
.type_btn.br_radius_false .button {
 border-radius:0
}
.header__bot.border_true {
 border-top:1px solid #ececec
}
.nt_menu>li>.sub-menu {
 position:absolute;
 opacity:0;
 visibility:hidden;
 pointer-events:none
}
.header_search input.search__input {
 min-width:290px;
 width:100%;
 height:46px;
 border-radius:30px;
 border:2px solid rgba(129,129,129,.2);
 padding-right:50px
}
.header_search input.search__input:focus {
 border-color:rgba(129,129,129,.2)
}
button.search__submit {
 padding:0 15px;
 width:55px;
 background-color:#cc9e6c;
 color:#fff;
 border:0;
 border-radius:0 30px 30px 0
}
button.search__submit i {
 font-weight:900
}
.header_6 .header__bot.border_true,
.header_9 .header__bot.border_true {
 border:1px solid #ececec
}
.header_hcat>span {
 letter-spacing:.4px
}
.header_hcat>i {
 font-weight:900
}
.header_categories {
 color:#222;
 font-size:14px
}
.border_true .header_categories {
 border-right:1px solid #ececec
}
.categories_menu {
 top:100%;
//  z-index:299;
 visibility:hidden;
 transition:.2s;
 -webkit-transform:translateY(15px) translateZ(0);
 transform:translateY(15px) translateZ(0);
 border:1px solid rgba(129,129,129,.2)
}
.header_categories:hover .categories_menu {
 visibility:visible;
 opacity:1;
 -webkit-transform:none;
 transform:none;
 pointer-events:visible;
 pointer-events:unset
}
.nt_action>a,
.nt_action>div>a {
 color:#222
}
.header_8 .nt_action .push-menu-btn img {
 transform:rotate(180deg);
 -webkit-transform:rotate(180deg)
}
.menu_wid_full .sub-menu,
.menu_wid_full .sub-menu.lazyloadt4sed {
 width:100vw;
 padding:10px 20px
}
.menu_wid_12 .sub-menu,
.menu_wid_12 .sub-menu.lazyloadt4sed {
 width:1200px
}
.menu_wid_full .container {
 max-width:100%
}
.h_transparent_true.template-index #ntheader .ntheader_wrapper {
 position:absolute;
 top:0;
 right:0;
 left:0;
//  z-index:390;
 backface-visibility:hidden;
 -webkit-backface-visibility:hidden
}
.header_7 {
 position:relative
}
body:not(.h_calc_ready) #shopify-section-header_banner {
 position:absolute;
 top:0;
 left:0;
 right:0;
 opacity:0;
 pointer-events:none
}
#shopify-section-header_banner {
 transition:height .6s .25s;
 -webkit-transition:height .6s .25s;
 will-change:height
}
.h_calc_ready #shopify-section-header_banner {
 position:relative;
 overflow:hidden;
 height:0
}
.h__banner .col-auto:first-child {
 opacity:0
}
#nav_header7 ul,
.mb_nav_tab ul {
 list-style:none
}
.nt_mb_menu .sub-menu li>a,
.nt_mb_menu>li>a {
 display:-webkit-box;
 display:-ms-flexbox;
 display:flex;
 -webkit-box-align:center;
 -ms-flex-align:center;
 align-items:center;
 -webkit-flex-direction:row;
 -ms-flex-direction:row;
 -webkit-box-orient:horizontal;
 -webkit-box-direction:normal;
 flex-direction:row;
 padding:5px 20px;
 min-height:50px;
 border-bottom:1px solid rgba(129,129,129,.2);
 color:#222;
 font-size:14px;
 text-align:left;
 line-height:1.3
}
#nav_header7 .nt_mb_menu .sub-menu li>a,
#nav_header7 .nt_mb_menu>li>a {
 padding:5px
}
.nt_mb_menu>li>a {
 letter-spacing:.2px;
 font-size:14px
}
.has_ntmenu .mfp-close,
.nt_mb_menu .sub-menu,
.nt_mb_menu .sub-menu li a.currency-item.dn,
.nt_mb_menu .sub-menu li a.currency-item.dnt4,
.nt_mb_menu .sub-sub-menu,
.sub-sub-sub-menu {
 display:none
}
.nt_currency.curt4_fixed .nt_currency__div {
 position:fixed;
 top:50%;
 margin-top:-22px;
 padding:10px;
 box-shadow:9px 5px 10px #dfe3ea,-5px -5px 10px #faffff;
 right:0;
 background-color:#fff;
 box-shadow:0 0 5px rgba(0,0,0,.17);
//  z-index:104;
 min-width:85px;
 cursor:pointer;
 border-radius:4px
}
.nt_currency.curt4_fixed.languages .nt_currency__div {
 top:44%
}
.nt_currency.curt4_fixed ul {
 display:none
}
.placeholder-svg {
 display:block;
 fill:#cc9e6c;
 background-color:rgba(86,207,225,.1);
 width:100%;
 border:1px solid rgba(86,207,225,.5)
}
.h__lz_img {
 background-size:cover;
 background-repeat:no-repeat
}
.des_header_7 .h__lz_img .sp_header_mid {
 background-color:transparent
}
.nt_count {
 opacity:0;
 -webkit-transition:opacity .4s ease-in-out;
 -moz-transition:opacity .4s ease-in-out;
 -o-transition:opacity .4s ease-in-out;
 transition:opacity .4s ease-in-out
}
.page-head .nt_bg_lz.lazyloadt4s:before,
.page-head .nt_bg_lz.lazyloadt4sed:before,
.page-head .nt_bg_lz.lazyloadt4sing:before {
 display:none
}
.has_icon {
 display:inline-block;
 display:-ms-inline-flexbox;
 display:inline-flex;
 -ms-flex-wrap:wrap;
 flex-wrap:wrap;
 -ms-flex-align:center;
 align-items:center
}
.has_icon>i {
 font-size:16px;
 margin-left:.2em;
 opacity:.6
}
.has_icon.mgr>i {
 margin-right:.2em;
 margin-left:0
}
.cat_sortby>a {
 position:relative;
//  z-index:8;
 font-size:14px;
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 -ms-user-select:none;
 user-select:none
}
.btn_filter,
.btn_sidebar,
.cat_sortby>a {
 color:#878787
}
.btn_filter>i,
.btn_sidebar>i {
 font-size:16px;
 opacity:1;
 transition:none!important
}
.btn_sidebar>svg {
 fill:currentColor
}
.page-head {
 padding:50px 0;
 color:#ededed
}
.page-head h1 {
 font-size:20px;
 line-height:20px;
 font-weight:500
}
.page-head:before {
 content:'';
 opacity:.5;
 position:absolute;
 background:#000;
 left:0;
 top:0;
 width:100%;
 height:100%;
 z-index:99;
 pointer-events:none
}
.page-head a {
 color:#fff
}
.page-head p {
 margin-bottom:0;
 margin-top:5px
}
.hidden_true.sidebar_nt {
 margin-top:0!important
}
.hidden_true .section_sidebar_shop,
.nt_pop_sidebar .section_nt_filter,
.pop_default .section_nt_filter>.h3,
.pop_default .section_nt_filter>h3,
.section_sidebar_shop {
 display:none
}
.cat_view a {
 border:1px solid currentcolor;
 color:#878787;
 --active-view-cl:#878787;
 height:26px;
 transition:.25s
}
.cat_view a.view_12 {
 width:28px
}
.cat_view a.view_6,
.cat_view a.view_list {
 width:29px
}
.cat_view a.view_4 {
 width:42px
}
.cat_view a.view_3 {
 width:55px
}
.cat_view a.view_15 {
 width:68px
}
.cat_view a.view_2 {
 width:81px
}
.cat_view a.view_list:before {
 right:2px;
 height:5px;
 width:23px;
 box-shadow:0 7.3px 0 currentColor,0 15.1px 0 currentColor;
 box-shadow:0 7.3px 0 var(--active-view-cl),0 15.1px 0 var(--active-view-cl)
}
.cat_view a.view_12:before {
 width:22px
}
.cat_view a.view_6:before {
 box-shadow:13px 0 0 currentColor,13px 0 0 currentColor;
 box-shadow:13px 0 0 var(--active-view-cl),13px 0 0 var(--active-view-cl)
}
.cat_view a.view_4:before {
 box-shadow:13px 0 0 currentColor,26px 0 0 currentColor;
 box-shadow:13px 0 0 var(--active-view-cl),26px 0 0 var(--active-view-cl)
}
.cat_view a.view_3:before {
 box-shadow:13px 0 0 currentColor,26px 0 0 currentColor,39px 0 0 currentColor;
 box-shadow:13px 0 0 var(--active-view-cl),26px 0 0 var(--active-view-cl),39px 0 0 var(--active-view-cl)
}
.cat_view a.view_2:before {
 box-shadow:13px 0 0 currentColor,26px 0 0 currentColor,39px 0 0 currentColor,52px 0 0 currentColor,65px 0 0 currentColor;
 box-shadow:13px 0 0 var(--active-view-cl),26px 0 0 var(--active-view-cl),39px 0 0 var(--active-view-cl),52px 0 0 var(--active-view-cl),65px 0 0 var(--active-view-cl)
}
.cat_view a.view_15:before {
 box-shadow:13px 0 0 currentColor,26px 0 0 currentColor,39px 0 0 currentColor,52px 0 0 currentColor;
 box-shadow:13px 0 0 var(--active-view-cl),26px 0 0 var(--active-view-cl),39px 0 0 var(--active-view-cl),52px 0 0 var(--active-view-cl)
}
.cat_view a.active {
 pointer-events:none
}
.cat_view.on_list_view_true a:not(.view_list) {
 color:#878787!important;
 --active-view-cl:#878787!important
}
.cat_view a.active,
.cat_view a:hover,
.cat_view.on_list_view_true a.view_list {
 color:#222;
 --active-view-cl:#222
}
.cat_view a:before {
 content:"";
 position:absolute;
 top:2px;
 left:2px;
 width:10px;
 height:20px;
 background:currentcolor;
 background:var(--active-view-cl);
 transition:.3s
}
.sp_result_html .cp {
 margin-right:3px
}
.result_clear {
 font-size:0
}
.result_clear>.clear_filter {
 font-size:15px
}
.result_clear>.clear_filter:not(:last-child) {
 margin-right:15px;
 padding-right:15px;
 border-right:1px solid #e5e5e5;
 margin-bottom:10px
}
a.clear_filter {
 position:relative;
 padding-left:16px
}
a.clear_filter:after,
a.clear_filter:before {
 content:" ";
 position:absolute;
 top:50%;
 left:0;
 display:inline-block;
 margin-top:-1px;
 width:10px;
 height:2px;
 background-color:currentColor;
 transition:background-color .2s ease-in-out,transform .2s ease-in-out,width .2s ease-in-out,-webkit-transform .2s ease-in-out;
 -webkit-transform:rotate(45deg);
 transform:rotate(45deg)
}
a.clear_filter:after {
 -webkit-transform:rotate(-45deg);
 transform:rotate(-45deg)
}
a.clear_filter:hover:after,
a.clear_filter:hover:before {
 -webkit-transform:rotate(0);
 transform:rotate(0)
}
.nt-pagination {
 border-top:1px solid #ddd
}
.page-numbers {
 display:inline-block;
 list-style:none;
 margin:10px 0;
 padding:5px 10px
}
.page-numbers li {
 float:left;
 margin:0 10px;
 color:#222
}
.page-numbers li a {
 color:#222
}
.page-numbers li a:hover,
.page-numbers.current {
 color:#ec0101
}
a.nt_cat_lm,
a.view_more {
 padding:11px 45px
}
.products.space_0 .nt_pr {
 margin-top:0
}
.products.space_20 .nt_pr {
 margin-top:20px
}
.products.space_40 .nt_pr {
 margin-top:40px
}
.products.space_50 .nt_pr {
 margin-top:50px
}
a.br_rd_false {
 border-radius:0
}
.hover_button {
 max-width:100%;
 padding:0 5px;
 top:40%;
 left:50%;
 -webkit-transform:translate(-50%,-50%);
 transform:translate(-50%,-50%);
//  z-index:2;
 white-space:nowrap;
 opacity:0;
 visibility:hidden
}
.equal_nt .nt_bg_lz,
.pr_lazy_img {
 display:block;
 width:100%;
 height:100%;
 position:relative;
 background-repeat:no-repeat;
 background-size:cover
}
.p-thumb.equal_nt .nt_bg_lz {
 height:auto
}
.pr_lazy_img {
 background-position:center center
}
.nt_bg_lz.lazyloadt4s,
.nt_bg_lz.lazyloadt4sing,
.nt_fk_lz,
.nt_load.lazyloadt4sed {
 position:relative;
 background-color:#f5f5f5;
 background-image:none!important
}
.lds_bginfinity:before,
.nt_bg_lz.lazyloadt4s:before,
.nt_bg_lz.lazyloadt4sing:before,
.nt_fk_lz:before,
.nt_load.lazyloadt4sed:before {
 position:absolute;
 content:"";
 display:block;
 width:100%;
//  z-index:90;
 top:0;
 left:0;
 right:0;
 bottom:0;
 background-repeat:no-repeat;
 background-position:center;
 background-size:50px
}
.nt_fk_lz:before {
//  z-index:2
}
.quote_avatar .nt_bg_lz.lazyloadt4s:before,
.quote_avatar .nt_bg_lz.lazyloadt4sing:before {
 background-size:30px
}
.p-nav .nt_bg_lz.lazyloadt4s,
.p-nav .nt_bg_lz.lazyloadt4sing {
 transition:none
}
.p-nav .nt_bg_lz.lazyloadt4s:before,
.p-nav .nt_bg_lz.lazyloadt4sing:before {
 background-size:40px
}
.lds_bginfinity {
 width:50px;
 height:50px;
 margin-left:auto;
 margin-right:auto
}
.lds_bginfinity:before {
 background-size:50px
}
.nt_slider:not(.flickity-enabled)>.lds_bginfinity,
.nt_slider:not(.flickity-enabled)>.no_ins_nt {
 display:block!important
}
.equal_nt.ratio8_5 .nt_bg_lz {
 padding-top:62.5%
}
.equal_nt.ratio3_2 .nt_bg_lz {
 padding-top:66.66%
}
.equal_nt.ratio2_1 .nt_bg_lz {
 padding-top:50%
}
.equal_nt.ratio1_2 .nt_bg_lz {
 padding-top:200%
}
.equal_nt.ratio4_3 .nt_bg_lz {
 padding-top:75%
}
.equal_nt.ratio16_9 .nt_bg_lz {
 padding-top:56.25%
}
.equal_nt.ratio1_1 .nt_bg_lz {
 padding-top:100%
}
.equal_nt.ratio_nt .nt_bg_lz {
 padding-top:127.7777778%
}
.equal_nt.ratio2_3 .nt_bg_lz {
 padding-top:150%
}
.equal_nt.nt_contain .nt_bg_lz,
.item__position.nt_contain {
 background-size:contain
}
.equal_nt.position_1 .nt_bg_lz {
 background-position:left top
}
.equal_nt.position_2 .nt_bg_lz {
 background-position:left center
}
.equal_nt.position_3 .nt_bg_lz {
 background-position:left bottom
}
.equal_nt.position_4 .nt_bg_lz {
 background-position:right top
}
.equal_nt.position_5 .nt_bg_lz {
 background-position:right center
}
.equal_nt.position_6 .nt_bg_lz {
 background-position:right bottom
}
.equal_nt.position_7 .nt_bg_lz {
 background-position:center top
}
.equal_nt.position_8 .nt_bg_lz {
 background-position:center center
}
.equal_nt.position_9 .nt_bg_lz {
 background-position:center bottom
}
.nt_pr .hover_img {
 width:100%;
 -webkit-transition:.4s ease-in-out;
 transition:.4s ease-in-out
}
.price,
.price_range {
 color:#696969;
 margin:0
}
.price del,
.price_range del {
 color:#878787
}
.price ins,
.price_range ins {
 color:#ec0101;
 text-decoration:none;
 margin-left:6px
}
.rtl_true .price ins,
.rtl_true .price_range ins {
 margin-left:auto;
 margin-right:6px;
 display:inline-block
}
.price__unit {
 font-size:12px
}
.entry-summary .price__unit {
 font-size:14px
}
.nt_labels {
 opacity:0;
 visibility:hidden
}
.swatch__list_js {
 min-height:38px
}
.swatch_list_size_small .swatch__list_js {
 min-height:28px
}
.swatch_list_size_large .swatch__list_js {
 min-height:63px
}
.shopify-ready .r-wapper-collection .ryviu-item,
.shopify-ready .r-wapper-collection .ryviu-number-widget {
 padding:0
}
.desgin__2 .product-image {
 border-radius:5px;
 -webkit-box-shadow:0 0 12px rgba(0,0,0,.12);
 box-shadow:0 0 12px rgba(0,0,0,.12)
}
.desgin__2 .swatch__list_js.lazyloadt4s,
.desgin__2 .swatch__list_js.lazyloadt4sing,
.row_pr_1.tc .desgin__1 .swatch__list_js.lazyloadt4s,
.row_pr_1.tc .desgin__1 .swatch__list_js.lazyloadt4sing {
 background-position:center
}
.product-image .product-attr,
.product-image a.db:before,
.product-image a.wishlistadd {
 opacity:0;
 visibility:hidden
}
.nt_isotope .spr-badge,
.nt_isotope .star-rating,
.nt_packery .spr-badge,
.nt_packery .star-rating,
.nt_slider .spr-badge,
.nt_slider .star-rating {
 min-height:24px
}
.mfp-hide {
 display:none!important
}
.on_list_view_true .pr_list_item {
 margin-top:0
}
.on_list_view_true .col-lg-listt4,
.on_list_view_true .col-listt4,
.on_list_view_true .col-md-listt4 {
 -ms-flex:0 0 100%;
 flex:0 0 100%;
 max-width:100%
}
.on_list_view_true .pr_list_item .product-image {
 width:120px;
 min-width:80px;
 margin-right:20px
}
.rtl_true .on_list_view_true .pr_list_item .product-image {
 margin-right:auto;
 margin-left:20px
}
.on_list_view_true .pr_list_item .product-info {
 margin-top:0!important;
 flex:1 0 0;
 max-width:calc(100% - 100px)
}
.on_list_view_true .pr_list_item .product-info__btns {
 -ms-flex-item-align:center;
 align-self:center
}
.on_list_view_true .pr_list_item .product-inner {
 display:-webkit-box;
 display:-ms-flexbox;
 display:flex;
 padding:20px;
 border:1px solid #eee;
 border-top:0;
 -ms-flex-align:start;
 align-items:flex-start
}
.on_list_view_true .grid-sizer+.pr_list_item .product-inner,
.on_list_view_true .pr_list_item:first-child .product-inner {
 border-top:1px solid #eee;
 margin-top:30px
}
.on_list_view_true .pr_list_item .product-info__btns>a {
 position:relative;
 display:inline-block;
 padding:0 30px;
 line-height:40px;
 text-align:center;
 cursor:pointer;
 transition:color .25s,background-color .25s,border-color .25s,box-shadow,opacity .25s;
 background-color:#007e91;
 color:#fff;
 font-size:14px;
 margin-top:10px;
 box-shadow:0 5px 15px 0 rgba(0,0,0,.15);
 overflow:hidden;
 text-overflow:ellipsis;
 white-space:nowrap
}
.btnt4_style_1 .on_list_view_true .pr_list_item .product-info__btns>a {
 border-radius:2px
}
.on_list_view_true .pr_list_item .product-info__btns>a:hover {
 opacity:.85
}
.on_list_view_true .pr_list_item .product-info__btns>a.nt_add_qv {
 margin-top:0;
 background-color:#fff;
 color:#007e91;
 border:1px solid currentcolor;
 box-shadow:none
}
.on_list_view_false .pr_list_item .product-info__btns,
.on_list_view_true .pr_list_item .product-image .hover_button,
.on_list_view_true .pr_list_item .product-info__btns>a>.tt_txt,
.product-info__btns>a>i {
 display:none
}
.on_list_view_true .pr_list_item .product-inner:hover .nt_add_cp>a,
.on_list_view_true .pr_list_item .product-inner:hover .product-attr,
.on_list_view_true .pr_list_item .product-inner:hover a.db:before,
.on_list_view_true .pr_list_item .product-inner:hover a.nt_w_login,
.on_list_view_true .pr_list_item .product-inner:hover a.wishlistadd {
 opacity:1;
 visibility:visible
}
.sepr_coun_dt_wrap .sepr_coun_dt {
 position:relative;
//  z-index:12;
 margin:0 -10px;
 display:-ms-inline-flexbox;
 display:inline-flex;
 -ms-flex-wrap:wrap;
 flex-wrap:wrap
}
.sepr_coun_dt_wrap .sepr_coun_dt>span {
 background-color:transparent;
 display:inline-block;
 margin:0 10px;
 position:relative;
 line-height:1;
 text-align:center;
 min-width:auto;
 min-height:auto;
 padding:0;
 border-radius:0;
 box-shadow:none
}
.sepr_coun_dt_wrap .sepr_coun_dt>span>.cd_timet4 {
 display:block;
 background:#000;
 color:#fff;
 line-height:1;
 padding:15px 0;
 border-radius:4px;
 width:50px;
 font-size:17.4px
}
.sepr_coun_dt_wrap .sepr_coun_dt>span>.cd_txtt4 {
 display:block;
 text-transform:uppercase;
 padding-top:7px;
 font-weight:600;
 color:#333;
 font-size:11.6px;
 line-height:1.5
}
@media (min-width:768px) {
 .size_secd_extra_large .sepr_coun_dt {
  margin:0 -20px
 }
 .size_secd_extra_large .sepr_coun_dt>span {
  margin:0 20px
 }
 .size_secd_extra_large .sepr_coun_dt>span>.cd_timet4 {
  width:70px;
  padding:21px 0;
  font-size:22.185px
 }
 .size_secd_extra_large .sepr_coun_dt>span>.cd_txtt4 {
  font-size:15.66px
 }
 .size_secd_large .sepr_coun_dt>span>.cd_timet4 {
  width:55px
 }
}
.size_secd_small .sepr_coun_dt>span>.cd_timet4 {
 width:46px;
 padding:12px 0
}
.des_secd_2 .sepr_coun_dt>span>.cd_timet4 {
 background:#ffff;
 color:#333;
 box-shadow:0 0 3px rgb(0 0 0 / 10%)
}
.des_secd_3 .sepr_coun_dt>span>.cd_timet4 {
 background:0 0;
 color:#f30;
 border:1px solid #ddd
}
.des_secd_4 .sepr_coun_dt>span>.cd_timet4 {
 background-color:transparent;
 border:1px solid #aaa
}
.des_secd_4 .sepr_coun_dt>span>.cd_txtt4 {
 color:#ddd
}
.sp-breadcrumb {
 padding:0;
 font-size:13px
}
.sp-breadcrumb i {
 margin:0 8px
}
.sp-single .sp-breadcrumb {
 line-height:1.4
}
.product-quickview .entry-summary>:not(:last-child),
.sp-single .entry-summary>:not(:last-child),
.type_featured-product .entry-summary>:not(:last-child) {
 margin-bottom:20px
}
.product_title {
 font-size:16px;
 margin-bottom:10px!important;
 font-weight:600
}
.pr_carousel:after {
 content:'flickity';
 display:none
}
.watchCSS:after {
 content:'';
 display:none
}
.p-nav {
 opacity:1
}
.p-nav.nt_slider:not(.flickity-enabled)>.row {
 display:block!important
}
.btn_pnav_next,
.btn_pnav_prev {
 display:none
}
.row.p-nav {
 margin-top:5px;
 margin-right:-2.5px;
 margin-left:-2.5px
}
.p-nav .n-item {
 overflow:hidden;
 padding-left:2.5px;
 padding-right:2.5px
}
.thumb_bottom.thumb_col_4 .p-nav .n-item {
 -ms-flex:0 0 25%;
 flex:0 0 25%;
 max-width:25%
}
.thumb_bottom.thumb_col_5 .p-nav .n-item {
 -ms-flex:0 0 20%;
 flex:0 0 20%;
 max-width:20%
}
.thumb_bottom.thumb_col_6 .p-nav .n-item {
 -ms-flex:0 0 16.666667%;
 flex:0 0 16.666667%;
 max-width:16.666667%
}
.p-nav .nt_bg_lz {
 background-position:center center
}
.p-nav .n-item:not(.is-nav-selected) {
 opacity:.6
}
.equal_nt.p-nav .n-item>img.nt_bg_lz {
 height:0
}
.p-nav .n-item .nt_bg_lz {
 margin-bottom:-5px;
 opacity:.5;
 transition:transform .6s,opacity .6s;
 -ms-transform:translateY(0);
 transform:translateY(0)
}
.p-nav .n-item.is-nav-selected .nt_bg_lz,
.p-nav .n-item:hover .nt_bg_lz {
 -ms-transform:translateY(-5px);
 transform:translateY(-5px);
 opacity:1
}
.price-review>div {
 -ms-order:2;
 order:2;
 display:-ms-flexbox;
 display:flex;
 width:auto
}
.price-review .product-widget__ryviu .ryviu-item,
.price-review .product-widget__ryviu .ryviu-number-widget {
 padding:0 2.5px
}
.entry-summary .price,
.entry-summary .price_range {
 font-size:22px;
 line-height:22px
}
.nt_cart_form .price {
 margin-right:0;
 margin-bottom:20px;
 display:block;
 line-height:1
}
.price del [class*=tooltip_] {
 display:inline
}
.swatch {
 margin-bottom:10px
}
.swatch:first-child {
  margin-top: 1rem;
}
.swatch__title {
 font-size:14px;
 text-transform:uppercase;
 font-weight:700
}
.swatch__list_pr {
 list-style:none;
 margin:10px -5px;
 padding:0
}
.swatch_pr_item {
 display:inline-block;
 margin:3px;
 cursor:pointer
}
.swatch__value_pr {
 padding:5px;
 display:inline-block;
 text-align:center;
 background-position:center center!important;
 background-repeat:no-repeat!important;
 background-size:cover!important
}
.style_color .is-color .swatch_pr_item {
 margin:3px;
 border:1px solid #ddd;
 padding:2px
}
.style_color .is-color .swatch_pr_item.nt_soldout {
 border-style:outset
}
.style__circle .is-label .swatch_pr_item.is-selected,
.style__circle .is-label .swatch_pr_item.is-selected-nt,
.style_color .is-color .swatch_pr_item.is-selected,
.style_color .is-color .swatch_pr_item.is-selected-nt {
 background-color:#333;
 border-color:#333;
 color:#fff
}
.style_color .is-color .swatch__value_pr {
 display:block;
 width:24px;
 height:24px;
 font-size:0;
 padding:0
}
.style_color.size_small .is-color .swatch__value_pr {
 width:16px;
 height:16px
}
.style_color.size_large .is-color .swatch__value_pr {
 width:30px;
 height:30px
}
.style_color.size_exlarge .is-color .swatch__value_pr {
 width:40px;
 height:40px
}
.fimg_true.style_color .fimg_ratio.is-color .swatch__value_pr {
 padding-top:100%;
 width:35px;
 height:auto!important
}
.fimg_true.style_color.size_small .fimg_ratio.is-color .swatch__value_pr {
 width:25px
}
.fimg_true.style_color.size_large .fimg_ratio.is-color .swatch__value_pr {
 width:44px
}
.fimg_true.style_color.size_exlarge .fimg_ratio.is-color .swatch__value_pr {
 width:55px
}
.img_w25 {
 max-width:25px
}
.style_color.des_color_1 .is-color .swatch__value_pr,
.style_color.des_color_1 .is-color .swatch_pr_item {
 border-radius:50%
}
.style_color.des_color_1 .is-color .swatch_pr_item {
 background-color:#fff;
 border-width:2px
}
.variations.style__circle .swatch.is-label .swatch_pr_item {
 margin:3px;
 border-radius:40px;
 border:1px solid #ddd;
 padding:5px;
 max-width:100%
}
.variations.style__circle .swatch.is-label .swatch__value_pr {
 padding:0;
 display:block;
 min-width:18px;
 min-height:18px;
 line-height:18px;
 border-radius:40px;
 text-align:center
}
.swatch.is-label .swatch_pr_item:not(.is-selected):hover .swatch__value_pr {
 color:#222
}
.variations.style__circle.size_small .swatch.is-label .swatch__value_pr {
 min-width:15px;
 min-height:15px;
 line-height:15px
}
.variations_form .quantity {
 min-width:120px;
 width:120px;
 height:40px;
 border:1px solid #222;
 text-align:center;
 border-radius:40px;
 display:inline-block
}
shopify-payment-terms {
 margin-bottom:15px;
 display:inline-block
}
.ttip_nt .tt_txt {
 opacity:0;
 position:absolute;
 pointer-events:none
}
.variations_form .quantity.qty__false {
 display:none!important
}
.quantity input.input-text[type=number] {
 -moz-appearance:textfield;
 width:35px;
 border:0;
 height:38px;
 background:0 0;
 padding:0;
 font-weight:600;
 font-size:16px;
 color:#222;
 padding-top: 15px;
 left:40px;
}
button__button--unbranded:hover:not([disabled]), .variations_form .shopify-payment-button__button--unbranded:hover {
  background: #cc9e6c;
  color: #fff;
}
button__button--unbranded:hover:not([disabled]), .variations_form .shopify-payment-button__button--unbranded {
  background: #585759;
  border: #585759;
  color: #fff;
}




.content-quickview .quantity input.input-text[type=number] {
 width:90px
}
.quantity input.input-text[type=number]:focus {
 border-color:#222
}
.quantity input::-webkit-inner-spin-button,
.quantity input::-webkit-outer-spin-button {
 -webkit-appearance:none
}
.quantity input[type=number]:focus {
 outline:0
}
.quantity .tc a,
.quantity .tc button {
 top:0;
 width:30px;
 height:40px;
 line-height:40px;
 border:0;
 background:0 0
}
.rtl_true .quantity .tc a.plus,
.rtl_true .quantity .tc button.plus {
 right:auto;
 left:0;
 padding-right:0;
 padding-left:15px
}
.rtl_true .quantity .tc a.minus,
.rtl_true .quantity .tc button.minus {
 left:auto;
 right:0;
 padding-left:0;
 padding-right:15px
}
.variations_button .nt_add_cp,
.variations_button .nt_add_w {
 position:relative;
 min-width:40px;
 width:40px;
 height:40px;
 line-height:40px;
 margin-left:10px;
 border-radius:50%;
 border:1px solid #222;
 text-align:center;
 transition:.3s;
 display:inline-block
}
.variations_button .nt_add_cp i {
 font-size:18px
}
.variations_button .nt_add_cp a,
.variations_button .nt_add_w a {
 display:block
}
.btn_des_1 .add_to_cart_button,
.btn_des_1 .out_stock,
.btn_des_1 .single_add_to_cart_button,
.btn_des_1 .variations_button .nt_add_cp,
.btn_des_1 .variations_button .nt_add_w,
.btn_des_1 .variations_form .quantity,
.btn_des_1 .variations_form .shopify-payment-button__button--unbranded {
 border-radius:40px!important
}
.btn_des_2 .add_to_cart_button,
.btn_des_2 .out_stock,
.btn_des_2 .single_add_to_cart_button,
.btn_des_2 .variations_button .nt_add_cp,
.btn_des_2 .variations_button .nt_add_w,
.btn_des_2 .variations_form .quantity,
.btn_des_2 .variations_form .shopify-payment-button__button--unbranded {
 border-radius:5px!important
}
.btn_des_3 .add_to_cart_button,
.btn_des_3 .out_stock,
.btn_des_3 .single_add_to_cart_button,
.btn_des_3 .variations_button .nt_add_cp,
.btn_des_3 .variations_button .nt_add_w,
.btn_des_3 .variations_form .quantity,
.btn_des_3 .variations_form .shopify-payment-button__button--unbranded {
 border-radius:0!important
}
.btn_txt_1 .single_add_to_cart_button,
.btn_txt_1 .variations_form .shopify-payment-button__button--unbranded {
 text-transform:lowercase
}
.btn_txt_2 .single_add_to_cart_button,
.btn_txt_2 .variations_form .shopify-payment-button__button--unbranded {
 text-transform:capitalize
}
.btn_txt_3 .single_add_to_cart_button,
.btn_txt_3 .variations_form .shopify-payment-button__button--unbranded {
 text-transform:uppercase
}
.single_add_to_cart_button {
 background-color:#cc9e6c;
 border:none;
 color:#fff;
 text-transform:uppercase;
 position:relative
}
.single_add_to_cart_button:hover {
 background:#585759;
 border-color:#585759
}
.variations_form .shopify-payment-button {
 margin-top:15px
}
.variations_form .shopify-payment-button__button--unbranded {
 border-radius:40px!important;
 background-color:#585759;
 border: #585759;
 text-transform:capitalize
}
.variations_form .shopify-payment-button__more-options {
 border:2px solid;
 text-transform:capitalize
}
.single_stt.out_stock,
.variations_form .shopify-payment-button__button--hidden {
 display:none
}
.product_meta {
 display:inline-block;
 width:100%
}
.product_meta>span {
 display:block;
 margin-bottom:5px
}
.product_meta>span a {
 display:inline-block
}
.sp-single .social-share {
 margin-top:20px
}
.sp-single #shopify-section-pr_description+.social-share {
 margin-top:40px
}
.sp-single .nt-social i {
 font-size:20px
}
.nt-social i {
 font-size:16px
}
.nt-social i:before {
 vertical-align:middle
}
.nt-social a {
 display:inline-block;
 margin:0 10px
}
.ntheader .cus_txt_h .nt-social a {
 margin:0 8px
}
.ntheader .cus_txt_h .nt-social i {
 font-size:15px
}
.nt-social a:hover {
 transform:translateY(-4px);
 color:#999!important
}
.nt-social svg:hover {
 fill:#999!important
}
.nt-social svg.at-icon-facebook:hover,
.nt-social svg.at-icon-messenger:hover {
 fill:#3b5998!important
}
.nt-social svg.at-icon-twitter:hover {
 fill:#00aced!important
}
.nt-social svg.at-icon-linkedin:hover {
 fill:#007bb6!important
}
.nt-social svg.at-icon-youtube:hover {
 fill:#b00!important
}
.nt-social svg.at-icon-instagram:hover {
 fill:#517fa4!important
}
.nt-social svg.at-icon-pinterest_share:hover {
 fill:#cb2027!important
}
.nt-social svg.at-icon-flickr:hover {
 fill:#ff0084!important
}
.nt-social svg.at-icon-tumblr:hover {
 fill:#32506d!important
}
.nt-social svg.at-icon-behance:hover {
 fill:#176aff!important
}
.nt-social svg.at-icon-soundcloud:hover {
 fill:#f70!important
}
.nt-social svg.at-icon-dribbble:hover {
 fill:#ea4c89!important
}
.nt-social svg.at-icon-skype:hover {
 fill:#0078ca!important
}
.nt-social svg.at-icon-telegram:hover {
 fill:#08c!important
}
.nt-social svg.at-icon-addthis:hover {
 fill:#ff6550!important
}
.nt-social svg.at-icon-mailto:hover {
 fill:#585858!important
}
.nt-social .facebook:hover {
 color:#3b5998!important
}
.nt-social .twitter:hover {
 color:#00aced!important
}
.nt-social .linkedin:hover {
 color:#007bb6!important
}
.nt-social .youtube:hover {
 color:#b00!important
}
.nt-social .instagram:hover {
 color:#517fa4!important
}
.nt-social .pinterest:hover {
 color:#cb2027!important
}
.nt-social .tumblr:hover {
 color:#32506d!important
}
.nt-social .behance:hover {
 color:#176aff!important
}
.nt-social .soundcloud:hover {
 color:#f70!important
}
.nt-social .dribbble:hover {
 color:#ea4c89!important
}
.nt-social .telegram:hover {
 color:#08c!important
}
.nt-social .email:hover {
 color:#585858!important
}
.nt-social .whatsapp:hover {
 color:#00e676!important
}
.social-share .at-resp-share-element.at-mobile .at-share-btn,
.social-share .at-style-responsive .at-share-btn {
 padding:0;
 margin:0 5px
}
.at-resp-share-element .at-share-btn .at-icon-wrapper,
.at-resp-share-element .at-share-btn .at-icon-wrapper svg,
.nt-social svg {
 fill:#000;
 width:26px!important;
 height:26px!important
}
.nt-social svg.fa-tiktok {
 width:12px!important;
 height:12px!important;
 display:inline-block;
 vertical-align:middle
}
#nt_footer .nt-social svg.fa-tiktok {
 width:15px!important;
 height:15px!important
}
.h__top .nt-social a:first-child {
 margin-left:0
}
.h__top .nt-social a:last-child {
 margin-right:0
}
.li_choose:not(.is-selected),
.nt_carousel_qv .flickity-button:disabled,
.p-thumb .flickity-button:disabled,
.variations .swatches-select>.nt_unavailable,
.variations.remove_soldout_true .swatches-select>.nt_soldout {
 display:none!important
}
.remove_soldout_false.variations .swatch:not(.is-color) .swatches-select>.nt_soldout>span {
 text-decoration:line-through;
 opacity:.5
}
.remove_soldout_false.variations .swatch:not(.is-color) .swatches-select>.nt_soldout.is-selected>span {
 opacity:1
}
.remove_soldout_false.variations .swatch.is-color:not(.color_simple) .swatches-select>.nt_soldout>span.swatch__value_pr:before {
 content:" ";
 position:absolute;
 width:100%;
 height:100%;
 top:0;
 left:0;
 bottom:0;
 right:0;
 display:block;
//  z-index:2;
 -webkit-border-radius:50%;
 -moz-border-radius:50%;
 border-radius:50%
}
.remove_soldout_false.variations.style_color .swatch.is-color .swatches-select>.nt_soldout>span.swatch__value_pr:before {
//  background-image:url(sold_out.png?v=1994);
 background-size:cover;
 background-repeat:no-repeat;
 background-position:0 0
}
.remove_soldout_false.variations .swatches-select>li.nt_soldout a,
.remove_soldout_false.variations.variant_simple .input-dropdown-inner .dropdown-list li.current-item.nt_soldout a,
.remove_soldout_false.variations.variant_simple .input-dropdown-inner .dropdown-list li.nt_soldout a {
 color:#d0c8c8!important
}
.variations.style__radio .swatch:not(.is-color) .swatch_pr_item {
 display:-ms-inline-flexbox;
 display:-webkit-inline-flex;
 display:inline-flex;
 text-transform:uppercase
}
.variations.style__radio.full .swatch:not(.is-color) li {
 display:-webkit-box;
 display:-ms-flexbox;
 display:flex;
 width:100%;
 border:1px solid #ddd
}
.variations.style__radio.full .swatch:not(.is-color) li:not(:last-child) {
 margin-bottom:6px
}
.variations.style__radio:not(.full) .swatch:not(.is-color) .swatch_pr_item .swatch__value_pr {
 padding-right:15px
}
.variations.style__radio.full .swatch:not(.is-color) .swatch_pr_item {
 padding:0 15px
}
span.radio_styled {
 width:16px;
 height:16px;
 border-radius:50%;
 border:1px solid #c4cdd5;
 -webkit-box-shadow:0 1px 0 0 rgba(22,29,37,.05);
 box-shadow:0 1px 0 0 rgba(22,29,37,.05)
}
span.radio_styled:after {
 content:"";
 display:block;
 height:10px;
 width:10px;
 position:absolute;
 top:50%;
 left:50%;
 border-radius:100%;
 background-color:transparent;
 -webkit-transform:translate(-50%,-50%) scale(0);
 transform:translate(-50%,-50%) scale(0);
 -webkit-transition:-webkit-transform .15s ease-in-out;
 transition:transform .15s ease-in-out;
 transition:transform .15s ease-in-out,-webkit-transform .15s ease-in-out
}
.variations.style__radio li.is-selected span.radio_styled,
.variations.style__radio li.is-selected-nt span.radio_styled,
.variations.style__radio li:hover span.radio_styled {
 border-color:#f76b6a
}
.variations.style__radio li.is-selected span.radio_styled:after,
.variations.style__radio li.is-selected-nt span.radio_styled:after {
 background-color:#cc9e6c;
 -webkit-transform:translate(-50%,-50%) scale(1);
 transform:translate(-50%,-50%) scale(1)
}
.variations.style__radio .swatch:not(.is-color) li.is-selected,
.variations.style__radio .swatch:not(.is-color) li.is-selected-nt,
.variations.style__radio .swatch:not(.is-color) li:hover {
 border-color:#f76b6a;
 background-color:#fff
}
.variations.style__rectangle .swatch:not(.is-color) li {
 font-weight:600;
 font-style:normal;
 letter-spacing:.1em;
 text-transform:uppercase;
 line-height:1;
 padding:5px;
 font-size:.76471em;
 background-color:#f5f5f5;
 border:1px solid #f5f5f5;
 color:#222;
 transition:.25s
}
.variations.style__rectangle .swatch:not(.is-color) li.is-selected,
.variations.style__rectangle .swatch:not(.is-color) li.is-selected-nt,
.variations.style__rectangle .swatch:not(.is-color) li:hover {
 border-color:rgba(33,43,54,.9);
 background-color:rgba(33,43,54,.9);
 color:#fff
}
.variations.style__rectangle .swatch.is-label .swatch_pr_item:not(.is-selected):not(.is-selected-nt):hover .swatch__value_pr {
 color:#fff
}
.dropdown_picker_js .swatch:not(.is-color) h4 .nt_name_current {
 display:none
}
.header_picker {
 padding:0 20px 0 10px;
 height:40px;
 line-height:40px;
 font-size:14px;
 color:#222;
 border:1px solid #e3e3e3;
 border-radius:3px;
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 -ms-user-select:none;
 user-select:none
}
.nt_lt_fake ul {
 opacity:0;
 pointer-events:none
}
svg.ic_triangle_svg {
 position:absolute;
 pointer-events:none;
 visibility:hidden;
 opacity:0
}
.p-thumb.isotope_ok .p-item a.is-selected {
 border:1px solid #ec0101
}
.p_group_btns {
 opacity:0;
 transition:.2s ease-in-out
}
.pr__view-in-space[data-shopify-xr-hidden] {
 visibility:hidden
}
.nt_countdow_page {
 min-width:100%
}
.nt_countdow_page .block {
 min-width:50px
}
.nt_countdow_page .flip-top {
 font-size:40px;
 color:#222;
 font-weight:500;
 display:inline-block;
 margin:5px 0
}
.cd_style_dark_2 .nt_countdow_page .flip-top {
 text-shadow:.1em .1em .2em rgba(0,0,0,.6)
}
.cd_style_dark_3 {
 border-radius:5px;
 background-color:#f5f5f5;
 padding:0 0 10px
}
.cd_style_light .nt_countdow_page .flip-top {
 color:#fff;
 text-shadow:.1em .1em .2em rgba(0,0,0,.59)
}
.nt_countdow_page .label {
 color:#222;
 font-size:11px
}
.nt_stock_page .progressbar {
 position:relative;
 height:12px;
 background-color:#e5e5e5;
 -webkit-box-shadow:inset 0 1px 2px rgba(0,0,0,.1);
 box-shadow:inset 0 1px 2px rgba(0,0,0,.1)
}
.nt_stock_page .progressbar>div {
 background-color:#d95350;
 height:12px;
 background-image:-webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
 background-image:linear-gradient(45deg,rgba(255,255,255,.15) 25%,rgba(0,0,0,0) 25%,rgba(0,0,0,0) 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,rgba(0,0,0,0) 75%,rgba(0,0,0,0));
 background-size:40px 40px;
 transition:width 1s;
 -webkit-transition:width 1s
}
.nt_stock_page .progress_bar>div {
 -webkit-animation:2s linear infinite progress_bar;
 animation:2s linear infinite progress_bar
}
@-webkit-keyframes progress_bar {
 from {
  background-position:0 0
 }
 to {
  background-position:40px 0
 }
}
@keyframes progress_bar {
 from {
  background-position:0 0
 }
 to {
  background-position:40px 0
 }
}
.fading_true {
 animation:1s infinite fading
}
@-webkit-keyframes fading {
 0%,
 100% {
  opacity:0
 }
 50% {
  opacity:1
 }
}
@keyframes fading {
 0%,
 100% {
  opacity:0
 }
 50% {
  opacity:1
 }
}
.des_style_2.sp-tab {
 display:block
}
.des_style_2.sp-tab:not(.clicked_accordion)>.sp-tab-content,
.des_style_2.ul_tabs {
 display:none
}
.des_style_2.sp-tab.active:not(.clicked_accordion)>.sp-tab-content,
.des_style_2.sp-tab>.heading {
 display:block
}
.des_style_1.sp-tab {
 padding:25px 0;
 -webkit-animation:1s ani-fadeIn;
 animation:1s ani-fadeIn
}
.ul_tabs li.tab_title_block a {
 font-size:14px;
 font-weight:600;
 margin:10px;
 line-height:1.2;
 border-bottom:2px solid transparent
}
.ul_tabs li a:hover,
.ul_tabs li.active a {
 color:#222
}
.ul_tabs li.active a,
.ul_tabs li.tab_title_block.active a {
 border-color:#222
}
.tab-heading .txt_h_tab {
 padding:10px
}
.des_style_2.sp-tab:not(:last-of-type) {
 margin-bottom:10px
}
.des_style_2.sp-tab>.sp-tab-content {
 padding:20px;
 border:1px solid #f5f5f5;
 border-top:0
}
.type_banner_collection .flickity-page-dots,
.type_slideshow .flickity-page-dots {
 position:absolute;
//  z-index:22;
 bottom:10px;
 display:block
}
.prev_next_1 .flickity-button:not(:hover) {
 background:#abb1b4;
 color:#ffff;
 border-color:#abb1b4
}
.prev_next_2 .flickity-button:not(:hover) {
 background:#fff;
 color:#222;
 border-color:#fff;
 box-shadow:0 0 3px 0 rgba(0,0,0,.2)
}
.prev_next_3 .flickity-button {
 background:#cc9e6c;
 color:#fff;
 border-color:#cc9e6c
}
.clno-touchevents .flickity-enabled.prev_next_3 .flickity-button:hover,
.prev_next_3 .flickity-button:hover {
 opacity:.7
}
.caption-wrap .caption.left {
 -ms-flex-pack:start!important;
 justify-content:flex-start!important
}
.caption-wrap .caption.center {
 -ms-flex-pack:center!important;
 justify-content:center!important
}
.caption-wrap .caption.right {
 -ms-flex-pack:end!important;
 justify-content:flex-end!important
}
.caption-wrap .caption.top {
 -ms-flex-align:start!important;
 align-items:flex-start!important
}
.caption-wrap .caption.bottom {
 -ms-flex-align:end!important;
 align-items:flex-end!important
}
.caption-wrap .caption.middle {
 -ms-flex-align:center!important;
 align-items:center!important
}
.inc_lz .container {
 padding-left:0;
 padding-right:0
}
.inc_lz .type_instagram_shop,
.inc_lz .type_lookbook_img,
.inc_lz .type_lookbook_slider {
 margin-right:-15px;
 margin-left:-15px
}
.section-title {
 margin:0
}
.title_11:after,
.title_11:before,
.title_12:after,
.title_12:before,
.title_2:after,
.title_2:before {
 content:"";
 display:inline-block;
 width:30px;
 height:2px;
 background:#222
}
.title_11>span,
.title_12>span {
 margin:0 .8em;
 -ms-flex:0 0 auto;
 flex:0 0 auto;
 width:auto;
 max-width:100%
}
.title_11:after,
.title_11:before,
.title_12:after,
.title_12:before {
 width:100%;
 height:1px;
 background:0 0;
 border-top:1px solid #ebebeb
}
.title_12:after,
.title_12:before {
 height:3px;
 border-bottom:1px solid #ebebeb
}
.title_3,
.title_4 {
 padding-bottom:10px;
 margin-bottom:10px
}
.title_3:after {
 content:" ";
 position:absolute;
 top:100%;
 left:50%;
 margin-left:-20px;
 width:40px;
 height:2px;
 background-color:#222
}
.title_4 {
 border-bottom:2px solid rgba(119,119,119,.17)
}
.title_4 span {
 position:relative
}
.title_4 span:after {
 content:" ";
 position:absolute;
 top:100%;
 left:0;
 margin-top:10px;
 width:100%;
 height:2px;
 background-color:#222
}
.title_5 {
 padding-bottom:5px;
 margin-bottom:40px
}
.title_5:after {
 content:" ";
 position:absolute;
 top:100%;
 left:50%;
 margin-left:-125px;
 width:250px;
 height:30px
}
.title_5.lazyloadt4sed:after {
//  background-image:url(svg_title.svg)
}
.title_6+.tt_divider {
 display:block;
 position:relative;
 padding:15px 10px
}
.title_6+.tt_divider:after,
.title_6+.tt_divider:before {
 content:" ";
 position:absolute;
 top:50%;
 bottom:-12px;
 left:50%;
 width:24px;
 height:1px;
 display:inline-block;
 background-color:#dcdcdc
}
.title_6+.tt_divider:before {
 margin-left:-34px
}
.title_6+.tt_divider:after {
 margin-left:10px
}
.title_6+.tt_divider span:after,
.title_6+.tt_divider span:before {
 content:" ";
 position:absolute;
 top:50%;
 bottom:-12px;
 left:50%;
 margin-left:-6px;
 width:12px;
 height:1px;
 display:inline-block;
 background-color:#c1c1c1
}
.title_6+.tt_divider span:before {
 -webkit-transform:rotate(45deg);
 -ms-transform:rotate(45deg);
 transform:rotate(45deg)
}
.title_6+.tt_divider span:after {
 -webkit-transform:rotate(-45deg);
 -ms-transform:rotate(-45deg);
 transform:rotate(-45deg)
}
i.dn.title_6,
i.dnt4.title_6 {
 display:none
}
.title_7+.tt_divider {
 display:-ms-flexbox;
 display:flex;
 -ms-flex-pack:center;
 justify-content:center;
 -ms-flex-align:center;
 align-items:center;
 margin:5px 0 8px
}
.title_7+.tt_divider>i {
 display:inline-block;
 margin:0 8px;
 font-size:22px
}
.title_7+.tt_divider>span {
 width:24px;
 height:1px;
 display:inline-block;
 background-color:#dcdcdc
}
.title_8 span,
.title_9 span {
 display:inline-block;
 position:relative;
//  z-index:9
}
.title_8 span:after {
 content:"";
 display:block;
 position:relative;
 width:60%;
 max-width:150px;
 height:2px;
 background-color:currentColor;
 margin:12px auto 15px
}
.title_9 span:after {
 content:"";
 height:8px;
 background:#56cfde;
 opacity:.7;
 position:absolute;
 bottom:4px;
 opacity:.3;
 left:0;
 width:100%;
//  z-index:-1
}
.title_10 a {
 border-bottom:2px solid #222
}
.des_title_13 {
 text-align:center;
 margin-bottom:15px
}
.des_title_13 .section-title {
 margin-bottom:17px
}
.des_title_13 .section-subtitle {
 margin-bottom:27px
}
.des_title_13::after {
 display:inline-block;
 vertical-align:top;
 content:'';
 width:72px;
 height:5px
}
.sub-title {
 font-family:Poppins;
 font-style:italic;
 font-size:14px;
 color:#878787;
 font-weight:400;
 margin-top:0
}
.tab_cat_title.des_tab_11>li,
.tab_cat_title.des_tab_1>li,
.tab_cat_title.des_tab_2>li,
.tab_cat_title.des_tab_3>li {
 margin:0 2px
}
.tab_cat_title.des_tab_11>li>a,
.tab_cat_title.des_tab_1>li>a,
.tab_cat_title.des_tab_2>li>a,
.tab_cat_title.des_tab_3>li>a {
 display:block;
 padding:8px 25px 7px;
 border:1px solid transparent;
 color:#878787
}
.tab_cat_title.des_tab_11>li>a,
.tab_cat_title.des_tab_2>li>a,
.tab_cat_title.des_tab_3>li>a {
 border-radius:40px
}
.tab_cat_title.des_tab_11>li>a,
.tab_cat_title.des_tab_3>li>a {
 border-style:dashed
}
.tab_cat_title.des_tab_11>li>a.tt_active,
.tab_cat_title.des_tab_11>li>a:hover,
.tab_cat_title.des_tab_1>li>a.tt_active,
.tab_cat_title.des_tab_1>li>a:hover,
.tab_cat_title.des_tab_2>li>a.tt_active,
.tab_cat_title.des_tab_2>li>a:hover,
.tab_cat_title.des_tab_3>li>a.tt_active,
.tab_cat_title.des_tab_3>li>a:hover {
 border-color:#222;
 color:#222
}
.tab_cat_title.des_tab_10>li>a,
.tab_cat_title.des_tab_4>li>a,
.tab_cat_title.des_tab_5>li>a,
.tab_cat_title.des_tab_6>li>a,
.tab_cat_title.des_tab_7>li>a,
.tab_cat_title.des_tab_8>li>a,
.tab_cat_title.des_tab_9>li>a {
 padding:2.5px 15px;
 font-weight:500;
 font-size:16px;
 opacity:.7;
 color:#222;
 transition:opacity .25s,color .25s
}
.tab_cat_title.des_tab_7>li>a {
 padding:2.5px 0
}
.tab_cat_title.des_tab_8>li>a,
.tab_cat_title.des_tab_9>li>a {
 padding:10px 15px
}
.tab_cat_title.des_tab_10>li>a>span,
.tab_cat_title.des_tab_6>li>a>span,
.tab_cat_title.des_tab_7>li>a>span {
 position:relative;
 display:inline-block;
 padding-top:1px;
 padding-bottom:1px
}
.tab_cat_title.des_tab_10>li>a>span:after,
.tab_cat_title.des_tab_6>li>a>span:after,
.tab_cat_title.des_tab_7>li>a>span:after {
 content:'';
 position:absolute;
 bottom:2px;
 left:0;
 width:0;
 height:2px;
 transition:width .4s cubic-bezier(.175,.885,.32,1.15)
}
.tab_cat_title.des_tab_10>li>a.tt_active>span:after,
.tab_cat_title.des_tab_10>li>a:hover>span:after {
 background-color:#222
}
.tab_cat_title.des_tab_7>li:not(:last-child) {
 display:-ms-inline-flexbox;
 display:inline-flex;
 -ms-flex-align:center;
 align-items:center
}
.tab_cat_title.des_tab_7>li:not(:last-child):after {
 content:"";
 height:14px;
 margin:0 15px;
 display:inline-block;
 vertical-align:bottom;
 width:1px;
 background-color:#adadad;
 -webkit-transform:rotate(25deg);
 -ms-transform:rotate(25deg);
 transform:rotate(25deg)
}
.tab_se_header.des_tab_8,
.tab_se_header.des_tab_9 {
 border-bottom:2px solid #eaeaea
}
.tab_name {
 font-weight:600;
 font-size:22px;
 color:#222;
 position:relative;
//  z-index:1;
 margin-bottom:-2px;
 margin-right:30px;
 padding-top:5px;
 padding-bottom:5px;
 border-bottom:2px solid;
 vertical-align:middle
}
.tab_se_header.des_tab_8 {
 -ms-flex-pack:justify;
 justify-content:space-between
}
.clno-touchevents .tab_cat_title>li>a:hover,
.tab_cat_title>li>a.tt_active {
 opacity:1
}
.clno-touchevents .tab_cat_title>li>a:hover>span:after,
.tab_cat_title>li>a.tt_active>span:after {
 width:100%
}
.tab_cat_title>li>a.tt_active {
 pointer-events:none
}
.tab_cat_title.des_tab_12 {
 margin-top:-7.5px
}
.tab_cat_title.des_tab_12>li {
 margin:7.5px
}
.tab_cat_title.des_tab_12>li>a {
 display:block;
 border:1px solid #ccc;
 color:#222;
 line-height:1;
 margin:0;
 padding:8px 25px;
 background-color:transparent
}
.tab_cat_title.des_tab_13>li {
 margin:0 5px
}
.tab_cat_title.des_tab_13>li>a {
 border:0;
 color:#aaa;
 line-height:1;
 margin:0;
 padding:10px 25px;
 border-radius:25px;
 display:block;
 letter-spacing:.1em;
 background-color:transparent
}
.tab_cat_title.des_tab_13>li>a:hover {
 color:#222
}
.tab_cat_title.des_tab_12>li>a.tt_active,
.tab_cat_title.des_tab_12>li>a:hover,
.tab_cat_title.des_tab_13>li>a.tt_active {
 background-color:#cc9e6c;
 border-color:#cc9e6c;
 color:#fff
}
.tab_se_content {
 position:relative
}
.tab_se_element {
 pointer-events:none;
 opacity:0;
 visibility:hidden;
 position:absolute;
//  z-index:1;
 top:0;
 left:0;
 right:0;
 -webkit-transform:translateY(40px);
 -ms-transform:translateY(40px);
 transform:translateY(40px);
 -webkit-transition:.2s ease-in-out;
 transition:.2s ease-in-out
}
.tab_se_element.ct_active {
 pointer-events:auto;
 opacity:1;
 visibility:visible;
 position:relative;
//  z-index:2;
 -webkit-transform:none;
 -ms-transform:none;
 transform:none;
 -webkit-transition:.2s ease-in-out .2s;
 transition:.2s ease-in-out .2s
}
.item__position {
 background-position:center center
}
.item__position.top {
 background-position:center top
}
.item__position.bottom {
 background-position:center bottom
}
.item__position.center.center {
 background-position:center center
}
.item__position.left.top {
 background-position:left top
}
.item__position.left.center {
 background-position:left center
}
.item__position.left.bottom {
 background-position:left bottom
}
.item__position.right.top {
 background-position:right top
}
.item__position.right.center {
 background-position:right center
}
.item__position.right.bottom {
 background-position:right bottom
}
.item__position.center.top {
 background-position:center top
}
.item__position.center.bottom {
 background-position:center bottom
}
.cat_design_1 .cat_grid_item__wrapper,
.cat_design_4 .cat_grid_item__wrapper,
.cat_design_6 .cat_grid_item__wrapper,
.cat_design_7 .cat_grid_item__wrapper {
 position:absolute;
 bottom:20px;
 left:50%;
 font-size:14px;
 font-weight:600;
 background:#fff;
 color:#222;
 padding:12px 20px;
 box-shadow:1px 1px 0 0 rgba(0,0,0,.1);
 min-width:150px;
 text-align:center;
 -webkit-transform:translateX(-50%);
 transform:translateX(-50%)
}
.cat_design_6 .cat_grid_item__wrapper,
.cat_design_7 .cat_grid_item__wrapper {
 background:#000;
 color:#fff;
 box-shadow:none
}
.cat_grid_item__wrapper .h3 {
 color:currentColor
}
.cat_design_2 .cat_grid_item__wrapper,
.cat_design_3 .cat_grid_item__wrapper {
 color:#fff;
 position:absolute;
 text-align:center;
 width:100%;
 top:50%;
 padding:0 5px;
 -ms-transform:translateY(-50%);
 -webkit-transform:translateY(-50%);
 transform:translateY(-50%);
 transition:.3s;
 text-shadow:0 0 4px rgba(0,0,0,.4);
 hyphens:auto;
 z-index:2;
 -webkit-transition:.3s;
 -moz-transition:.3s;
 -o-transition:.3s
}
.cat_design_2 .cat_grid_item__title,
.cat_design_3 .cat_grid_item__title {
 padding:0 15px;
 font-size:1.625em;
 font-style:normal;
 font-weight:600;
 line-height:1.2;
 overflow-wrap:break-word;
 word-wrap:break-word
}
.cat_design_2 .cat_grid_item__link::before,
.cat_design_3 .cat_grid_item__link::before {
 content:'';
 position:absolute;
 top:0;
 right:0;
 bottom:0;
 left:0;
 background-color:#000;
 opacity:.2;
 pointer-events:none;
 z-index:2;
 -webkit-transition:.6s ease-in-out;
 -moz-transition:.6s ease-in-out;
 -o-transition:.6s ease-in-out;
 transition:.6s ease-in-out
}
.cat_design_2 .cat_grid_item:hover .cat_grid_item__link::before,
.cat_design_3 .cat_grid_item:hover .cat_grid_item__link::before {
 opacity:.5
}
.cat_design_3 .cat_grid_item__count.dn,
.cat_design_3 .cat_grid_item__count.dnt4 {
 margin-top:5px;
 color:rgba(255,255,255,.9);
 line-height:20px;
 opacity:0;
 -webkit-transition:opacity .3s,-webkit-transform .3s;
 transition:transform .3s,opacity .3s,-webkit-transform .3s;
 -webkit-transform:translateY(15px) translateZ(0);
 transform:translateY(15px) translateZ(0);
 display:block
}
.cat_design_3 .cat_grid_item:hover .cat_grid_item__wrapper {
 -webkit-transform:translateY(-15px);
 -moz-transform:translateY(-15px);
 -o-transform:translateY(-15px);
 transform:translateY(-15px)
}
.cat_design_3 .cat_grid_item:hover .cat_grid_item__count {
 opacity:1;
 -webkit-transform:none;
 transform:none
}
.cat_design_4 .cat_grid_item:hover .cat_grid_item__title {
 opacity:0;
 font-size:0
}
.cat_design_4 .cat_grid_item:hover .cat_grid_item__count,
.cat_design_5 .cat_grid_item__count,
.cat_design_8 .cat_grid_item__count {
 display:block
}
.cat_design_5 .cat_grid_item__overlay,
.cat_design_8 .cat_grid_item__overlay {
 margin-bottom:70px
}
.cat_design_5 .cat_grid_item__wrapper,
.cat_design_8 .cat_grid_item__wrapper {
 pointer-events:auto;
 text-align:center;
 padding:10px
}
.cat_design_5 .cat_grid_item__wrapper,
.cat_design_8 .cat_grid_item__wrapper,
.tp_cat_list_packery .cat_design_5 .cat_grid_item__wrapper,
.tp_cat_list_packery .cat_design_8 .cat_grid_item__wrapper {
 background-color:#fff;
 position:absolute;
 right:0;
 left:0;
 bottom:25px;
//  z-index:10
}
.cat_design_8 .cat_grid_item__wrapper,
.tp_cat_list_packery .cat_design_8 .cat_grid_item__wrapper {
 background-color:#f8f8f8
}
.cat_design_5 .cat_grid_item__title,
.cat_design_8 .cat_grid_item__title {
 font-size:18px;
 font-weight:500;
 color:#222
}
.cat_design_8 .cat_grid_item__content {
 background:#f8f8f8;
 border-radius:5px
}
.cat_design_9 .cat_grid_item__content {
 text-align:center;
 padding:15px;
 box-shadow:inset -1px -1px #f1f1f1,-1px -1px #f1f1f1
}
.cat_design_9 .cat_grid_item__wrapper {
 margin-top:5px;
 margin-bottom:9px;
 pointer-events:auto
}
.cat_design_9 .cat_grid_item__title {
 font-weight:600;
 color:#333;
 font-size:15px
}
.cat_design_9 .cat_grid_item__content:hover .cat_grid_item__title {
 text-decoration:underline;
 color:#555
}
.cat_design_10 .cat_grid_item__count,
.cat_design_9 .cat_grid_item__count {
 display:block
}
.cat_design_10 .cat_grid_item__content {
 text-align:center
}
.cat_design_10 .cat_grid_item__link {
 border-radius:10px;
 overflow:hidden;
 -webkit-transition:350ms;
 -moz-transition:350ms;
 -o-transition:350ms;
 transition:350ms
}
.cat_design_10 .cat_grid_item__content:hover .cat_grid_item__link {
 -webkit-box-shadow:0 0 4px 0 rgb(1 1 1 / 10%);
 -moz-box-shadow:0 0 4px 0 rgba(1,1,1,.1);
 box-shadow:0 0 4px 0 rgb(1 1 1 / 10%)
}
.cat_design_10 .cat_grid_item__wrapper {
 margin-top:10px;
 margin-bottom:8.5px;
 pointer-events:auto
}
.cat_design_10 .cat_grid_item__title {
 font-weight:600;
 font-size:15px;
 color:#444
}
.cat_design_10 .cat_grid_item__content:hover .cat_grid_item__title {
 color:#333
}
.rtl_false .type_collection_list .container .flickity-prev-next-button.previous,
.rtl_true .type_collection_list .container .flickity-prev-next-button.next {
 left:-40px
}
.rtl_false .type_collection_list .container .flickity-prev-next-button.next,
.rtl_true .type_collection_list .container .flickity-prev-next-button.previous {
 right:-40px
}
.cat_space_item {
 padding-left:5px;
 padding-right:5px;
 margin-bottom:10px
}
.cat_space_0 {
 margin-left:0;
 margin-right:0
}
.cat_space_0 .cat_space_item {
 padding-left:0;
 padding-right:0;
 margin-bottom:0
}
.cat_space_2 {
 margin-left:-1px;
 margin-right:-1px
}
.cat_space_2 .cat_space_item {
 padding-left:1px;
 padding-right:1px;
 margin-bottom:2px
}
.cat_size_1 .cat_grid_item__overlay,
.cat_size_2 .cat_grid_item__overlay,
.cat_size_3 .cat_grid_item__overlay {
 padding-top:52.63157894736842%
}
.cat_size_3 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:110.52631578947368%
}
.cat_size_4 .cat_grid_item__overlay {
 padding-top:111.1111111111111%
}
.cat_size_4 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:110.52631578947368%
}
.cat_size_4 .cat_grid_item_4 .cat_grid_item__overlay {
 padding-top:52.63157894736842%
}
.cat_size_5 .cat_grid_item__overlay {
 padding-top:111.1111111111111%
}
.cat_size_5 .cat_grid_item_2 .cat_grid_item__overlay {
 padding-top:110.52631578947368%
}
.cat_size_6 .cat_grid_item__overlay {
 padding-top:111.1111111111111%
}
.cat_size_6 .cat_grid_item_3 .cat_grid_item__overlay,
.cat_size_6 .cat_grid_item_4 .cat_grid_item__overlay {
 padding-top:52.63157894736842%
}
.cat_size_7 .cat_grid_item__overlay,
.cat_size_8 .cat_grid_item__overlay {
 padding-top:100%
}
.cat_size_7 .cat_grid_item_3 .cat_grid_item__overlay {
 padding-top:211.08179419525067%
}
.cat_size_3.cat_space_20 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:108.7719298245614%
}
.cat_size_4.cat_space_20 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:107.7719298245614%
}
.cat_size_5.cat_space_20 .cat_grid_item_2 .cat_grid_item__overlay {
 padding-top:110.7719298245614%
}
.cat_size_6.cat_space_20 .cat_grid_item_3 .cat_grid_item__overlay,
.cat_size_6.cat_space_20 .cat_grid_item_4 .cat_grid_item__overlay {
 padding-top:53.63157894736842%
}
.cat_size_7.cat_space_20 .cat_grid_item_3 .cat_grid_item__overlay {
 padding-top:207.8047493403694%
}
.cat_size_3.cat_space_10 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:107.01754385964912%
}
.cat_size_4.cat_space_10 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:109%
}
.cat_size_5.cat_space_10 .cat_grid_item_2 .cat_grid_item__overlay {
 padding-top:111.01754385964912%
}
.cat_size_6.cat_space_10 .cat_grid_item_3 .cat_grid_item__overlay,
.cat_size_6.cat_space_10 .cat_grid_item_4 .cat_grid_item__overlay {
 padding-top:54.63157894736842%
}
.cat_size_7.cat_space_10 .cat_grid_item_3 .cat_grid_item__overlay {
 padding-top:203.44327176781002%
}
.cat_size_3.cat_space_6 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:106.3157894736842%
}
.cat_size_4.cat_space_6 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:108.6157894736842%
}
.cat_size_5.cat_space_6 .cat_grid_item_2 .cat_grid_item__overlay {
 padding-top:110.9957894736842%
}
.cat_size_6.cat_space_6 .cat_grid_item_3 .cat_grid_item__overlay,
.cat_size_6.cat_space_6 .cat_grid_item_4 .cat_grid_item__overlay {
 padding-top:55.12157894736842%
}
.cat_size_7.cat_space_6 .cat_grid_item_3 .cat_grid_item__overlay {
 padding-top:202.19868073878628%
}
.cat_size_3.cat_space_2 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:105.6140350877193%
}
.cat_size_4.cat_space_2 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:108.4140350877193%
}
.cat_size_5.cat_space_2 .cat_grid_item_2 .cat_grid_item__overlay {
 padding-top:111.1111111111111%
}
.cat_size_6.cat_space_2 .cat_grid_item_3 .cat_grid_item__overlay,
.cat_size_6.cat_space_2 .cat_grid_item_4 .cat_grid_item__overlay {
 padding-top:55.42157894736842%
}
.cat_size_7.cat_space_2 .cat_grid_item_3 .cat_grid_item__overlay {
 padding-top:200.55408970976254%
}
.cat_size_3.cat_space_0 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:105.26315789473684%
}
.cat_size_4.cat_space_0 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:108.16315789473684%
}
.cat_size_5.cat_space_0 .cat_grid_item_2 .cat_grid_item__overlay {
 padding-top:111.1111111111111%
}
.cat_size_6.cat_space_0 .cat_grid_item_3 .cat_grid_item__overlay,
.cat_size_6.cat_space_0 .cat_grid_item_4 .cat_grid_item__overlay {
 padding-top:57.63157894736842%
}
.cat_size_7.cat_space_0 .cat_grid_item_3 .cat_grid_item__overlay {
 padding-top:200%
}
.cat_size_4.cat_lay4_1 .cat_grid_item_4 .cat_grid_item__overlay {
 padding-top:233.333333%
}
.cat_size_4.cat_lay4_2 .cat_grid_item__overlay {
 padding-top:71.9298247%
}
.cat_size_4.cat_lay4_2 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:88.5964913%
}
.cat_size_4.cat_lay4_2 .cat_grid_item_3 .cat_grid_item__overlay {
 padding-top:55.2631579%
}
.cat_size_4.cat_lay4_3 .cat_grid_item__overlay,
.cat_size_5.cat_lay5_2 .cat_grid_item__overlay,
.cat_size_5.cat_lay5_3 .cat_grid_item_1 .cat_grid_item__overlay {
 padding-top:100%
}
.cat_size_4.cat_lay4_3 .cat_grid_item_2 .cat_grid_item__overlay,
.cat_size_4.cat_lay4_3 .cat_grid_item_3 .cat_grid_item__overlay,
.cat_size_5.cat_lay5_2 .cat_grid_item_2 .cat_grid_item__overlay {
 padding-top:110.52631578947368%
}
.cat_size_5.cat_lay5_2 .cat_grid_item_2 .cat_grid_item__overlay {
 padding-top:52.63157894736842%
}
.cat_size_5.cat_lay5_2 .cat_grid_item_4 .cat_grid_item__overlay,
.cat_size_5.cat_lay5_2 .cat_grid_item_5 .cat_grid_item__overlay {
 padding-top:111.1111111111111%
}
.cat_size_5.cat_lay5_3 .cat_grid_item__overlay {
 padding-top:52.6316%
}
.cat_size_5.cat_lay5_3 .cat_grid_item_2 .cat_grid_item__overlay,
.cat_size_5.cat_lay5_3 .cat_grid_item_5 .cat_grid_item__overlay {
 padding-top:47.3684211%
}
.pos_text_alt .category-content {
 margin:-36px 15px 0;
 padding:15px 20px 20px;
 background-color:#fff;
 position:relative
}
.type_feature_columns .h4,
.type_feature_columns .h4>a {
 line-height:1.1
}
.pos_text_alt .placeholder-svg,
.pos_text_alt img {
 margin-bottom:0!important
}
.form-notify {
 border-top:1px solid rgba(129,129,129,.2)
}
.nt_bg_overlay:after,
.nt_img_txt>a:after,
.nt_promotion>a:after {
 content:'';
 position:absolute;
 top:0;
 left:0;
 width:100%;
 height:100%;
 pointer-events:none
}
.nt_img_txt .pa.txt_content,
.nt_promotion .pa {
//  z-index:10;
 padding:10px
}
.banner_design_1 h4,
.banner_design_2 h3 {
 line-height:24px
}
.txt_shadow_true .shadow_wrap>* {
 text-shadow:0 0 4px rgba(0,0,0,.4)
}
.se_height_full .js_full_ht4,
.se_height_full .nt_bg_lz {
 min-height:100vh
}
.type_slideshow .se_height_full .nt_bg_lz {
 min-height:auto
}
.type_slideshow .nt_bg_lz.pa {
 position:absolute
}
.hero_video_se iframe {
 height:100%;
 position:absolute;
 top:0;
 right:0;
 bottom:0;
 width:300%;
 left:-100%;
 max-width:none;
 pointer-events:none
}
.hero_video_se.video_interactable iframe {
 pointer-events:auto
}
.type_banner_collection .flickity-page-dots .dot,
.type_slideshow .flickity-page-dots .dot {
 margin-top:2.5px;
 width:11px;
 height:11px
}
.nt_img_txt video.vid_nt {
 padding:0;
 object-fit:cover;
 object-position:50% 50%;
 width:100%;
 height:100%
}
.img_vid_js {
 background-repeat:no-repeat;
 background-position:center;
 background-size:cover;
//  z-index:5
}
.img_vid_js.nt_bg_lz {
 padding-top:0!important;
 position:absolute!important
}
.nt_img_txt .pa.btn_video_ef {
 padding:0
}
.type_slideshow .has_video .caption {
//  z-index:40
}
.nt_img_txt .pa.btn_video_ef,
.type_slideshow .has_video .nt_img_txt>a:after {
//  z-index:45
}
video.vid_ready {
 opacity:1
}
.nt_img_txt video+.btn_video_ef {
 background-color:#f9f9f9
}
.nt_img_txt video.vid_ready+.btn_video_ef {
 opacity:0
}
.type_slideshow .has_video .nt_bg_lz {
 padding-top:56.25%
}
.dek_img_slide,
.dek_img_slide.nt_bg_lz {
 display:none
}
.slideshow__slide .caption-wrap {
 position:absolute;
 top:0;
 right:0;
 left:0;
 bottom:0;
 width:100%;
 height:100%
}
.caption-w-1 {
 max-width:1200px;
 margin:0 auto
}
.caption-w-2 {
 max-width:100%
}
.nt_promotion_html.pa,
.type_banner_collection .caption,
.type_hero_video .pa_txts,
.type_image_text_overlay .pa_txts,
.type_slideshow .caption {
 position:absolute;
 padding:15px;
 max-width:100%
}
a.button.btn_style_2:focus,
a.button.btn_style_2:hover {
 opacity:.8
}
.img_zoom {
 -webkit-transform:scale(1.2);
 -moz-transform:scale(1.2);
 transform:scale(1.2);
 -webkit-transition:4s;
 -moz-transition:4s;
 transition:4s
}
.is-selected .img_zoom.lazyloadt4sed {
 -webkit-transform:scale(1);
 -moz-transform:scale(1);
 transform:scale(1)
}
.img_tran_ef {
 transform:translateX(200px);
 opacity:0;
 transition:none
}
.is-selected .img_tran_ef.lazyloadt4sed {
 opacity:1;
 transform:translateX(0);
 transition:.35s
}
.bottom_top,
.left_right,
.right_left,
.top_bottom {
 transform:translateY(40px);
 -webkit-transform:translateY(40px);
 -moz-transform:translateY(40px);
 transition:.4s cubic-bezier(.44,.13,.48,.87) .3s;
 -webkit-transition:.4s cubic-bezier(.44,.13,.48,.87) .3s;
 opacity:0
}
.top_bottom {
 transform:translateY(-40px);
 -webkit-transform:translateY(-40px);
 -moz-transform:translateY(-40px)
}
.left_right {
 transform:translateX(-40px);
 -webkit-transform:translateX(-40px);
 -moz-transform:translateX(-40px)
}
.right_left {
 transform:translateX(40px);
 -webkit-transform:translateX(40px);
 -moz-transform:translateX(40px)
}
.is-selected .bottom_top,
.is-selected .left_right,
.is-selected .right_left,
.is-selected .top_bottom {
 transform:translateY(0);
 -webkit-transform:translateY(0);
 -moz-transform:translateY(0);
 opacity:1
}
.hero_video_se.video_loaded .video-control__play,
.video_loading .txt_content,
.video_loading iframe {
 opacity:0
}
.video_loading .nt_img_txt>a:after {
 background-color:#f5f5f5!important;
 opacity:1!important
}
.video-control__play {
 visibility:visible;
 opacity:1;
 width:50px;
 height:50px;
 border-radius:25px;
 position:relative;
 margin:0 auto;
 padding:5px;
 pointer-events:none;
//  z-index:4;
 transition:.1s ease-out;
 background-color:#cc9e6c;
 border-color:#cc9e6c;
 color:#fff
}
.video-control__play .icon {
 display:inline-block;
 width:20px;
 height:20px;
 vertical-align:middle;
 fill:currentColor
}
.hero_video_se.video_interactable .txt_content {
 pointer-events:none
}
.video-control__play::before {
 content:'';
 display:block;
 width:2.875rem;
 height:2.875rem;
 position:absolute;
 margin-left:-1.4375rem;
 border-radius:50%;
 border:2px solid #fff;
 border-top-color:transparent;
 -moz-animation:.35s linear infinite spin;
 -o-animation:.35s linear infinite spin;
 -webkit-animation:.35s linear infinite spin;
 animation:.35s linear infinite spin;
 transition:.1s ease-out .5s;
//  z-index:5;
 top:1px;
 left:50%
}
.type_iframe_video .nt_bg_lz {
 background-color:#f5f5f5
}
.type_iframe_video iframe.lazyloadt4sed {
//  z-index:22
}
@-webkit-keyframes spin {
 0% {
  -ms-transform:rotate(0);
  -webkit-transform:rotate(0);
  transform:rotate(0)
 }
 100% {
  -ms-transform:rotate(360deg);
  -webkit-transform:rotate(360deg);
  transform:rotate(360deg)
 }
}
@keyframes spin {
 0% {
  -ms-transform:rotate(0);
  -webkit-transform:rotate(0);
  transform:rotate(0)
 }
 100% {
  -ms-transform:rotate(360deg);
  -webkit-transform:rotate(360deg);
  transform:rotate(360deg)
 }
}
.nt_contain .post_nt_loop>a.bgb {
 background-color:transparent
}
.more-link {
 margin:20px auto;
 line-height:40px;
 border:2px solid #222;
 padding:0 30px;
 font-weight:600;
 display:table;
 border-radius:40px
}
.more-link:hover {
 background-color:#222;
 color:#fff
}
.nt_shipping .icon.small {
 font-size:24px
}
.nt_shipping .icon.medium {
 font-size:36px
}
.nt_shipping .icon.large {
 font-size:48px
}
.nt_shipping .icon {
 line-height:100%
}
.nt_shipping.tc .icon {
 margin:0 auto 10px;
 display:inline-block
}
.nt_shipping.jas-icon-square .small,
.nt_shipping.nt_icon_circle .small {
 width:60px;
 height:60px;
 line-height:60px
}
.nt_shipping.jas-icon-square .medium,
.nt_shipping.nt_icon_circle .medium {
 width:80px;
 height:80px;
 line-height:80px
}
.nt_shipping.jas-icon-square .large,
.nt_shipping.nt_icon_circle .large {
 width:95px;
 height:95px;
 line-height:95px
}
.nt_shipping .title {
 font-size:14px
}
.nt_shipping.tl .content,
.nt_shipping.tr .content {
 overflow:hidden
}
.nt_shipping.tl .icon,
.nt_shipping.tr .icon {
 text-align:center
}
.nt_shipping.tr .icon {
 margin-left:20px;
 -ms-order:2;
 order:2
}
.nt_shipping.tl .icon {
 margin-right:20px
}
.nt_shipping.tc {
 -ms-flex-direction:column;
 flex-direction:column
}
.nt_shipping.nt_icon_circle .icon {
 border:1px solid;
 position:relative
}
.nt_shipping.nt_icon_circle:hover i {
 color:#fff;
 position:relative;
//  z-index:1
}
.nt_shipping.nt_icon_circle.tc .icon {
 margin-bottom:30px
}
.nt_shipping.nt_icon_circle .icon,
.nt_shipping.nt_icon_circle .icon:before {
 border-radius:50%
}
.nt_shipping .ship_img {
 background-size:contain;
 background-repeat:no-repeat;
 width:50px;
 height:50px
}
.nt_shipping .ship_img.small {
 width:40px;
 height:40px
}
.nt_shipping .ship_img.large {
 width:60px;
 height:60px
}
.use_border_true .nt_shipping {
 padding:15px
}
.use_border_true .nt_shipping:after {
 display:block!important;
 content:'';
 position:absolute;
 top:0;
 bottom:0;
 left:7.5px;
 right:7.5px;
 border:1px solid #ddd
}
.use_border_true .nt_shipping .ship_img {
 background-position:center
}
.quotes_des_2 .quote_slide,
.quotes_des_3 .quote_slide {
 padding:30px;
 background:#fff;
 box-shadow:0 15px 34px rgba(0,0,0,.2);
 border-radius:10px
}
.quotes_des_5 .quote_slide,
.quotes_des_6 .quote_slide {
 border:1px solid #eee;
 padding:30px;
 background:#fff
}
.quotes_des_3 .quote_avatar {
 width:50px;
 height:50px;
 min-width:50px;
 min-height:50px;
 max-width:50px;
 max-height:50px
}
.quote_avatar {
 width:100px;
 height:100px
}
.quote_rating {
 min-height:22px
}
.quote_rating>i {
 margin:0 .5px
}
.quote_rating>.fa47-star-half {
//  z-index:44;
 margin-right:-7px
}
.quotes_des_4 {
 border-color:#eee;
 border-left-style:solid;
 border-top-style:solid;
 border-width:1px
}
.quotes_des_4 .quote_col {
 border-color:#eee;
 padding:41px 20px 49px;
 border-right-style:solid;
 border-bottom-style:solid;
 border-width:1px
}
.ovic-person.style-05 .inner {
 padding-left:20px;
 padding-right:20px
}
.quotes_des_4 .quote_texts p {
 margin-bottom:30px
}
.quotes_des_4 .quote_author {
 font-size:13px;
 line-height:20px;
 letter-spacing:.17em;
 margin-bottom:0;
 font-weight:500
}
.quotes_des_4 .quote_position {
 font-size:13px;
 line-height:20px;
 margin-bottom:0
}
.quotes_des_4 .quote_avatar {
 width:80px;
 height:80px
}
.signup-newsletter-form {
 border-radius:50px;
 border:1px solid #878787;
 padding:2px
}
.signup-newsletter-form .submit-btn {
 text-transform:none;
 border-radius:50px;
 font-size:14px;
 padding:0 20px;
 margin:0;
 border:none;
 background:#222;
 color:#fff
}
.signup-newsletter-form input.input-text {
 width:100%;
 background-color:transparent;
 border-radius:50px;
 padding:0 10px;
 border:0
}
.newl_des_2 .signup-newsletter-form,
.newl_des_2 .signup-newsletter-form .submit-btn,
.newl_des_2 .signup-newsletter-form input.input-text,
.newl_des_3 .signup-newsletter-form,
.newl_des_3 .signup-newsletter-form .submit-btn,
.newl_des_3 .signup-newsletter-form input.input-text,
.newl_des_4 .signup-newsletter-form,
.newl_des_4 .signup-newsletter-form .submit-btn,
.newl_des_4 .signup-newsletter-form input.input-text {
 border-radius:0
}
.newl_des_3 .signup-newsletter-form,
.newl_des_4 .signup-newsletter-form {
 padding:0;
 border:0
}
.newl_des_3 .signup-newsletter-form input.input-text {
 border:1px solid #878787
}
.newsletter_se.newl_des_3 .signup-newsletter-form .col_email {
 margin-bottom:10px
}
.newsletter_se .signup-newsletter-form .submit-btn {
 font-size:16px;
 background-color:#000
}
.newl_des_4 .signup-newsletter-form .submit-btn,
.newl_des_4 .signup-newsletter-form input.input-text {
 height:60px!important;
 font-size:16px
}
.newl_des_4 .signup-newsletter-form .submit-btn {
 min-width:175px!important;
 font-size:14px;
 line-height:24px;
 background-color:#293341;
 font-weight:500;
 text-transform:uppercase;
 letter-spacing:.17em
}
.newl_des_4 .signup-newsletter-form input.input-text {
 border:1px solid #eee;
 padding:17px 20px
}
.newl_des_6 .signup-newsletter-form {
 padding:0;
 border:0;
 border-radius:0;
 border-bottom:1px solid #fff
}
.newsletter_se.newl_des_6 .signup-newsletter-form input.input-text {
 border-radius:0
}
.newsletter_se.newl_des_6 .signup-newsletter-form .submit-btn {
 border-radius:0;
 padding:0 20px;
 min-width:auto;
 font-weight:500
}
.newl_des_6 .section-title {
 font-weight:500
}
.color_scheme_light,
.color_scheme_light .cg,
.color_scheme_light .section-title {
 color:rgba(255,255,255,.8)
}
.newsletter_se.color_scheme_light,
.newsletter_se.color_scheme_light .cg,
.newsletter_se.color_scheme_light .section-title {
 color:#fff
}
.newsletter_se.color_scheme_light input {
 background-color:transparent
}
.color_scheme_light h2 {
 color:#fff
}
.color_scheme_light .signup-newsletter-form {
 border-color:rgba(255,255,255,.7)
}
.color_scheme_light input:not([type=submit]):not([type=checkbox]),
.color_scheme_light select,
.color_scheme_light textarea {
 color:rgba(255,255,255,.7)
}
.color_scheme_light a,
.color_scheme_light a:hover {
 color:#fff
}
.newsletter_se .mc4wp-response>div {
 max-width:600px;
 margin:20px auto 0;
 background-color:rgba(255,255,255,.8)
}
.nt_full .flickity-prev-next-button.previous {
 left:15px
}
.nt_full .flickity-prev-next-button.next {
 right:15px
}
.row.ins_spaces_0 {
 margin:0
}
.row.ins_spaces_2 {
 margin:-1px
}
.row.ins_spaces_3 {
 margin:-1.5px
}
.row.ins_spaces_6 {
 margin:-3px
}
.row.ins_spaces_10 {
 margin:-5px
}
.row.ins_spaces_15 {
 margin:-7.5px
}
.row.ins_spaces_0 .col_ins {
 padding:0
}
.row.ins_spaces_2 .col_ins {
 padding:1px
}
.row.ins_spaces_3 .col_ins {
 padding:1.5px
}
.row.ins_spaces_6 .col_ins {
 padding:3px
}
.row.ins_spaces_10 .col_ins {
 padding:5px
}
.row.ins_spaces_15 .col_ins {
 padding:7.5px
}
.row.ins_spaces_30 .col_ins {
 padding:15px
}
.row.ins_rounded_1 .wrap_ins_img {
 border-radius:50%
}
.row.ins_rounded_2 .wrap_ins_img {
 border-radius:5px
}
.widget ul li {
 line-height:25px;
 list-style:none;
 margin-bottom:5px
}
.footer__top_wrap {
 background:#f6f6f8;
 color:#878787
}
.footer__top i {
 font-size:24px;
 margin-right:5px;
 vertical-align:middle
}
.footer__top .label_check {
 font-size:13px
}
.footer__top .nt-social i {
 font-size:16px;
 font-weight:500
}
.footer__top .widget-title {
 color:#222
}
.footer__top .menu li {
 list-style-type:none;
 line-height:30px;
 margin-right:0
}
.footer__bot a,
.footer__top a {
 color:#878787
}
.footer__top p {
 margin-bottom:1.3em;
 line-height:24px
}
.footer__bot_wrap {
 line-height:1.5;
 background:#fff
}
#footer-menu li {
 list-style:none;
 margin-left:20px;
 display:inline-block
}
.footer__bot .col_2,
.footer__bot .col_3 {
 margin-top:15px
}
.footer__bot .nt_currency ul {
 bottom:100%
}
.tag-comment {
 border:1px solid #f1f1f1;
 padding:15px 20px
}
em {
 font-family:Poppins

}
p {
  font-family: Poppins;
}
.nt_single_blog .nt-social a {
 margin:0 10px
}
.widget .post_list_widget .article-title,
.widget .product_list_widget .product-title {
 color:#222;
 font-weight:500;
 line-height:24px
}
.widget .product_list_widget ins {
 color:#ec0101;
 text-decoration:none
}
.widget_img_ar,
.widget_img_pr {
 min-width:95px;
 max-width:95px;
 padding-right:0!important
}
.widget_if_ar,
.widget_if_pr {
 padding-left:10px!important;
 color:#878787
}
.ntcols_js {
 width:100%
}
[data-columns="6"] .ntcols_js {
 -ms-flex:0 0 16.666667%;
 flex:0 0 16.666667%;
 max-width:16.666667%
}
[data-columns="5"] .ntcols_js {
 -ms-flex:0 0 20%;
 flex:0 0 20%;
 max-width:20%
}
[data-columns="4"] .ntcols_js {
 -ms-flex:0 0 25%;
 flex:0 0 25%;
 max-width:25%
}
[data-columns="3"] .ntcols_js {
 -ms-flex:0 0 33.333333%;
 flex:0 0 33.333333%;
 max-width:33.333333%
}
[data-columns="2"] .ntcols_js {
 -ms-flex:0 0 50%;
 flex:0 0 50%;
 max-width:50%
}
[data-columns="1"] .ntcols_js {
 -ms-flex:0 0 100%;
 flex:0 0 100%;
 max-width:100%;
 width:100%
}
.ntcols_js .post_nt_loop,
.ntcols_js .pr_grid_item,
.ntcols_js .ptf_nt_loop {
 -ms-flex:0 0 100%;
 flex:0 0 100%;
 max-width:100%
}
.filter_area .section_nt_filter {
 display:none
}
.about_us_img.border_true {
 padding:10px;
 border:1px solid #f9f9f9;
 margin-bottom:25px
}
.about_us_img.border_true>span:after,
.about_us_img.border_true>span:before {
 position:absolute;
 background-color:#cc9e6c;
 content:'';
 display:inline-block;
//  z-index:2
}
.about_us_img .video-control__play {
 pointer-events:auto;
//  z-index:4;
 color:#fff
}
.about_us_img.border_true>span.fr_br_1:before {
 left:0;
 top:0;
 width:2px;
 height:5%
}
.about_us_img.border_true>span.fr_br_1:after {
 left:0;
 top:0;
 height:2px;
 width:5%
}
.about_us_img.border_true>span.fr_br_2:before {
 right:0;
 top:0;
 width:2px;
 height:5%
}
.about_us_img.border_true>span.fr_br_2:after {
 right:0;
 top:0;
 height:2px;
 width:5%
}
.about_us_img.border_true>span.fr_br_3:before {
 left:0;
 bottom:0;
 width:2px;
 height:5%
}
.about_us_img.border_true>span.fr_br_3:after {
 left:0;
 bottom:0;
 height:2px;
 width:5%
}
.about_us_img.border_true>span.fr_br_4:before {
 right:0;
 bottom:0;
 width:2px;
 height:5%
}
.about_us_img.border_true>span.fr_br_4:after {
 right:0;
 bottom:0;
 height:2px;
 width:5%
}
.pin__image {
 background-size:cover;
 background-position:center;
 background-repeat:no-repeat
}
.pin__title {
 -webkit-transform:translateX(-50%) scale(0);
 transform:translateX(-50%) scale(0)
}
.pin__type .dn_md .nt_add_qv {
 display:none
}
.login-form h2 {
 font-size:16px;
 text-transform:uppercase;
 font-weight:700;
 margin:0 0 20px
}
form .form-row label {
 line-height:2;
 display:block;
 margin-bottom:5px
}
.login-form form .form-row label {
 margin-bottom:10px
}
form .form-row label .required {
 color:#fc655e
}
.form-message--error {
 color:#651818;
 border:1px solid #d20000;
 background-color:#fff8f8;
 padding:1rem 1.3rem;
 text-align:left;
 width:100%;
 margin:0 0 27.5px
}
.form-message__title {
 font-size:14px;
 color:#651818;
 margin-bottom:10px
}
.form-message--error a:not(:hover) {
 color:#651818
}
input.input--error {
 border-color:#d20000!important;
 margin-bottom:5px
}
.frm_guest_login {
 border-top:1px solid #e5e5e5
}
.sp-myaccount-nav ul {
 list-style:none;
 line-height:1.4;
 border:1px solid #e1e3e4
}
.sp-myaccount-nav a {
 display:block;
 padding:10px 15px;
 font-size:14px;
 border-bottom:1px solid #e1e3e4
}
.sp-myaccount-nav .is-active a {
 background-color:rgba(136,136,136,.12);
 cursor:default
}
.sp-myaccount-nav a:focus,
.sp-myaccount-nav a:hover {
 background-color:#f8f8f8
}
.button.button_primary {
 background-color:#cc9e6c;
 color:#fff;
 border-color:#cc9e6c
}
.button.button_primary:focus,
.button.button_primary:hover {
 opacity:.8
}
.login-form select {
 border-radius:0
}
hr {
 margin:55px 0;
 border:0;
 border-bottom:1px solid #e8e9eb
}
.orders-table .btn--secondary {
 background-color:transparent;
 color:#cc9e6c;
 border:1px solid #cc9e6c;
 padding:8px 10px;
 font-size:.75em;
 line-height:1;
 display:inline-block;
 text-align:center;
 min-width:55px
}
.table-responsive {
 min-height:.01%;
 overflow-x:auto
}
.kalles_toolbar {
 background-color:#fff;
 z-index:350;
 overflow-x:auto;
 overflow-y:hidden;
 -webkit-overflow-scrolling:touch;
 padding:5px;
 height:55px;
 box-shadow:0 0 9px rgba(0,0,0,.12);
 transform:translate3d(0,104%,0);
 -webkit-transform:translate3d(0,104%,0);
 transition:transform .25s
}
.sidebar.sidebar_nt.hidden_true {
 -ms-flex-order:0;
 order:0
}
input[type=checkbox] {
 float:none;
 vertical-align:middle
}
.cat_emty_noprs {
 margin:140px 0
}
#nt_content .shopify-challenge__container,
.empty_cart_page {
 margin:140px 0;
 text-align:center
}
.empty_cart_page .button,
.nt_mini_cart .empty .button {
 min-width:180px
}
.img_action_zoom.is_sticky .theiaStickySidebar {
 overflow:hidden
}
.is_sticky {
 will-change:min-height,height
}
.is_sticky .theiaStickySidebar {
 transform:translate(0,0);
 transform:translate3d(0,0,0);
 will-change:position,transform
}
#nt_content .shopify-challenge__container {
 margin-left:auto!important;
 margin-right:auto!important
}
.lz_op_ef:not(.lazyloadt4sed) {
 background:#e1e3e4
}
.lz_op_ef:not(.lazyloadt4sed),
.skeleton_wrap {
 -webkit-animation:.45s linear infinite alternate skeletonAnimation;
 animation:.45s linear infinite alternate skeletonAnimation;
 will-change:opacity
}
@-webkit-keyframes skeletonAnimation {
 0% {
  opacity:.45
 }
 100% {
  opacity:.9
 }
}
@keyframes skeletonAnimation {
 0% {
  opacity:.45
 }
 100% {
  opacity:.9
 }
}
.progress_bar {
 display:block;
 margin:15px auto 20px;
 width:250px;
 height:4px;
 background-color:#ddd;
 border-radius:5px
}
.progress_bar .current_bar {
 width:0;
 will-change:width;
 background-color:#00badb;
 -webkit-transition:width .3s cubic-bezier(.19,1,.22,1);
 transition:width .3s cubic-bezier(.19,1,.22,1)
}
.template-search .empty_cart_page>i:after {
 display:none
}
.template-search .empty_cart_page>.iccl-search:after {
 font-size:20px;
 left:28px;
 margin-top:-18px;
 content:"\f128";
 font-family:"Font Awesome 5 Pro";
 font-weight:900
}
.results_article_blog h4,
.results_prs_tt {
 background-color:#f9f9f9;
 text-transform:uppercase;
 font-weight:600;
 font-size:22px;
 padding:15px;
 margin:0;
 border:1px solid rgba(129,129,129,.2)
}
.results_prs_tt {
 margin-top:30px
}
.results_article_blog a {
 display:block;
 padding:8px 20px;
 transition:color .15s ease-in-out,background .15s ease-in-out;
 border:1px solid rgba(129,129,129,.2);
 border-top:0
}
.results_article_blog a:hover {
 background-color:#f9f9f9
}
.container_cat .results_article_blog ul {
 margin-bottom:40px
}
.featured_product_se .product-images .nt_labels,
.sp-single .product-images .nt_labels {
 right:30px
}
.button.round_false {
 border-radius:0
}
.type_prs_countd_banner .desgin__2 .product-image {
 border-radius:0;
 -webkit-box-shadow:none;
 box-shadow:none
}
.type_prs_countd_banner .pr_grid_item {
 padding-right:0;
 padding-left:0
}
.type_prs_countd_banner .nt_promotion .pa {
 padding:20px
}
.type_prs_countd_banner .nt_promotion h5 {
 font-weight:400
}
.type_prs_countd_banner .button {
 font-size:14px;
 line-height:36px;
 padding:0 34px
}
.wrap_txt_ship {
 padding:17px 5px;
 font-size:15px
}
.type_banner_collection a.button.slt4_btn,
.type_css_btna a.button.slt4_btn,
.type_custom_section a.imtt4_btn,
.type_custom_section2 a.slt4_btn,
.type_hero_video a.button,
.type_image_text_overlay a.button,
.type_slideshow a.button.slt4_btn {
 min-width:150px;
 display:-ms-inline-flexbox;
 display:inline-flex;
 -ms-flex-align:center;
 align-items:center;
 -ms-flex-pack:center;
 justify-content:center
}
.type_banner_collection a.button.slt4_btn+a,
.type_hero_video a.button+a,
.type_image_text_overlay a.button+a,
.type_slideshow a.button.slt4_btn+a {
 margin-left:7.5px;
 margin-top:7.5px
}
.type_slideshow .slt4_p {
 line-height:1.42857143
}
.txt_content .pa_txts>:last-child {
 margin-bottom:0
}
::placeholder {
 color:currentcolor;
 opacity:1
}
:-ms-input-placeholder {
 color:currentcolor
}
::-ms-input-placeholder {
 color:currentcolor
}
#cat_shopify .la,
#cat_shopify .lab,
#cat_shopify .lad,
#cat_shopify .lal,
#cat_shopify .lar,
#cat_shopify .las {
 display:block
}
.clno-touchevents .cart_pos_dropdown #nt_cart_canvas {
 opacity:0
}
.nt_fk_canvas#nt_login_canvas .lds_bginfinity {
 height:100vh
}
.footer_collapse_true .widget_logo .widget-title {
 transition:.2s
}
.js-sl-item.is_varhide {
 display:none
}
.n-item i {
 position:absolute;
 right:3px;
 top:8px;
 pointer-events:none;
 background:#5b5b5b;
 font-size:15px;
 padding:5px;
 color:#fff;
 border-radius:100px
}
.des_style_2 .sp-tab-content [data-kl-full-width] {
 left:auto;
 padding:0;
 width:auto
}
.catalog_mode_true #callBackVariant_ppr,
.catalog_mode_true #callBackVariant_qv,
.catalog_mode_true .js__qs,
.catalog_mode_true .js_addtc,
.catalog_mode_true .sticky_atc_wrap .quantity,
.catalog_mode_true .sticky_atc_wrap .sticky_atc_js,
.pr__view-in-space[data-shopify-xr-hidden] {
 display:none!important
}
.pr-single__media .media-item,
.pr-single__media .media-video,
.pr-single__media .plyr,
.pr-single__media .plyr__video-wrapper,
.pr-single__media .shopify-model-viewer-ui,
.pr-single__media iframe,
.pr-single__media model-viewer {
 height:100%
}
.pr-single__media .media-item,
.pr-single__media .plyr,
.pr-single__media .shopify-model-viewer-ui,
.pr-single__media iframe,
.pr-single__media model-viewer,
.pr-single__media>video {
 position:absolute;
 top:0;
 left:0;
 width:100%;
 max-width:100%;
 outline:0
}
.plyr input:not([type=submit]):not([type=checkbox]) {
 border:0;
 padding:0;
 color:#222
}
.plyr [data-plyr=fullscreen],
.plyr__control {
 color:inherit!important
}
.plyr__control--overlaid.plyr__tab-focus,
.plyr__control--overlaid:hover {
 background-color:#fff;
 color:rgba(58,58,58,.55)!important;
 border-color:transparent
}
.plyr.plyr--full-ui .plyr__volume input[type=range] {
 color:#fff
}
.branding img {
 padding-top:5px;
 padding-bottom:5px;
 perspective:800px;
 -webkit-perspective:800px;
 backface-visibility:hidden;
 -webkit-backface-visibility:hidden
}
.col_ins .la-video,
.col_ins.ins_media_type_VIDEO .la-image,
.countdown-wrap.expired_cdt4,
.like_t4insundefined.cms_t4insundefined>span {
 display:none
}
.col_ins.ins_media_type_VIDEO .la-video {
 display:inline-block
}
.col_ins.ins_media_type_CAROUSEL_ALBUM .la-image:before {
 content:"\f302"
}
.type_instagram_feed2 .col_ins .las,
.type_instagram_feed3 .col_ins .las {
 font-size:30px
}
.type_prs_countd_deal .medizin_laypout {
 border:2px solid #4e97fd;
 border-radius:5px;
 padding:0 20px 25px;
 margin-top:21px
}
.medizin_laypout .product-cd-heading {
 line-height:1.2;
 margin-right:0;
 margin-bottom:5px
}
.medizin_laypout .product-cd-header {
 background:#fff;
 -webkit-transform:translateY(-23px);
 -ms-transform:translateY(-23px);
 transform:translateY(-23px);
 padding:5px 20px
}
.medizin_laypout .countdown-wrap {
 color:#fff;
 background:#e4573d;
 border-radius:30px;
 padding:8px 24px;
 margin-bottom:5px;
 position:static;
 transform:none;
 -webkit-transform:none
}
.medizin_laypout .countdown-label {
 font-size:18px;
 line-height:1
}
.medizin_laypout .pr_coun_dt {
 font-size:18px;
 line-height:1;
 font-weight:600
}
.medizin_laypout .loop-product-stock .status-bar {
 background-color:#ededed;
 margin:22px 0 10px;
 border-radius:5px
}
.medizin_laypout .loop-product-stock .sold-bar,
.medizin_laypout .loop-product-stock .status-bar {
 height:8px
}
.medizin_laypout .product-stock-status .available,
.medizin_laypout .product-stock-status .sold {
 font-size:15px
}
.medizin_laypout .product-stock-status .sold {
 -webkit-box-flex:1;
 -webkit-flex-grow:1;
 -ms-flex-positive:1;
 flex-grow:1;
 margin-right:20px
}
.medizin_laypout .product-stock-status .available {
 -webkit-flex-shrink:0;
 -ms-flex-negative:0;
 flex-shrink:0
}
.type_faq .nt_boxed {
 max-width:800px;
 width:100%;
 padding-right:15px;
 padding-left:15px;
 margin-right:auto;
 margin-left:auto
}
.faq_des2 .sp-tab .tab-heading {
 padding:10px 30px;
 min-height:60px
}
.faq_des2 .sp-tab .sp-tab-content {
 padding:20px 30px
}
.faq_des2 .nav_link_icon {
 width:40px;
 height:40px;
 border-radius:50px
}
.faq_des2 .tab-heading .txt_h_tab {
 padding:0
}
.type_faq .sp-tabs>.sp-tab.des_style_2:first-of-type {
 margin-top:0
}
.post-readmore {
 letter-spacing:.17em;
 font-weight:500;
 font-size:14px;
 line-height:30px
}
.h3.heading_sea {
 font-size:20px;
 font-weight:600;
 margin-bottom:10px;
 line-height:24px
}
.wrap_sea_section .input-group__field.search-form__input-wrapper,
.wrap_sea_section button.search-form__connected-submit,
.wrap_sea_section input.search-form__input {
 height:50px
}
.wrap_sea_section {
 width:90%;
 max-width:850px;
 margin:0 auto
}
.wrap_sea_section .input-group__field.search-form__input-wrapper {
 padding:0 12px;
 border-radius:12px;
 box-shadow:0 4px 4px rgba(0,0,0,.25);
 border:0;
 background-color:#ffff
}
.wrap_sea_section input.search-form__input {
 border:0;
 font-size:16px;
 line-height:36px;
 padding-left:42px;
 color:#222;
 font-weight:400;
 background:0 0
}
.wrap_sea_section .search-form .input-group {
 padding:5px 0
}
.wrap_sea_section input.search-form__input::-webkit-input-placeholder,
.wrap_sea_section input.search-form__input::placeholder {
 color:#8c8c8c
}
.wrap_sea_section button.search-form__connected-submit {
 width:60px;
 font-size:24px;
 right:10px;
 left:0;
 position:absolute;
 top:5px;
 border:0;
 background:0 0;
 padding:0;
 color:#222
}
.bg_sz_cover {
 background-size:cover
}
.bg_rp_norepeat {
 background-repeat:no-repeat
}
.our-store h3 {
 font-size:20px
}
.our-store h4 {
 font-size:14px
}
.our-store p {
 margin-bottom:0
}
.our-store {
 width:90%;
 margin:auto;
 padding:40px 20px
}
.our-store .separate {
 height:2px;
 margin:20px auto;
 width:80px;
 background:currentColor
}
.our-store h3,
.our-store h4 {
 font-weight:700;
 color:currentcolor
}
.agree_checkbox a.text-link {
 text-decoration:underline
}
.prs_bordered_grid_2 .products .nt_pr .product-image {
 border:1px solid #eee
}
.prs_bordered_grid_3 .products:not(.articles) .flickity-viewport:before {
 position:absolute;
 content:"";
 width:100%;
 height:100%;
 border:1px solid #eee;
 z-index:1;
 pointer-events:none;
 display:block!important
}
.prs_bordered_grid_3 .products:not(.articles) .flickity-viewport .nt_pr {
 min-height:100%
}
.prs_bordered_grid_3 .products .nt_pr,
.prs_bordered_grid_3 .products .nt_pr:not(.pr_list_item) {
 margin-top:0!important;
 padding-top:15px;
 padding-bottom:15px;
 box-shadow:inset -1px -1px #eee,-1px -1px #eee
}
.prs_bordered_grid_3 .on_list_view_true.products .nt_pr {
 padding-top:0;
 padding-bottom:0;
 box-shadow:none!important;
 border:0!important
}
.prs_bordered_grid_3 .wrap_title+.products:not(.articles) {
 margin-top:25px
}
.prs_bordered_grid_3 .cat_toolbar,
.prs_bordered_grid_3 .tab_se_header {
 margin-bottom:30px
}
.prs_bordered_grid_3 .type_prs_countd_deal .flickity-prev-next-button {
 z-index:10
}
.type_feature_columns a.button {
 padding:10px 25px;
 line-height:18px
}
.type_feature_columns .rte-setting>p {
 margin-bottom:15px
}
.type_feature_columns a.button:not(.btn--style-1) {
 color:#222;
 padding:0;
 border:0;
 min-height:auto;
 background-color:transparent!important
}
.type_feature_columns a.button.btn--style-2 {
 border-bottom:2px solid #dfdfdf
}
.type_feature_columns a.button.btn--style-2:hover {
 border-color:#c5c5c5;
 color:#222
}
.type_feature_columns .nt_banner_holder .cat_space_item {
 margin-bottom:0
}
.prs_sw_limit_true .swatch__list:not(.swatch__list--calced) {
 white-space:nowrap;
 overflow:hidden
}
.swatch__list--more {
 display:none!important
}
.pa_txts a.button,
a.slt4_btn {
 display:-ms-inline-flexbox;
 display:inline-flex;
 -ms-flex-align:center;
 align-items:center;
 -ms-flex-pack:center;
 justify-content:center
}
.se_t4_bg {
 background-repeat:no-repeat;
 background-size:cover
}
.on_list_view_true .pr_wr_dcimg .pr_deal_dt {
 position:static;
 width:100%;
 max-width:120px;
 margin:0;
 transform:none;
 -webkit-transform:none
}
.bg_content_true.border_bl_true .pa_txts>div {
 border:1px solid #222
}
.bg_content_true.border_bl_true.br_style_dashed .pa_txts>div {
 border:1px dashed #222
}
.bg_content_true.border_bl_true.br_style_double .pa_txts>div {
 border:4px double #222
}
.space_banner {
 max-width:100%
}
@media (max-width:767px) {
 .hide_bgimg_true {
  background-image:none!important
 }
 .pa_txts.mb_h_pos0 {
  left:0
 }
 .pa_txts.mb_v_pos0 {
  top:0
 }
 .pa_txts.mb_h_pos1 {
  left:1%
 }
 .pa_txts.mb_v_pos1 {
  top:1%
 }
 .pa_txts.mb_h_pos2 {
  left:2%
 }
 .pa_txts.mb_v_pos2 {
  top:2%
 }
 .pa_txts.mb_h_pos3 {
  left:3%
 }
 .pa_txts.mb_v_pos3 {
  top:3%
 }
 .pa_txts.mb_h_pos4 {
  left:4%
 }
 .pa_txts.mb_v_pos4 {
  top:4%
 }
 .pa_txts.mb_h_pos5 {
  left:5%
 }
 .pa_txts.mb_v_pos5 {
  top:5%
 }
 .pa_txts.mb_h_pos6 {
  left:6%
 }
 .pa_txts.mb_v_pos6 {
  top:6%
 }
 .pa_txts.mb_h_pos7 {
  left:7%
 }
 .pa_txts.mb_v_pos7 {
  top:7%
 }
 .pa_txts.mb_h_pos8 {
  left:8%
 }
 .pa_txts.mb_v_pos8 {
  top:8%
 }
 .pa_txts.mb_h_pos9 {
  left:9%
 }
 .pa_txts.mb_v_pos9 {
  top:9%
 }
 .pa_txts.mb_h_pos10 {
  left:10%
 }
 .pa_txts.mb_v_pos10 {
  top:10%
 }
 .pa_txts.mb_h_pos11 {
  left:11%
 }
 .pa_txts.mb_v_pos11 {
  top:11%
 }
 .pa_txts.mb_h_pos12 {
  left:12%
 }
 .pa_txts.mb_v_pos12 {
  top:12%
 }
 .pa_txts.mb_h_pos13 {
  left:13%
 }
 .pa_txts.mb_v_pos13 {
  top:13%
 }
 .pa_txts.mb_h_pos14 {
  left:14%
 }
 .pa_txts.mb_v_pos14 {
  top:14%
 }
 .pa_txts.mb_h_pos15 {
  left:15%
 }
 .pa_txts.mb_v_pos15 {
  top:15%
 }
 .pa_txts.mb_h_pos16 {
  left:16%
 }
 .pa_txts.mb_v_pos16 {
  top:16%
 }
 .pa_txts.mb_h_pos17 {
  left:17%
 }
 .pa_txts.mb_v_pos17 {
  top:17%
 }
 .pa_txts.mb_h_pos18 {
  left:18%
 }
 .pa_txts.mb_v_pos18 {
  top:18%
 }
 .pa_txts.mb_h_pos19 {
  left:19%
 }
 .pa_txts.mb_v_pos19 {
  top:19%
 }
 .pa_txts.mb_h_pos20 {
  left:20%
 }
 .pa_txts.mb_v_pos20 {
  top:20%
 }
 .pa_txts.mb_h_pos21 {
  left:21%
 }
 .pa_txts.mb_v_pos21 {
  top:21%
 }
 .pa_txts.mb_h_pos22 {
  left:22%
 }
 .pa_txts.mb_v_pos22 {
  top:22%
 }
 .pa_txts.mb_h_pos23 {
  left:23%
 }
 .pa_txts.mb_v_pos23 {
  top:23%
 }
 .pa_txts.mb_h_pos24 {
  left:24%
 }
 .pa_txts.mb_v_pos24 {
  top:24%
 }
 .pa_txts.mb_h_pos25 {
  left:25%
 }
 .pa_txts.mb_v_pos25 {
  top:25%
 }
 .pa_txts.mb_h_pos26 {
  left:26%
 }
 .pa_txts.mb_v_pos26 {
  top:26%
 }
 .pa_txts.mb_h_pos27 {
  left:27%
 }
 .pa_txts.mb_v_pos27 {
  top:27%
 }
 .pa_txts.mb_h_pos28 {
  left:28%
 }
 .pa_txts.mb_v_pos28 {
  top:28%
 }
 .pa_txts.mb_h_pos29 {
  left:29%
 }
 .pa_txts.mb_v_pos29 {
  top:29%
 }
 .pa_txts.mb_h_pos30 {
  left:30%
 }
 .pa_txts.mb_v_pos30 {
  top:30%
 }
 .pa_txts.mb_h_pos31 {
  left:31%
 }
 .pa_txts.mb_v_pos31 {
  top:31%
 }
 .pa_txts.mb_h_pos32 {
  left:32%
 }
 .pa_txts.mb_v_pos32 {
  top:32%
 }
 .pa_txts.mb_h_pos33 {
  left:33%
 }
 .pa_txts.mb_v_pos33 {
  top:33%
 }
 .pa_txts.mb_h_pos34 {
  left:34%
 }
 .pa_txts.mb_v_pos34 {
  top:34%
 }
 .pa_txts.mb_h_pos35 {
  left:35%
 }
 .pa_txts.mb_v_pos35 {
  top:35%
 }
 .pa_txts.mb_h_pos36 {
  left:36%
 }
 .pa_txts.mb_v_pos36 {
  top:36%
 }
 .pa_txts.mb_h_pos37 {
  left:37%
 }
 .pa_txts.mb_v_pos37 {
  top:37%
 }
 .pa_txts.mb_h_pos38 {
  left:38%
 }
 .pa_txts.mb_v_pos38 {
  top:38%
 }
 .pa_txts.mb_h_pos39 {
  left:39%
 }
 .pa_txts.mb_v_pos39 {
  top:39%
 }
 .pa_txts.mb_h_pos40 {
  left:40%
 }
 .pa_txts.mb_v_pos40 {
  top:40%
 }
 .pa_txts.mb_h_pos41 {
  left:41%
 }
 .pa_txts.mb_v_pos41 {
  top:41%
 }
 .pa_txts.mb_h_pos42 {
  left:42%
 }
 .pa_txts.mb_v_pos42 {
  top:42%
 }
 .pa_txts.mb_h_pos43 {
  left:43%
 }
 .pa_txts.mb_v_pos43 {
  top:43%
 }
 .pa_txts.mb_h_pos44 {
  left:44%
 }
 .pa_txts.mb_v_pos44 {
  top:44%
 }
 .pa_txts.mb_h_pos45 {
  left:45%
 }
 .pa_txts.mb_v_pos45 {
  top:45%
 }
 .pa_txts.mb_h_pos46 {
  left:46%
 }
 .pa_txts.mb_v_pos46 {
  top:46%
 }
 .pa_txts.mb_h_pos47 {
  left:47%
 }
 .pa_txts.mb_v_pos47 {
  top:47%
 }
 .pa_txts.mb_h_pos48 {
  left:48%
 }
 .pa_txts.mb_v_pos48 {
  top:48%
 }
 .pa_txts.mb_h_pos49 {
  left:49%
 }
 .pa_txts.mb_v_pos49 {
  top:49%
 }
 .pa_txts.mb_h_pos50 {
  width:100%;
  transform:translateX(-50%);
  left:50%
 }
 .bg_content_true .pa_txts.mb_h_pos50 {
  display:-ms-flexbox;
  display:flex;
  -ms-flex-pack:center;
  justify-content:center
 }
 .pa_txts.mb_v_pos50 {
  transform:translateY(-50%);
  top:50%
 }
 .pa_txts.mb_h_pos51 {
  right:49%
 }
 .pa_txts.mb_v_pos51 {
  bottom:49%
 }
 .pa_txts.mb_h_pos52 {
  right:48%
 }
 .pa_txts.mb_v_pos52 {
  bottom:48%
 }
 .pa_txts.mb_h_pos53 {
  right:47%
 }
 .pa_txts.mb_v_pos53 {
  bottom:47%
 }
 .pa_txts.mb_h_pos54 {
  right:46%
 }
 .pa_txts.mb_v_pos54 {
  bottom:46%
 }
 .pa_txts.mb_h_pos55 {
  right:45%
 }
 .pa_txts.mb_v_pos55 {
  bottom:45%
 }
 .pa_txts.mb_h_pos56 {
  right:44%
 }
 .pa_txts.mb_v_pos56 {
  bottom:44%
 }
 .pa_txts.mb_h_pos57 {
  right:43%
 }
 .pa_txts.mb_v_pos57 {
  bottom:43%
 }
 .pa_txts.mb_h_pos58 {
  right:42%
 }
 .pa_txts.mb_v_pos58 {
  bottom:42%
 }
 .pa_txts.mb_h_pos59 {
  right:41%
 }
 .pa_txts.mb_v_pos59 {
  bottom:41%
 }
 .pa_txts.mb_h_pos60 {
  right:40%
 }
 .pa_txts.mb_v_pos60 {
  bottom:40%
 }
 .pa_txts.mb_h_pos61 {
  right:39%
 }
 .pa_txts.mb_v_pos61 {
  bottom:39%
 }
 .pa_txts.mb_h_pos62 {
  right:38%
 }
 .pa_txts.mb_v_pos62 {
  bottom:38%
 }
 .pa_txts.mb_h_pos63 {
  right:37%
 }
 .pa_txts.mb_v_pos63 {
  bottom:37%
 }
 .pa_txts.mb_h_pos64 {
  right:36%
 }
 .pa_txts.mb_v_pos64 {
  bottom:36%
 }
 .pa_txts.mb_h_pos65 {
  right:35%
 }
 .pa_txts.mb_v_pos65 {
  bottom:35%
 }
 .pa_txts.mb_h_pos66 {
  right:34%
 }
 .pa_txts.mb_v_pos66 {
  bottom:34%
 }
 .pa_txts.mb_h_pos67 {
  right:33%
 }
 .pa_txts.mb_v_pos67 {
  bottom:33%
 }
 .pa_txts.mb_h_pos68 {
  right:32%
 }
 .pa_txts.mb_v_pos68 {
  bottom:32%
 }
 .pa_txts.mb_h_pos69 {
  right:31%
 }
 .pa_txts.mb_v_pos69 {
  bottom:31%
 }
 .pa_txts.mb_h_pos70 {
  right:30%
 }
 .pa_txts.mb_v_pos70 {
  bottom:30%
 }
 .pa_txts.mb_h_pos71 {
  right:29%
 }
 .pa_txts.mb_v_pos71 {
  bottom:29%
 }
 .pa_txts.mb_h_pos72 {
  right:28%
 }
 .pa_txts.mb_v_pos72 {
  bottom:28%
 }
 .pa_txts.mb_h_pos73 {
  right:27%
 }
 .pa_txts.mb_v_pos73 {
  bottom:27%
 }
 .pa_txts.mb_h_pos74 {
  right:26%
 }
 .pa_txts.mb_v_pos74 {
  bottom:26%
 }
 .pa_txts.mb_h_pos75 {
  right:25%
 }
 .pa_txts.mb_v_pos75 {
  bottom:25%
 }
 .pa_txts.mb_h_pos76 {
  right:24%
 }
 .pa_txts.mb_v_pos76 {
  bottom:24%
 }
 .pa_txts.mb_h_pos77 {
  right:23%
 }
 .pa_txts.mb_v_pos77 {
  bottom:23%
 }
 .pa_txts.mb_h_pos78 {
  right:22%
 }
 .pa_txts.mb_v_pos78 {
  bottom:22%
 }
 .pa_txts.mb_h_pos79 {
  right:21%
 }
 .pa_txts.mb_v_pos79 {
  bottom:21%
 }
 .pa_txts.mb_h_pos80 {
  right:20%
 }
 .pa_txts.mb_v_pos80 {
  bottom:20%
 }
 .pa_txts.mb_h_pos81 {
  right:19%
 }
 .pa_txts.mb_v_pos81 {
  bottom:19%
 }
 .pa_txts.mb_h_pos82 {
  right:18%
 }
 .pa_txts.mb_v_pos82 {
  bottom:18%
 }
 .pa_txts.mb_h_pos83 {
  right:17%
 }
 .pa_txts.mb_v_pos83 {
  bottom:17%
 }
 .pa_txts.mb_h_pos84 {
  right:16%
 }
 .pa_txts.mb_v_pos84 {
  bottom:16%
 }
 .pa_txts.mb_h_pos85 {
  right:15%
 }
 .pa_txts.mb_v_pos85 {
  bottom:15%
 }
 .pa_txts.mb_h_pos86 {
  right:14%
 }
 .pa_txts.mb_v_pos86 {
  bottom:14%
 }
 .pa_txts.mb_h_pos87 {
  right:13%
 }
 .pa_txts.mb_v_pos87 {
  bottom:13%
 }
 .pa_txts.mb_h_pos88 {
  right:12%
 }
 .pa_txts.mb_v_pos88 {
  bottom:12%
 }
 .pa_txts.mb_h_pos89 {
  right:11%
 }
 .pa_txts.mb_v_pos89 {
  bottom:11%
 }
 .pa_txts.mb_h_pos90 {
  right:10%
 }
 .pa_txts.mb_v_pos90 {
  bottom:10%
 }
 .pa_txts.mb_h_pos91 {
  right:9%
 }
 .pa_txts.mb_v_pos91 {
  bottom:9%
 }
 .pa_txts.mb_h_pos92 {
  right:8%
 }
 .pa_txts.mb_v_pos92 {
  bottom:8%
 }
 .pa_txts.mb_h_pos93 {
  right:7%
 }
 .pa_txts.mb_v_pos93 {
  bottom:7%
 }
 .pa_txts.mb_h_pos94 {
  right:6%
 }
 .pa_txts.mb_v_pos94 {
  bottom:6%
 }
 .pa_txts.mb_h_pos95 {
  right:5%
 }
 .pa_txts.mb_v_pos95 {
  bottom:5%
 }
 .pa_txts.mb_h_pos96 {
  right:4%
 }
 .pa_txts.mb_v_pos96 {
  bottom:4%
 }
 .pa_txts.mb_h_pos97 {
  right:3%
 }
 .pa_txts.mb_v_pos97 {
  bottom:3%
 }
 .pa_txts.mb_h_pos98 {
  right:2%
 }
 .pa_txts.mb_v_pos98 {
  bottom:2%
 }
 .pa_txts.mb_h_pos99 {
  right:1%
 }
 .pa_txts.mb_v_pos99 {
  bottom:1%
 }
 .pa_txts.mb_h_pos100 {
  right:0
 }
 .pa_txts.mb_v_pos100 {
  bottom:0
 }
 .pa_txts.mb_v_pos50.mb_h_pos50 {
  transform:translate(-50%,-50%)
 }
 .bg_content_true .pa_txts.mb_v_pos50.mb_h_pos50 {
  display:-ms-flexbox;
  display:flex;
  -ms-flex-pack:center;
  justify-content:center
 }
}
@media (min-width:768px) {
 .pa_txts.v_pos50.h_pos50 {
  transform:translate(-50%,-50%)
 }
 .bg_content_true .pa_txts.v_pos50.h_pos50 {
  display:-ms-flexbox;
  display:flex;
  -ms-flex-pack:center;
  justify-content:center
 }
 .pa_txts.h_pos0 {
  left:0
 }
 .pa_txts.v_pos0 {
  top:0
 }
 .pa_txts.h_pos1 {
  left:1%
 }
 .pa_txts.v_pos1 {
  top:1%
 }
 .pa_txts.h_pos2 {
  left:2%
 }
 .pa_txts.v_pos2 {
  top:2%
 }
 .pa_txts.h_pos3 {
  left:3%
 }
 .pa_txts.v_pos3 {
  top:3%
 }
 .pa_txts.h_pos4 {
  left:4%
 }
 .pa_txts.v_pos4 {
  top:4%
 }
 .pa_txts.h_pos5 {
  left:5%
 }
 .pa_txts.v_pos5 {
  top:5%
 }
 .pa_txts.h_pos6 {
  left:6%
 }
 .pa_txts.v_pos6 {
  top:6%
 }
 .pa_txts.h_pos7 {
  left:7%
 }
 .pa_txts.v_pos7 {
  top:7%
 }
 .pa_txts.h_pos8 {
  left:8%
 }
 .pa_txts.v_pos8 {
  top:8%
 }
 .pa_txts.h_pos9 {
  left:9%
 }
 .pa_txts.v_pos9 {
  top:9%
 }
 .pa_txts.h_pos10 {
  left:10%
 }
 .pa_txts.v_pos10 {
  top:10%
 }
 .pa_txts.h_pos11 {
  left:11%
 }
 .pa_txts.v_pos11 {
  top:11%
 }
 .pa_txts.h_pos12 {
  left:12%
 }
 .pa_txts.v_pos12 {
  top:12%
 }
 .pa_txts.h_pos13 {
  left:13%
 }
 .pa_txts.v_pos13 {
  top:13%
 }
 .pa_txts.h_pos14 {
  left:14%
 }
 .pa_txts.v_pos14 {
  top:14%
 }
 .pa_txts.h_pos15 {
  left:15%
 }
 .pa_txts.v_pos15 {
  top:15%
 }
 .pa_txts.h_pos16 {
  left:16%
 }
 .pa_txts.v_pos16 {
  top:16%
 }
 .pa_txts.h_pos17 {
  left:17%
 }
 .pa_txts.v_pos17 {
  top:17%
 }
 .pa_txts.h_pos18 {
  left:18%
 }
 .pa_txts.v_pos18 {
  top:18%
 }
 .pa_txts.h_pos19 {
  left:19%
 }
 .pa_txts.v_pos19 {
  top:19%
 }
 .pa_txts.h_pos20 {
  left:20%
 }
 .pa_txts.v_pos20 {
  top:20%
 }
 .pa_txts.h_pos21 {
  left:21%
 }
 .pa_txts.v_pos21 {
  top:21%
 }
 .pa_txts.h_pos22 {
  left:22%
 }
 .pa_txts.v_pos22 {
  top:22%
 }
 .pa_txts.h_pos23 {
  left:23%
 }
 .pa_txts.v_pos23 {
  top:23%
 }
 .pa_txts.h_pos24 {
  left:24%
 }
 .pa_txts.v_pos24 {
  top:24%
 }
 .pa_txts.h_pos25 {
  left:25%
 }
 .pa_txts.v_pos25 {
  top:25%
 }
 .pa_txts.h_pos26 {
  left:26%
 }
 .pa_txts.v_pos26 {
  top:26%
 }
 .pa_txts.h_pos27 {
  left:27%
 }
 .pa_txts.v_pos27 {
  top:27%
 }
 .pa_txts.h_pos28 {
  left:28%
 }
 .pa_txts.v_pos28 {
  top:28%
 }
 .pa_txts.h_pos29 {
  left:29%
 }
 .pa_txts.v_pos29 {
  top:29%
 }
 .pa_txts.h_pos30 {
  left:30%
 }
 .pa_txts.v_pos30 {
  top:30%
 }
 .pa_txts.h_pos31 {
  left:31%
 }
 .pa_txts.v_pos31 {
  top:31%
 }
 .pa_txts.h_pos32 {
  left:32%
 }
 .pa_txts.v_pos32 {
  top:32%
 }
 .pa_txts.h_pos33 {
  left:33%
 }
 .pa_txts.v_pos33 {
  top:33%
 }
 .pa_txts.h_pos34 {
  left:34%
 }
 .pa_txts.v_pos34 {
  top:34%
 }
 .pa_txts.h_pos35 {
  left:35%
 }
 .pa_txts.v_pos35 {
  top:35%
 }
 .pa_txts.h_pos36 {
  left:36%
 }
 .pa_txts.v_pos36 {
  top:36%
 }
 .pa_txts.h_pos37 {
  left:37%
 }
 .pa_txts.v_pos37 {
  top:37%
 }
 .pa_txts.h_pos38 {
  left:38%
 }
 .pa_txts.v_pos38 {
  top:38%
 }
 .pa_txts.h_pos39 {
  left:39%
 }
 .pa_txts.v_pos39 {
  top:39%
 }
 .pa_txts.h_pos40 {
  left:40%
 }
 .pa_txts.v_pos40 {
  top:40%
 }
 .pa_txts.h_pos41 {
  left:41%
 }
 .pa_txts.v_pos41 {
  top:41%
 }
 .pa_txts.h_pos42 {
  left:42%
 }
 .pa_txts.v_pos42 {
  top:42%
 }
 .pa_txts.h_pos43 {
  left:43%
 }
 .pa_txts.v_pos43 {
  top:43%
 }
 .pa_txts.h_pos44 {
  left:44%
 }
 .pa_txts.v_pos44 {
  top:44%
 }
 .pa_txts.h_pos45 {
  left:45%
 }
 .pa_txts.v_pos45 {
  top:45%
 }
 .pa_txts.h_pos46 {
  left:46%
 }
 .pa_txts.v_pos46 {
  top:46%
 }
 .pa_txts.h_pos47 {
  left:47%
 }
 .pa_txts.v_pos47 {
  top:47%
 }
 .pa_txts.h_pos48 {
  left:48%
 }
 .pa_txts.v_pos48 {
  top:48%
 }
 .pa_txts.h_pos49 {
  left:49%
 }
 .pa_txts.v_pos49 {
  top:49%
 }
 .pa_txts.h_pos50 {
  width:100%;
  transform:translateX(-50%);
  left:50%
 }
 .bg_content_true .pa_txts.h_pos50 {
  display:-ms-flexbox;
  display:flex;
  -ms-flex-pack:center;
  justify-content:center
 }
 .pa_txts.v_pos50 {
  transform:translateY(-50%);
  top:50%
 }
 .pa_txts.h_pos51 {
  right:49%
 }
 .pa_txts.v_pos51 {
  bottom:49%
 }
 .pa_txts.h_pos52 {
  right:48%
 }
 .pa_txts.v_pos52 {
  bottom:48%
 }
 .pa_txts.h_pos53 {
  right:47%
 }
 .pa_txts.v_pos53 {
  bottom:47%
 }
 .pa_txts.h_pos54 {
  right:46%
 }
 .pa_txts.v_pos54 {
  bottom:46%
 }
 .pa_txts.h_pos55 {
  right:45%
 }
 .pa_txts.v_pos55 {
  bottom:45%
 }
 .pa_txts.h_pos56 {
  right:44%
 }
 .pa_txts.v_pos56 {
  bottom:44%
 }
 .pa_txts.h_pos57 {
  right:43%
 }
 .pa_txts.v_pos57 {
  bottom:43%
 }
 .pa_txts.h_pos58 {
  right:42%
 }
 .pa_txts.v_pos58 {
  bottom:42%
 }
 .pa_txts.h_pos59 {
  right:41%
 }
 .pa_txts.v_pos59 {
  bottom:41%
 }
 .pa_txts.h_pos60 {
  right:40%
 }
 .pa_txts.v_pos60 {
  bottom:40%
 }
 .pa_txts.h_pos61 {
  right:39%
 }
 .pa_txts.v_pos61 {
  bottom:39%
 }
 .pa_txts.h_pos62 {
  right:38%
 }
 .pa_txts.v_pos62 {
  bottom:38%
 }
 .pa_txts.h_pos63 {
  right:37%
 }
 .pa_txts.v_pos63 {
  bottom:37%
 }
 .pa_txts.h_pos64 {
  right:36%
 }
 .pa_txts.v_pos64 {
  bottom:36%
 }
 .pa_txts.h_pos65 {
  right:35%
 }
 .pa_txts.v_pos65 {
  bottom:35%
 }
 .pa_txts.h_pos66 {
  right:34%
 }
 .pa_txts.v_pos66 {
  bottom:34%
 }
 .pa_txts.h_pos67 {
  right:33%
 }
 .pa_txts.v_pos67 {
  bottom:33%
 }
 .pa_txts.h_pos68 {
  right:32%
 }
 .pa_txts.v_pos68 {
  bottom:32%
 }
 .pa_txts.h_pos69 {
  right:31%
 }
 .pa_txts.v_pos69 {
  bottom:31%
 }
 .pa_txts.h_pos70 {
  right:30%
 }
 .pa_txts.v_pos70 {
  bottom:30%
 }
 .pa_txts.h_pos71 {
  right:29%
 }
 .pa_txts.v_pos71 {
  bottom:29%
 }
 .pa_txts.h_pos72 {
  right:28%
 }
 .pa_txts.v_pos72 {
  bottom:28%
 }
 .pa_txts.h_pos73 {
  right:27%
 }
 .pa_txts.v_pos73 {
  bottom:27%
 }
 .pa_txts.h_pos74 {
  right:26%
 }
 .pa_txts.v_pos74 {
  bottom:26%
 }
 .pa_txts.h_pos75 {
  right:25%
 }
 .pa_txts.v_pos75 {
  bottom:25%
 }
 .pa_txts.h_pos76 {
  right:24%
 }
 .pa_txts.v_pos76 {
  bottom:24%
 }
 .pa_txts.h_pos77 {
  right:23%
 }
 .pa_txts.v_pos77 {
  bottom:23%
 }
 .pa_txts.h_pos78 {
  right:22%
 }
 .pa_txts.v_pos78 {
  bottom:22%
 }
 .pa_txts.h_pos79 {
  right:21%
 }
 .pa_txts.v_pos79 {
  bottom:21%
 }
 .pa_txts.h_pos80 {
  right:20%
 }
 .pa_txts.v_pos80 {
  bottom:20%
 }
 .pa_txts.h_pos81 {
  right:19%
 }
 .pa_txts.v_pos81 {
  bottom:19%
 }
 .pa_txts.h_pos82 {
  right:18%
 }
 .pa_txts.v_pos82 {
  bottom:18%
 }
 .pa_txts.h_pos83 {
  right:17%
 }
 .pa_txts.v_pos83 {
  bottom:17%
 }
 .pa_txts.h_pos84 {
  right:16%
 }
 .pa_txts.v_pos84 {
  bottom:16%
 }
 .pa_txts.h_pos85 {
  right:15%
 }
 .pa_txts.v_pos85 {
  bottom:15%
 }
 .pa_txts.h_pos86 {
  right:14%
 }
 .pa_txts.v_pos86 {
  bottom:14%
 }
 .pa_txts.h_pos87 {
  right:13%
 }
 .pa_txts.v_pos87 {
  bottom:13%
 }
 .pa_txts.h_pos88 {
  right:12%
 }
 .pa_txts.v_pos88 {
  bottom:12%
 }
 .pa_txts.h_pos89 {
  right:11%
 }
 .pa_txts.v_pos89 {
  bottom:11%
 }
 .pa_txts.h_pos90 {
  right:10%
 }
 .pa_txts.v_pos90 {
  bottom:10%
 }
 .pa_txts.h_pos91 {
  right:9%
 }
 .pa_txts.v_pos91 {
  bottom:9%
 }
 .pa_txts.h_pos92 {
  right:8%
 }
 .pa_txts.v_pos92 {
  bottom:8%
 }
 .pa_txts.h_pos93 {
  right:7%
 }
 .pa_txts.v_pos93 {
  bottom:7%
 }
 .pa_txts.h_pos94 {
  right:6%
 }
 .pa_txts.v_pos94 {
  bottom:6%
 }
 .pa_txts.h_pos95 {
  right:5%
 }
 .pa_txts.v_pos95 {
  bottom:5%
 }
 .pa_txts.h_pos96 {
  right:4%
 }
 .pa_txts.v_pos96 {
  bottom:4%
 }
 .pa_txts.h_pos97 {
  right:3%
 }
 .pa_txts.v_pos97 {
  bottom:3%
 }
 .pa_txts.h_pos98 {
  right:2%
 }
 .pa_txts.v_pos98 {
  bottom:2%
 }
 .pa_txts.h_pos99 {
  right:1%
 }
 .pa_txts.v_pos99 {
  bottom:1%
 }
 .pa_txts.h_pos100 {
  right:0
 }
 .pa_txts.v_pos100 {
  bottom:0
 }
}
.type_promo_text p {
 margin-bottom:0
}
.type_promo_text a {
 color:currentcolor
}
.type_promo_text a:hover {
 color:currentcolor;
 text-decoration:underline
}
.bg_content_true a.button {
 min-width:auto!important
}
.t4s_disable_se {
 opacity:.5
}
.rtet4 ol,
.rtet4 ul {
 margin:0 0 17.5px 25px;
 padding-left:0
}
.rtet4 ol.list--inline,
.rtet4 ul.list--inline {
 margin-left:0
}
.rtet4 ul {
 list-style:disc
}
.rtet4 ol {
 list-style:decimal
}
.rtet4 ul ul {
 list-style:circle
}
.rtet4 ul ul ul {
 list-style:square
}
.rtet4 a:not(.btn):not(.cd):not(.db):not(.cb):not(.chp) {
 border-bottom:1px solid currentColor;
 padding-bottom:1px
}
@media (min-width:500px) {
 .medizin_laypout .countdown-label,
 .medizin_laypout .product-cd-heading {
  margin-right:18px
 }
}
@media (min-width:767px) {
 .type_prs_countd_deal .medizin_laypout {
  padding:0 30px 48px
 }
 .medizin_laypout .flickity-prev-next-button.previous {
  margin:0 0 0 -53px
 }
 .medizin_laypout .flickity-prev-next-button.next {
  margin:0 -53px 0 0
 }
 .wrapper_boxed .medizin_laypout .flickity-prev-next-button.next {
  margin-right:-45px
 }
 .wrapper_boxed .medizin_laypout .flickity-prev-next-button.previous {
  margin-left:-45px
 }
}
@media (min-width:641px) {
 .on_list_view_true .pr_list_item .product-image {
  width:180px;
  margin-right:30px
 }
 .rtl_true .on_list_view_true .pr_list_item .product-image {
  margin-left:30px
 }
 .on_list_view_true .pr_list_item .product-inner {
  padding:20px;
  -ms-flex-align:center;
  align-items:center
 }
 .on_list_view_true .pr_list_item .product-info {
  margin:15px 0!important;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-align:center;
  align-items:center;
  -ms-flex-pack:justify;
  justify-content:space-between
 }
 .on_list_view_true .pr_list_item .product-info__btns {
  margin-left:30px;
  min-width:180px;
  margin-top:0
 }
 .rtl_true .on_list_view_true .pr_list_item .product-info__btns {
  margin-left:auto;
  margin-right:30px
 }
 #nt_content .shopify-challenge__container,
 .cat_emty_noprs,
 .empty_cart_page {
  margin:200px 0
 }
}
@media (min-width:768px) {
 html:not(.clno-touchevents) .cart_pos_dropdown #nt_cart_canvas {
  opacity:0
 }
 html.cltouchevents .cart_pos_dropdown #nt_cart_canvas {
  opacity:1
 }
 .on_list_view_true .pr_wr_dcimg .pr_deal_dt {
  max-width:180px
 }
 .pos_text_alt .category-content {
  margin:-74px 23px 0;
  padding:22px 28px 30px
 }
 .fs_md_14 {
  font-size:14px
 }
 .fs_md_50 {
  font-size:50px
 }
 .fs_md_20 {
  font-size:20px
 }
 .fs_md_55 {
  font-size:55px
 }
 .fs_md_60 {
  font-size:60px
 }
 .nt_action>a,
 .nt_action>div {
  padding:0 8px
 }
 .tl_md {
  text-align:left
 }
 .tr_md {
  text-align:right
 }
 .tc_md {
  text-align:center
 }
 .dn_mb,
 .dn_md {
  display:none
 }
 .db_mb,
 .db_md {
  display:block
 }
 .dib_mb,
 .dib_md {
  display:inline-block
 }
 .pa_md {
  position:absolute
 }
 .type_banner_collection .flickity-page-dots,
 .type_slideshow .flickity-page-dots {
  bottom:20px
 }
 .dek_img_slide,
 .dek_img_slide.nt_bg_lz {
  display:block
 }
 .fs_md_11 {
  font-size:11px
 }
 .fs_md_15 {
  font-size:15px
 }
 .fs_md_18 {
  font-size:18px
 }
 .fs_md_30 {
  font-size:30px
 }
 .fs_md_35 {
  font-size:35px
 }
 .fs_md_40 {
  font-size:40px
 }
 .cat_view .dev_mobile,
 .mb_img_slide,
 .mb_img_slide.nt_bg_lz,
 .sr_txt_mb {
  display:none
 }
 .pr__view-in-space {
  display:none!important
 }
 .sr_txt {
  display:inline-block
 }
 .cat_sortby>a {
  border:1px solid #ddd;
  border-radius:40px;
  padding:7px 7px 7px 15px;
  min-width:190px
 }
 .cat_view .dev_tablet {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex
 }
 .pr_carousel:after {
  content:''
 }
 .watchCSS:after {
  content:'flickity'
 }
 .product .summary {
  margin-top:0
 }
 .thumb_bottom .row.p-nav {
  margin-top:10px;
  margin-right:-5px;
  margin-left:-5px
 }
 .thumb_bottom .p-nav .n-item {
  padding-left:5px;
  padding-right:5px
 }
 .only_owl_mb.nt_slider:not(.flickity-enabled) {
  display:-ms-flexbox!important;
  display:flex!important
 }
 .thumb_2 .row.p-thumb {
  margin-right:-5px;
  margin-left:-5px
 }
 .thumb_2 .row.p-thumb .p-item {
  padding:5px;
  width:50%
 }
 .sp-single-3 .p-thumb .p-item:not(:last-child) {
  margin-bottom:30px
 }
 .sp-single-7 .p-thumb {
  margin-right:-5px;
  margin-left:-5px
 }
 .sp-single-7 .p-thumb>.p-item:not(:last-child) {
  margin-bottom:10px
 }
 .sp-single-7 .p-thumb>.p-item {
  padding-right:5px;
  padding-left:5px
 }
 .sp-single-3 .p-thumb .p-item.last_visible,
 .ul_tabs.des_mb_2 {
  margin-bottom:0
 }
 .ul_tabs.des_mb_2>.tab_title_block {
  display:inline-block
 }
 .ul_tabs li.tab_title_block a {
  min-height:40px;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -ms-flex-align:center;
  align-items:center;
  border-radius:40px;
  border:1px solid transparent;
  padding:0 25px;
  margin:0
 }
 .browser-Internet.platform-Windows .ul_tabs li.tab_title_block a {
  line-height:40px
 }
 .sp-tabs {
  padding-top:50px
 }
 .sp-tabs>.sp-tab.des_style_2:first-of-type {
  margin-top:-50px
 }
 .tab-heading .txt_h_tab {
  padding:10px 20px
 }
 .title_2>span {
  margin:0 30px
 }
 .title_2:after,
 .title_2:before {
  width:60px
 }
 .cat_space_0 {
  margin-left:0;
  margin-right:0
 }
 .cat_space_0 .cat_space_item {
  padding-left:0;
  padding-right:0;
  margin-bottom:0
 }
 .cat_space_2 {
  margin-left:-1px;
  margin-right:-1px
 }
 .cat_space_2 .cat_space_item {
  padding-left:1px;
  padding-right:1px;
  margin-bottom:2px
 }
 .cat_space_6 {
  margin-left:-3px;
  margin-right:-3px
 }
 .cat_space_6 .cat_space_item {
  padding-left:3px;
  padding-right:3px;
  margin-bottom:6px
 }
 .cat_space_10 {
  margin-left:-5px;
  margin-right:-5px
 }
 .cat_space_10 .cat_space_item {
  padding-left:5px;
  padding-right:5px;
  margin-bottom:10px
 }
 .newsletter_se .signup-newsletter-form input.input-text {
  min-width:320px
 }
 .newsletter_se .signup-newsletter-form .submit-btn {
  min-width:200px
 }
 .newl_des_3 .signup-newsletter-form .col_email {
  margin:0
 }
 .newsletter_se.newl_des_3 .signup-newsletter-form .col_email {
  margin:0 10px
 }
 .newl_des_1 .signup-newsletter-form {
  padding:2px
 }
 .newl_des_1 .signup-newsletter-form .submit-btn {
  border-radius:50px
 }
 .newl_des_6 .section-title {
  font-size:35px
 }
 .newsletter_se.newl_des_6 .signup-newsletter-form input.input-text {
  min-width:450px
 }
 .type_newsletter .nt_bg_overlay .container {
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -ms-flex-direction:column;
  flex-direction:column;
  -ms-flex-align:center;
  align-items:center
 }
 .type_newsletter .content_pos_1 .container {
  -ms-flex-align:start;
  align-items:flex-start
 }
 .type_newsletter .content_pos_3 .container {
  -ms-flex-align:end;
  align-items:flex-end
 }
 .type_newsletter .nt_bg_overlay {
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -ms-flex-direction:column;
  flex-direction:column;
  -ms-flex-align:center;
  align-items:center
 }
 .type_newsletter .nt_bg_overlay.tl {
  -ms-flex-align:start;
  align-items:flex-start
 }
 .type_newsletter .nt_bg_overlay.tr {
  -ms-flex-align:end;
  align-items:flex-end
 }
 .newsletter_se .signup-newsletter-form .submit-btn,
 .newsletter_se .signup-newsletter-form input.input-text {
  height:50px
 }
 .type_faq .sp-tabs {
  padding-top:0
 }
 .template-cart .additional_checkout_buttons,
 .template-cart .additional_checkout_buttons .shopify-cleanslate>div {
  display:inline-block
 }
 .wrap_title.des_title_13 {
  max-width:83.33333333%;
  margin-left:auto;
  margin-right:auto
 }
 .h3.heading_sea {
  font-size:36px;
  line-height:40px;
  margin-bottom:20px
 }
 .wrap_sea_section .input-group__field.search-form__input-wrapper,
 .wrap_sea_section button.search-form__connected-submit,
 .wrap_sea_section input.search-form__input {
  height:60px
 }
 .our-store {
  padding:60px
 }
 .on_list_view_true .pr_list_item .rte {
  display:block
 }
}
@media (min-width:1024px) {
 #nt_countdow_ppr_txt .nt_countdow_page {
  min-width:400px
 }
 .wrap_title.des_title_13 {
  max-width:66.66666667%
 }
}
.item__position.nt_parallax_true .parallax-inner,
.nt_parallax_true.parallax-inner {
 pointer-events:none;
 position:absolute;
 left:0;
 right:0;
 top:0;
 z-index:0;
 background-position:50% 50%;
 background-size:cover;
 background-repeat:no-repeat
}
@media (min-width:1025px) {
 .h_chan_space_true.header_full_false.h_transparent_true.template-index .ntheader:not(.live_stuck) .ntheader_wrapper {
  max-width:1170px;
  margin-left:auto;
  margin-right:auto;
  box-shadow:none!important
 }
 .h_chan_space_true.header_full_false.h_transparent_true.template-index .ntheader:not(.live_stuck) .ntheader_wrapper:before {
  content:"";
  display:block;
  height:30px
 }
 .h_chan_space_true.header_full_false.h_transparent_true.template-index .ntheader:not(.live_stuck) .sp_header_mid .header__mid>.container {
  padding-right:30px;
  padding-left:30px
 }
 .calc_footer_sticky:not(.template-index) #nt_content {
  padding-bottom:10px
 }
 .des_header_3 .col_group_btns {
  min-width:16.666667%
 }
 .db_lg {
  display:block
 }
 .dib_lg {
  display:inline-block
 }
 .dn_lg {
  display:none
 }
 .fs_lg_14 {
  font-size:14px
 }
 .fs_lg_18 {
  font-size:18px
 }
 .fs_lg_50 {
  font-size:50px
 }
 .fs_lg_20 {
  font-size:20px
 }
 .fs_lg_60 {
  font-size:60px
 }
 .tl_lg {
  text-align:left
 }
 .tr_lg {
  text-align:right
 }
 .tc_lg {
  text-align:center
 }
 .container_cat.cat_wide,
 .wrapper_wide #ntheader .container,
 .wrapper_wide .caption-w-1,
 .wrapper_wide .container {
  max-width:1600px
 }
 .container_cat.cat_full,
 .wrapper_content_full #ntheader .container,
 .wrapper_content_full .container {
  max-width:100%;
  width:100%;
  padding-left:30px;
  padding-right:30px
 }
 .wrapper_boxed #nt_wrapper {
  margin:0 auto;
  max-width:1200px;
  -webkit-box-shadow:0 1px 9px rgba(0,0,0,.08);
  box-shadow:0 1px 9px rgba(0,0,0,.08)
 }
 .wrapper_boxed .type_mega.menu_wid_full>.sub-menu {
  max-width:1200px;
  margin:0 auto
 }
 .wrapper_boxed #ntheader .ntheader_wrapper,
 .wrapper_boxed.h_transparent_true.template-index #ntheader .ntheader_wrapper {
  left:auto;
  right:auto;
  width:100vw;
  max-width:1200px
 }
 .wrapper_boxed .h__banner {
  width:100vw;
  max-width:1200px;
  margin:0 auto
 }
 .row {
  margin-right:-15px;
  margin-left:-15px
 }
 .col,
 .col-1,
 .col-10,
 .col-11,
 .col-12,
 .col-15,
 .col-2,
 .col-3,
 .col-4,
 .col-5,
 .col-6,
 .col-7,
 .col-8,
 .col-9,
 .col-auto,
 .col-lg,
 .col-lg-1,
 .col-lg-10,
 .col-lg-11,
 .col-lg-12,
 .col-lg-15,
 .col-lg-2,
 .col-lg-3,
 .col-lg-4,
 .col-lg-5,
 .col-lg-6,
 .col-lg-7,
 .col-lg-8,
 .col-lg-9,
 .col-lg-auto,
 .col-md,
 .col-md-1,
 .col-md-10,
 .col-md-11,
 .col-md-12,
 .col-md-15,
 .col-md-2,
 .col-md-3,
 .col-md-4,
 .col-md-5,
 .col-md-6,
 .col-md-7,
 .col-md-8,
 .col-md-9,
 .col-md-auto,
 .col-nt-auto,
 .col-sm,
 .col-sm-1,
 .col-sm-10,
 .col-sm-11,
 .col-sm-12,
 .col-sm-2,
 .col-sm-3,
 .col-sm-4,
 .col-sm-5,
 .col-sm-6,
 .col-sm-7,
 .col-sm-8,
 .col-sm-9,
 .col-sm-auto,
 .col-xl,
 .col-xl-1,
 .col-xl-10,
 .col-xl-11,
 .col-xl-12,
 .col-xl-2,
 .col-xl-3,
 .col-xl-4,
 .col-xl-5,
 .col-xl-6,
 .col-xl-7,
 .col-xl-8,
 .col-xl-9,
 .col-xl-auto {
  padding-right:15px;
  padding-left:15px
 }
 .cat_space_0 {
  margin-left:0;
  margin-right:0
 }
 .cat_space_0 .cat_space_item {
  padding-left:0;
  padding-right:0;
  margin-bottom:0
 }
 .cat_space_2 {
  margin-left:-1px;
  margin-right:-1px
 }
 .cat_space_2 .cat_space_item {
  padding-left:1px;
  padding-right:1px;
  margin-bottom:2px
 }
 .cat_space_6 {
  margin-left:-3px;
  margin-right:-3px
 }
 .cat_space_6 .cat_space_item {
  padding-left:3px;
  padding-right:3px;
  margin-bottom:6px
 }
 .cat_space_10 {
  margin-left:-5px;
  margin-right:-5px
 }
 .cat_space_10 .cat_space_item {
  padding-left:5px;
  padding-right:5px;
  margin-bottom:10px
 }
 .header_full_true .h__banner>.container,
 .header_full_true .h__top>.container,
 .header_full_true .header__mid>.container {
  max-width:100%
 }
 .nt_action>a,
 .nt_action>div {
  padding:0 8px
 }
 .h_icon_pegk .nt_action>a,
 .h_icon_pegk .nt_action>div {
  padding:0 6px
 }
 .des_header_7 #nt_wrapper,
 .des_header_7 .sticky_atc_wrap,
 .des_header_7:not(.h_calc_ready) #shopify-section-header_banner {
  padding-left:280px
 }
 .des_header_3.header_full_true .branding>a {
  margin-left:10px
 }
 .header_7 {
  position:fixed;
  width:280px;
  margin-left:-280px;
  z-index:1000;
  top:0;
  bottom:0;
  overflow-y:auto;
  background-color:#fff;
  border-right:2px solid rgba(150,150,150,.15)
 }
 .header_7 .header__mid {
  padding-top:60px;
  padding-bottom:60px
 }
 .nt_packery .pr_grid_item .product-info {
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  padding:10px 15px;
  opacity:0
 }
 .cl_h7_btns {
  margin-top:20px;
  text-align:center
 }
 .h_icon_iccl .cl_h7_btns {
  margin-top:25px
 }
 .cat_space_20 {
  margin-left:-10px;
  margin-right:-10px
 }
 .cat_space_20 .cat_space_item {
  padding-left:10px;
  padding-right:10px;
  margin-bottom:20px
 }
 .cat_space_30 {
  margin-left:-15px;
  margin-right:-15px
 }
 .cat_space_30 .cat_space_item {
  padding-left:15px;
  padding-right:15px;
  margin-bottom:30px
 }
 .header_8 a.icon_search,
 body:not(.des_header_1):not(.des_header_6):not(.des_header_10) #nt_menu_canvas.lazyloadt4s {
  display:none
 }
 .type_slideshow .container .flickity-prev-next-button.previous {
  left:-30px
 }
 .type_slideshow .container .flickity-prev-next-button.next {
  right:-30px
 }
 .nt_full .flickity-prev-next-button.previous {
  left:30px
 }
 .nt_full .flickity-prev-next-button.next {
  right:30px
 }
 .thumb_left .col_nav,
 .thumb_right .col_nav {
  max-width:100px;
  width:100%
 }
 .thumb_left .col_nav.nav_large,
 .thumb_right .col_nav.nav_large {
  max-width:170px
 }
 .thumb_left .col_nav.nav_small,
 .thumb_right .col_nav.nav_small {
  max-width:70px
 }
 .cat_hidden_false .cat_sidebar,
 .des_header_10 .nt_action>a.icon_search,
 .hidden_sortby_false.widget,
 .no_hidden_sidebar,
 .pop_default .btn_filter[data-opennt="#shopify-section-nt_filter"],
 .pop_default .btn_filter[data-opennt="#shopify-section-nt_filter2"],
 .section_sidebar_shop>.h3,
 .section_sidebar_shop>h3 {
  display:none
 }
 #cat_shopify,
 #cat_shopify ul li li,
 .cat_hidden_false .cat_sortby,
 .hidden_false.sidebar .section_sidebar_shop,
 .pop_default .btn_filter {
  display:block
 }
 .hidden_sortby_false.widget+.widget {
  margin-top:0!important
 }
 #cat_shopify ul li {
  line-height:25px;
  list-style:none;
  display:inline-block;
  margin:0;
  position:relative
 }
 #cat_shopify ul li a {
  padding:10px 15px
 }
 .cat_view .dev_tablet {
  display:none
 }
 .cat_view .dev_desktop {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex
 }
 .cat_toolbar {
  margin-bottom:10px;
  margin-top:40px
 }
 .cat-shop .sub-menu {
  visibility:hidden;
  opacity:0;
  pointer-events:none;
  position:absolute
 }
 .space_20.sidebar_nt {
  margin-top:20px
 }
 .space_30.sidebar_nt {
  margin-top:30px
 }
 .space_40.sidebar_nt {
  margin-top:40px
 }
 .space_50.sidebar_nt {
  margin-top:50px
 }
 .variations_button>.wrap {
  -webkit-flex-wrap:nowrap;
  -ms-flex-wrap:nowrap;
  flex-wrap:nowrap
 }
 .thumb_left .col_thumb {
  padding-left:5px;
  -ms-flex-order:12;
  order:12
 }
 .thumb_left .col_nav {
  padding-right:5px;
  -ms-flex-order:1;
  order:1
 }
 .thumb_right .col_thumb {
  padding-right:5px
 }
 .thumb_right .col_nav {
  padding-left:5px
 }
 .thumb_left .p-nav,
 .thumb_right .p-nav {
  margin-top:0;
  min-height:100%;
  overflow:hidden
 }
 .thumb_left .p-nav .n-item,
 .thumb_right .p-nav .n-item {
  width:100%;
  max-width:100%;
  margin-bottom:10px;
  cursor:pointer;
  position:relative!important;
  left:0!important;
  right:0!important
 }
 .thumb_left .p-nav .n-item:last-child,
 .thumb_right .p-nav .n-item:last-child {
  margin-bottom:50px
 }
 .thumb_left .p-nav .flickity-slider,
 .thumb_left .p-nav .flickity-viewport,
 .thumb_right .p-nav .flickity-slider,
 .thumb_right .p-nav .flickity-viewport {
  -ms-transform:none!important;
  transform:none!important;
  overflow:visible!important;
  height:auto!important
 }
 .single_add_to_cart_button {
  margin-top:0;
  -ms-flex-order:2;
  order:2
 }
 .PR_no_pick_true .shopify-payment-button {
  display:none
 }
 button.single_add_to_cart_button.disabled {
  opacity:.5
 }
 .atc-slide:not(.btn_full_true) .single_add_to_cart_button,
 .atc-slide:not(.btn_full_true) .variations_button {
  width:auto;
  min-width:160px;
  max-width:100%
 }
 .footer__bot .col-lg-6.col_2,
 .footer__bot .col-lg.col_2,
 .footer__bot .col-lg.col_3 {
  margin-top:0
 }
 .footer__bot .col-lg-6.col_1,
 .footer__bot .col-lg.col_1 {
  text-align:left
 }
 .footer__bot .col-lg-6.col_2,
 .footer__bot .col-lg.col_3 {
  text-align:right
 }
 .footer__bot .col-lg.col_2 {
  -ms-flex:0 0 auto;
  flex:0 0 auto;
  width:auto;
  max-width:100%
 }
 .use_border_true .nt_shipping {
  padding:20px
 }
 .use_border_true .nt_shipping:after {
  left:15px;
  right:15px
 }
 .des_style_1 .sp-tab-content {
  display:block!important
 }
 .label_style_rounded .on_list_view_true .pr_list_item .nt_label {
  height:50px;
  width:50px;
  font-size:13px
 }
}
@media screen and (min-width:1140px) {
 .hero_video_se iframe {
  width:100%;
  height:300%;
  left:auto;
  top:-100%
 }
 #nt_content .shopify-challenge__container {
  margin:250px 0
 }
}
@media (max-width:1024px) {
 .des_btns_prmb_2 .pr_grid_item .product-image.lazyloadt4s:after,
 .des_btns_prmb_2 .pr_grid_item .product-image.lazyloadt4sing:after {
  content:'';
  display:block!important;
  background-color:#f5f5f5;
  height:40px
 }
 .prs_bordered_grid_true .products.nt_products_holder .nt_pr {
  padding-top:7.5px;
  padding-bottom:7.5px
 }
 .icon_cart .ch_tt_price.ttip_nt {
  display:none
 }
 .type_faq .sp-tabs {
  padding-top:0
 }
 .type_faq .sp-tabs+.sp-tabs {
  margin-top:40px
 }
 .dn_true_1024,
 .header_2 .nt_menu>li:not(.branding),
 .nt_action .push_side.dn,
 .nt_action .push_side.dnt4 {
  display:none
 }
 .nt_menu>li.menu-item.branding>a {
  padding-right:0;
  padding-left:0
 }
 .sidebar.sidebar_nt {
  -ms-flex-order:0;
  order:0
 }
 .cat_space_10,
 .cat_space_20,
 .cat_space_30,
 .cat_space_6 {
  margin-left:-5px;
  margin-right:-5px
 }
 .cat_size_4.cat_space_30 .cat_grid_item_1 .cat_grid_item__overlay {
  padding-top:111%
 }
 .cat_size_4.cat_space_30 .cat_grid_item_4 .cat_grid_item__overlay {
  padding-top:228%
 }
 .cat_size_4.cat_space_10 .cat_grid_item_1 .cat_grid_item__overlay {
  padding-top:108.01754385964912%
 }
 .cat_size_4.cat_space_6 .cat_grid_item_1 .cat_grid_item__overlay {
  padding-top:107.615789%
 }
 .des_style_1.des_mb_2.sp-tab {
  padding:0
 }
 .des_style_1.des_mb_2.sp-tab:not(:last-child) {
  margin-bottom:10px
 }
 .des_style_1.des_mb_2.sp-tab:not(.clicked_accordion)>.sp-tab-content,
 .des_style_1.des_mb_2.ul_tabs {
  display:none
 }
 .des_style_1.des_mb_2.sp-tab,
 .des_style_1.des_mb_2.sp-tab.active:not(.clicked_accordion)>.sp-tab-content,
 .des_style_1.des_mb_2.sp-tab>.heading {
  display:block!important
 }
 .des_style_1.des_mb_2.sp-tab>.sp-tab-content {
  padding:20px;
  border:1px solid #f5f5f5;
  border-top:0
 }
 .des_style_1.des_mb_2 .sp-tab-content [data-kl-full-width],
 .des_style_2 .sp-tab-content [data-kl-full-width] {
  left:auto;
  padding:0;
  width:auto
 }
 .type_newsletter2 .col-new {
  max-width:600px;
  margin-top:20px
 }
}
@media (max-width:768px) {
 .tab_name {
  margin-right:0
 }
 .tab_se_header.des_tab_7,
 .tab_se_header.des_tab_8 {
  -ms-flex-direction:column;
  flex-direction:column;
  -ms-flex-pack:center;
  justify-content:center;
  border-bottom:0
 }
 .tab_cat_title.des_tab_7,
 .tab_cat_title.des_tab_8 {
  flex:1 1 auto;
  padding-top:10px;
  max-width:100%;
  width:100%;
  border-top:2px solid #eaeaea;
  text-align:center
 }
 .sp-single .row.product.mt__40 {
  margin-top:15px
 }
}
@media (max-width:767px) {
 .featured_product_se .entry-summary {
  margin-top:30px
 }
 .salse_pp_mb_false {
  display:none
 }
 .cat_size_4.cat_space_30 .cat_grid_item_1 .cat_grid_item__overlay {
  padding-top:108%
 }
 #trust_seal_ppr img.img_tr_s1,
 .trust_seal_se img.img_tr_s1 {
  max-width:var(--max-w-img);
  width:100%!important
 }
 .txt_col_img {
  margin-top:30px
 }
 .hide_m767_true {
  display:none
 }
 .products:not(.articles) .flickity-prev-next-button {
  top:30%
 }
 .products:not(.articles):not(.btn_owl_3) .flickity-prev-next-button {
  transform:none
 }
 .flickity-prev-next-button.previous {
  left:8px
 }
 .flickity-prev-next-button.next {
  right:8px
 }
 .type_hero_video .lh__1,
 .type_image_text_overlay .lh__1,
 .type_slideshow .lh__1 {
  line-height:1.22857143
 }
 .footer_collapse_true .row>.mb__50 {
  margin-bottom:30px
 }
 .footer_collapse_true .widget-title {
  position:relative;
  margin-bottom:0;
  padding-bottom:10px
 }
 .footer_collapse_true .footer_opened.widget_logo .widget-title,
 .footer_collapse_true .widget_instagram .widget-title {
  margin-bottom:10px
 }
 .footer_collapse_true .widget-title+.widget_footer {
  display:none
 }
 .type_newsletter2 .class_ip {
  text-align:center
 }
 .newl_des_5 .signup-newsletter-form,
 .newl_des_5 .signup-newsletter-form input.input-text,
 .newl_des_5.newsletter_se .signup-newsletter-form .submit-btn {
  border-radius:0
 }
 .newl_des_4 .mc4wp-form-fields {
  padding:0 30px
 }
 .newl_des_4 .signup-newsletter-form input.input-text {
  margin-bottom:10px
 }
}
.order-table dd,
.order-table dl {
 margin:0
}
.order-table tbody tr:last-child td,
.order-table tbody tr:last-child th {
 border-bottom:1px solid #69727b
}
@media (max-width:749px) {
 .small--hide {
  display:none!important
 }
 .order-table {
  border:0
 }
 .order-table thead {
  display:none
 }
 .order-table tbody tr:last-child td,
 .order-table tbody tr:last-child th {
  border-bottom:0
 }
 .order-table td,
 .order-table th {
  float:left;
  clear:left;
  width:100%;
  text-align:right;
  padding:.5rem 0;
  border:0;
  margin:0
 }
 .order-table td::before,
 .order-table th::before {
  content:attr(data-label);
  float:left;
  text-align:left;
  padding-right:2em;
  max-width:80%
 }
 .order-table tbody tr th:first-child {
  padding-top:1.25em
 }
 .order-table tbody tr td:last-child {
  padding-bottom:1.25em
 }
 .order-table tbody td::before,
 .order-table tbody th::before {
  font-weight:600
 }
 .order-table tfoot tr:first-child td,
 .order-table tfoot tr:first-child th {
  padding-top:1.25em;
  border-top:1px solid #e8e9eb
 }
 .order-table tfoot tr:nth-last-child(2) td,
 .order-table tfoot tr:nth-last-child(2) th {
  padding-bottom:1.25em
 }
 .order-table tfoot tr:last-child td,
 .order-table tfoot tr:last-child th {
  border-top:1px solid #e8e9eb;
  padding-top:1.25em;
  padding-bottom:1.25em
 }
 table.responsive-table {
  border:1px solid #ddd
 }
 table.responsive-table tbody>tr {
  border-bottom:1px solid #ddd
 }
 .responsive-table thead {
  display:none
 }
 .responsive-table td,
 .responsive-table th {
  float:left;
  clear:left;
  width:100%;
  text-align:right;
  padding:15px;
  border:0;
  margin:0
 }
 .responsive-table td::before,
 .responsive-table th::before {
  content:attr(data-label);
  float:left;
  text-align:center;
  font-size:12px;
  padding-right:10px;
  font-weight:400
 }
 .responsive-table__row+.responsive-table__row,
 tfoot>.responsive-table__row:first-child {
  position:relative;
  margin-top:10px;
  padding-top:55px
 }
 .responsive-table__row+.responsive-table__row::after,
 tfoot>.responsive-table__row:first-child::after {
  content:'';
  display:block;
  position:absolute;
  top:0;
  left:27.5px;
  right:27.5px;
  border-bottom:1px solid #e8e9eb
 }
 .table-responsive {
  width:100%;
  margin-bottom:15px;
  overflow-y:hidden;
  -ms-overflow-style:-ms-autohiding-scrollbar;
  border:1px solid #ddd;
  -webkit-overflow-scrolling:touch
 }
 .table-responsive table {
  border-width:0;
  margin:0
 }
}
.dark_mode_true,
body.dark_mode_true {
 color:rgba(255,255,255,.8)
}
.dark_mode_true #content_quickview,
.dark_mode_true #nt_content>.shopify-section,
.dark_mode_true #nt_wrapper,
.dark_mode_true #shopify-section-cart_widget .mini_cart_tool,
.dark_mode_true #wrap_des_pr,
.dark_mode_true .cat_design_1 .cat_grid_item__wrapper,
.dark_mode_true .cat_design_4 .cat_grid_item__wrapper,
.dark_mode_true .cat_design_6 .cat_grid_item__wrapper,
.dark_mode_true .cat_design_7 .cat_grid_item__wrapper,
.dark_mode_true .frm_cart_page:before,
.dark_mode_true .hover_button .nt_add_qv,
.dark_mode_true .hover_button .pr_atc,
.dark_mode_true .mini_cart_content .mini_cart_item:hover,
.dark_mode_true .mini_cart_dis,
.dark_mode_true .mini_cart_gift,
.dark_mode_true .mini_cart_note,
.dark_mode_true .mini_cart_ship,
.dark_mode_true .nt_fk_canvas,
.dark_mode_true .nt_mini_cart:before,
.dark_mode_true .nt_section,
.dark_mode_true .shipping_calc_page.shipping_calculator h3,
.dark_mode_true .sp-content-help {
 background-color:#1a1a1a!important
}
.dark_mode_true .bgw,
.dark_mode_true .drift-zoom-pane,
.dark_mode_true .mfp-content .section_nt_filter .widget,
.dark_mode_true .pswp__share-tooltip,
.dark_mode_true .pswp__ui--fit .pswp__caption,
.dark_mode_true .pswp_tp_light .pswp__bg,
.dark_mode_true .pswp_tp_light .pswp__button--arrow--left:before,
.dark_mode_true .pswp_tp_light .pswp__button--arrow--right:before,
.dark_mode_true .pswp_tp_light .pswp__ui--fit .pswp__top-bar,
.dark_mode_true .style_color.des_color_1 .is-color .swatch_pr_item {
 background-color:#1a1a1a
}
.dark_mode_true a.pswp__share--facebook:before {
 border-bottom-color:#1a1a1a
}
.dark_mode_true .style_color .is-color .swatch_pr_item.is-selected,
.dark_mode_true .style_color .is-color .swatch_pr_item.is-selected-nt {
 background-color:rgba(255,255,255,.45)
}
.has_fk_canvas .dark_mode_true .nt_fk_canvas,
.has_ntcanvas .dark_mode_true .mfp-content,
.has_ntcanvas .dark_mode_true .mfp-inline-holder .mfp-content {
 background-color:#1a1a1a
}
.dark_mode_true #content_quickview button.mfp-close,
.dark_mode_true #nt_backtop span i,
.dark_mode_true #nt_backtop.des_bt2 span i,
.dark_mode_true .blockid_price .price_slider_amount .price_label>span,
.dark_mode_true .btn_filter,
.dark_mode_true .btn_sidebar,
.dark_mode_true .cart_page_section .cart_header,
.dark_mode_true .cat_sortby>a,
.dark_mode_true .cat_view a.active,
.dark_mode_true .cat_view a:hover,
.dark_mode_true .cat_view.on_list_view_true a.view_list,
.dark_mode_true .cd,
.dark_mode_true .compare_field,
.dark_mode_true .compare_value:before,
.dark_mode_true .f__mont,
.dark_mode_true .f_heading,
.dark_mode_true .grouped-pr-list-item__price ins,
.dark_mode_true .h3,
.dark_mode_true .header-text>a:not(.cg),
.dark_mode_true .header_picker,
.dark_mode_true .hover_button .nt_add_qv,
.dark_mode_true .hover_button .nt_add_qv i,
.dark_mode_true .hover_button .pr_atc,
.dark_mode_true .hover_button .pr_atc i,
.dark_mode_true .kl_fbt_price,
.dark_mode_true .mb_nav_title,
.dark_mode_true .menu_infos_title,
.dark_mode_true .mini_cart_footer .button,
.dark_mode_true .mini_cart_footer .total,
.dark_mode_true .mini_cart_note label,
.dark_mode_true .nt_lt_fake ul>li,
.dark_mode_true .order-discount,
.dark_mode_true .page-numbers li a:hover,
.dark_mode_true .page-numbers.current,
.dark_mode_true .price ins,
.dark_mode_true .price_range ins,
.dark_mode_true .quantity input.input-text[type=number],
.dark_mode_true .section_nt_filter .nt_filter_block a,
.dark_mode_true .section_nt_filter .nt_filter_block.nt_filter_color .active a,
.dark_mode_true .section_nt_filter .nt_filter_block.nt_filter_color a:hover,
.dark_mode_true .shipping_calculator h3,
.dark_mode_true .sticky_atc_info>h4,
.dark_mode_true .sticky_atc_price,
.dark_mode_true .store-availability-information__title strong,
.dark_mode_true .swatch.is-label .swatch_pr_item:not(.is-selected):hover .swatch__value_pr,
.dark_mode_true .tab_cat_title.des_tab_10>li>a,
.dark_mode_true .tab_cat_title.des_tab_1>li>a.tt_active,
.dark_mode_true .tab_cat_title.des_tab_1>li>a:hover,
.dark_mode_true .tab_cat_title.des_tab_2>li>a.tt_active,
.dark_mode_true .tab_cat_title.des_tab_2>li>a:hover,
.dark_mode_true .tab_cat_title.des_tab_3>li>a.tt_active,
.dark_mode_true .tab_cat_title.des_tab_3>li>a:hover,
.dark_mode_true .tab_cat_title.des_tab_4>li>a,
.dark_mode_true .tab_cat_title.des_tab_5>li>a,
.dark_mode_true .tab_cat_title.des_tab_6>li>a,
.dark_mode_true .tab_cat_title.des_tab_7>li>a,
.dark_mode_true .tab_cat_title.des_tab_8>li>a,
.dark_mode_true .tab_cat_title.des_tab_9>li>a,
.dark_mode_true .type_shipping .content h3,
.dark_mode_true .ul_tabs li a:hover,
.dark_mode_true .ul_tabs li.active a,
.dark_mode_true .widget .post_list_widget .article-title,
.dark_mode_true .widget .product_list_widget .product-title,
.dark_mode_true .widget .product_list_widget ins,
.dark_mode_true .wrap_sortby a,
.dark_mode_true a,
.dark_mode_true a.button,
.dark_mode_true a.compare_remove,
.dark_mode_true a.pp_cookies_more_btn,
.dark_mode_true button,
.dark_mode_true h1,
.dark_mode_true h1.product_title.entry-title,
.dark_mode_true h2,
.dark_mode_true h3,
.dark_mode_true h3.product-title,
.dark_mode_true h3.product-title>a,
.dark_mode_true h4,
.dark_mode_true h5,
.dark_mode_true h6,
.dark_mode_true input.search_header__input:not([type=submit]):not([type=checkbox]),
.dark_mode_true input:not([type=submit]):not([type=checkbox]).nt_cat_title,
.dark_mode_true input[type=submit] {
 color:#fff
}
.dark_mode_true .title_11:after,
.dark_mode_true .title_11:before,
.dark_mode_true .title_12:after,
.dark_mode_true .title_12:before,
.dark_mode_true .title_2:after,
.dark_mode_true .title_2:before,
.dark_mode_true .widget h5.widget-title:after {
 background-color:rgba(255,255,255,.8)
}
.dark_mode_true .cb,
.dark_mode_true .gecko_toolbar_item>a,
.dark_mode_true .nt_mb_menu .sub-menu li>a,
.dark_mode_true .nt_mb_menu>li>a,
.dark_mode_true .type_shipping .content,
.dark_mode_true .type_shipping .icon {
 color:rgba(255,255,255,.8)
}
.dark_mode_true #CartSpecialInstructions,
.dark_mode_true #content_quickview button.mfp-close:hover,
.dark_mode_true .blockid_price .price_slider_amount .price_label,
.dark_mode_true .grouped-pr-list-item__price,
.dark_mode_true .kl_fbt_wrap del,
.dark_mode_true .nt_menu div.menu-item>a,
.dark_mode_true .nt_menu ul li a,
.dark_mode_true .page-numbers li,
.dark_mode_true .page-numbers li a,
.dark_mode_true .post-content,
.dark_mode_true .pr_short_des,
.dark_mode_true .price,
.dark_mode_true .price del,
.dark_mode_true .price_range,
.dark_mode_true .price_range del,
.dark_mode_true .sticky_atc_price del,
.dark_mode_true .store-availability-information__button:focus,
.dark_mode_true .store-availability-information__button:hover,
.dark_mode_true .sub-title,
.dark_mode_true .widget .product_list_widget del,
.dark_mode_true a:focus,
.dark_mode_true a:hover {
 color:rgba(255,255,255,.6)
}
.dark_mode_true .cat_view a,
.dark_mode_true .dev_view_cat a.view_list {
 color:#878787
}
.dark_mode_true #nt_backtop.des_bt2 span,
.dark_mode_true .tab_cat_title.des_tab_1>li>a.tt_active,
.dark_mode_true .tab_cat_title.des_tab_1>li>a:hover,
.dark_mode_true .tab_cat_title.des_tab_2>li>a.tt_active,
.dark_mode_true .tab_cat_title.des_tab_2>li>a:hover,
.dark_mode_true .tab_cat_title.des_tab_3>li>a.tt_active,
.dark_mode_true .tab_cat_title.des_tab_3>li>a:hover,
.dark_mode_true .ul_tabs li.active a,
.dark_mode_true .ul_tabs li.tab_title_block.active a {
 border-color:#fff
}
.dark_mode_true .nt_menu .sub-menu .sub-column-item>a,
.dark_mode_true .result_clear>.clear_filter:not(:last-child) {
 border-color:rgba(255,255,255,.35)
}
.dark_mode_true .cart_page_section .cart_item,
.dark_mode_true .cart_page_section .cart_item:last-child,
.dark_mode_true .cat-shop,
.dark_mode_true .cat_sortby>a,
.dark_mode_true .des_style_1.des_mb_2.sp-tab>.sp-tab-content,
.dark_mode_true .filter_area .section_nt_filter,
.dark_mode_true .header_picker,
.dark_mode_true .js_prs_search>:not(:last-child),
.dark_mode_true .mfp-content .section_nt_filter .widget,
.dark_mode_true .nt-pagination,
.dark_mode_true .nt_sortby .h3,
.dark_mode_true .nt_sortby h3,
.dark_mode_true .on_list_view_true .grid-sizer+.pr_list_item .product-inner,
.dark_mode_true .on_list_view_true .pr_list_item .product-inner,
.dark_mode_true .on_list_view_true .pr_list_item:first-child .product-inner,
.dark_mode_true .shipping_calc_page.shipping_calculator,
.dark_mode_true .spr-container,
.dark_mode_true .store-availability-list__item:not(:first-child)::before,
.dark_mode_true .style_color .is-color .swatch_pr_item,
.dark_mode_true .tag-comment,
.dark_mode_true .variations.style__circle .swatch.is-label .swatch_pr_item,
.dark_mode_true .variations.style__radio.full .swatch:not(.is-color) li,
.dark_mode_true .variations_button .nt_add_cp,
.dark_mode_true .variations_button .nt_add_w,
.dark_mode_true .variations_form .quantity,
.dark_mode_true hr,
.dark_mode_true input.search_header__input:not([type=submit]):not([type=checkbox]),
.dark_mode_true input:not([type=submit]):not([type=checkbox]).nt_cat_title,
.dark_mode_true table,
.dark_mode_true td,
.dark_mode_true th,
.dark_mode_true.prs_bordered_grid_2 .products .nt_pr .product-image,
.dark_mode_true.prs_bordered_grid_3 .products .nt_pr {
 border-color:rgba(255,255,255,.15)
}
.dark_mode_true.prs_bordered_grid_3 .products .nt_pr {
 box-shadow:0 -1px rgba(255,255,255,.15),-1px 0 rgba(255,255,255,.15)
}
.dark_mode_true #nt_backtop span,
.dark_mode_true .bgbl,
.dark_mode_true .cat_toolbar.cat_stuck_prepared.cat_stuck,
.dark_mode_true .frm_cart_page .mini_cart_actions .quantity,
.dark_mode_true .gecko_toolbar,
.dark_mode_true .h__top .bgbl,
.dark_mode_true .kalles_toolbar,
.dark_mode_true .mini_cart_actions .quantity,
.dark_mode_true .mini_cart_footer .button,
.dark_mode_true .mini_cart_tool>div:hover,
.dark_mode_true .nt_bg_lz.lazyloadt4s,
.dark_mode_true .nt_bg_lz.lazyloadt4sing,
.dark_mode_true .nt_fk_lz,
.dark_mode_true .nt_load.lazyloadt4sed,
.dark_mode_true .nt_lt_fake ul,
.dark_mode_true .nt_menu>li .sub-menu,
.dark_mode_true .nt_sortby,
.dark_mode_true .popup_slpr_wrap,
.dark_mode_true .quantity input.input-text[type=number],
.dark_mode_true .tab-heading .nav_link_icon,
.dark_mode_true .variations_button .nt_add_cp,
.dark_mode_true .variations_button .nt_add_w,
.dark_mode_true .variations_form .quantity,
.dark_mode_true .wrap_sortby {
 background-color:#000
}
.dark_mode_true .mini_cart_footer a.button.btn-cart {
 background-color:#000;
 color:#fff
}
.dark_mode_true svg.ic_triangle_svg path {
 fill:#000
}
.dark_mode_true .btns_cart_1 .mini_cart_tool>div {
 box-shadow:9px 5px 10px #262729,-5px -5px 10px #2c3131;
 color:#fff
}
.dark_mode_true input:not([type=submit]):not([type=checkbox]),
.dark_mode_true select,
.dark_mode_true textarea {
 border-color:rgba(255,255,255,.15);
 background-color:#1a1a1a;
 color:rgba(255,255,255,.8)
}
.dark_mode_true .form-message__title {
 color:#651818
}
.dark_mode_true a.button,
.dark_mode_true button,
.dark_mode_true input[type=submit] {
 border:1px solid rgba(255,255,255,.15);
 background-color:#000
}
.dark_mode_true #content_quickview button.mfp-close,
.dark_mode_true .pswp_tp_light .pswp__button--arrow--left,
.dark_mode_true .pswp_tp_light .pswp__button--arrow--right,
.dark_mode_true .search_header__submit,
.dark_mode_true .store-availability-information__button {
 border:none
}
.dark_mode_true .nt_menu>li .sub-menu {
 box-shadow:0 0 3px rgba(0,0,0,.15)
}
.dark_mode_true .cat_toolbar.cat_stuck_prepared,
.dark_mode_true .flickity-prev-next-button,
.dark_mode_true .mini_cart_ship input.button.btn_back,
.dark_mode_true .newl_des_1 .signup-newsletter-form,
.dark_mode_true .on_list_view_true .pr_list_item .product-info__btns>a.nt_add_qv,
.dark_mode_true .search_header__submit,
.dark_mode_true .variations.style__radio .swatch:not(.is-color) li.is-selected,
.dark_mode_true .variations.style__radio .swatch:not(.is-color) li.is-selected-nt,
.dark_mode_true .variations.style__radio .swatch:not(.is-color) li:hover,
.dark_mode_true input.button.btn_back.btn_back2 {
 background-color:transparent
}
.dark_mode_true .flickity-prev-next-button {
 border:2px solid #fff
}
.dark_mode_true a.compare_remove:after,
.dark_mode_true a.compare_remove:before {
 background-color:#fff
}
.dark_mode_true .nt_menu div.menu-item.is_hover>a,
.dark_mode_true .nt_menu div.menu-item>a:hover,
.dark_mode_true .nt_menu ul li a:hover,
.dark_mode_true .nt_menu ul li.is_hover a {
 color:rgba(255,255,255,.45)
}
@media (min-width:1025px) {
 .dark_mode_true .css_ntbar::-webkit-scrollbar-track,
 .dark_mode_true .css_scrollbar .fixcl-scroll ::-webkit-scrollbar-track,
 .dark_mode_true .css_scrollbar .gecko-scroll-quick ::-webkit-scrollbar-track,
 .dark_mode_true .mfp-content .css_ntbar .css_ntbar::-webkit-scrollbar-track {
  background-color:rgba(255,255,255,.15)
 }
 .dark_mode_true .css_ntbar::-webkit-scrollbar-thumb,
 .dark_mode_true .css_scrollbar .fixcl-scroll ::-webkit-scrollbar-thumb,
 .dark_mode_true .css_scrollbar .gecko-scroll-quick ::-webkit-scrollbar-thumb,
 .dark_mode_true .mfp-content .css_ntbar .css_ntbar::-webkit-scrollbar-thumb {
  background-color:rgba(255,255,255,.25)
 }
 .dark_mode_true .col_nav.t4_show .btn_pnav_next,
 .dark_mode_true .col_nav.t4_show .btn_pnav_prev {
  color:#fff
 }
 .dark_mode_true .type_banner_collection .container .cat_banner_prs.show_border_true {
  border-color:rgba(255,255,255,.15)
 }
 .dark_mode_true button.btn_pnav_next,
 .dark_mode_true button.btn_pnav_prev {
  background-color:rgba(0,0,0,.5)
 }
 .dark_mode_true button.btn_pnav_next:hover,
 .dark_mode_true button.btn_pnav_prev:hover {
  background-color:rgba(0,0,0,.8)
 }
}
@media (min-width:768px) {
 .dark_mode_true .summary-inner.gecko-scroll-quick:before {
  background:-webkit-gradient(linear,left top,left bottom,color-stop(94%,rgba(255,255,255,0)),to(#fff));
  background:-webkit-linear-gradient(top,rgba(255,255,255,0) 94%,#fff 100%);
  background:linear-gradient(to bottom,rgba(255,255,255,0) 94%,#fff 100%);
  background:-webkit-gradient(linear,left top,left bottom,color-stop(94%,rgba(26,26,26,0)),to(#1a1a1a));
  background:-webkit-linear-gradient(top,rgba(26,26,26,0) 94%,#1a1a1a 100%);
  background:linear-gradient(to bottom,rgba(26,26,26,0) 94%,#1a1a1a 100%)
 }
}
@media (max-width:1024px) {
 .dark_mode_true .pr_grid_item .product-image .hover_button,
 .dark_mode_true .pr_grid_item .product-image:hover .hover_button {
  background-color:#1a1a1a
 }
 .cltouchevents .dark_mode_true ul.cat_lv_0 {
  border-color:rgba(255,255,255,.15)
 }
}
.no-js .p-thumb .nt_bg_lz {
 padding-top:0!important
}
.no-js .nt_bg_lz.lazyloadt4s:before {
 display:none
}
#ssw-topauth .ssw-custom-login {
 font-size:22px
}
#menu_mb_ul .ssw-dropdown-menu {
 position:relative;
 width:100%;
 background:0 0;
 border:none;
 box-shadow:none
}
#menu_mb_ul .ssw-dropdown-backdrop {
 display:none!important
}
.ssw-link-fave-menu {
 position:relative
}
.ssw-link-fave-menu .ssw-counter-fave-menu {
 position:absolute;
 top:-6px;
 right:4px;
 width:15px;
 height:15px;
 display:flex!important;
 align-items:center;
 justify-content:center;
 color:#fff;
 font-size:11px;
 background:#000;
 border-radius:30px
}
#widget-fave-html {
 width:100%;
 order:4;
 margin-left:20px
}
#widget-fave-html .ssw-fave-btn {
 width:100%;
 line-height:38px
}
#widget-fave-html .ssw-fave-btn-content {
 width:100%;
 font-weight:600;
 text-transform:uppercase;
 color:#cc9e6c;
 border-color:#cc9e6c;
 -webkit-border-radius:30px;
 -moz-border-radius:30px;
 border-radius:30px
}
#widget-fave-html .ssw-icon-heart,
#widget-fave-html .ssw-icon-heart-o {
 color:#cc9e6c
}
#widget-fave-html .ssw-fave-btn-counter,
#widget-fave-html .ssw-fave-btn-counter:before {
 color:#cc9e6c;
 border-color:#cc9e6c;
 -webkit-border-radius:30px;
 -moz-border-radius:30px;
 border-radius:30px
}
#ssw-widget-recommends-html .ssw-active .ssw-product-comments-title,
#ssw-widget-recommends-html .ssw-active .ssw-product-reviews-title {
 background-color:inherit
}
#ssw-widget-recommends-html .btn.button.ssw-question-add-review,
#ssw-widget-recommends-html .ssw-ask-question-link.btn.button,
#ssw-widget-recommends-html .ssw-control-group.ssw-text-right .btn.button {
 color:#fff;
 background-color:#cc9e6c;
 border-color:#cc9e6c
}
.template-index .ssw-html-widget.ssw-instagram-widget>div:first-child,
.template-index .ssw-instagram-grid-title {
 display:flex;
 align-items:center;
 justify-content:center;
 margin:10px 0
}
.template-index .ssw-html-widget.ssw-instagram-widget .ssw-instagram-slide-title {
 font-family:Poppins;
 font-size:24px;
 font-weight:600;
 text-transform:uppercase;
 color:#222;
 letter-spacing:0;
 margin:0 10px
}
.template-index .ssw-html-widget.ssw-instagram-widget>div:first-child:after,
.template-index .ssw-html-widget.ssw-instagram-widget>div:first-child:before {
 content:"";
 display:inline-block;
 width:30px;
 height:2px;
 background:#222
}
#content_quickview .nt_add_w.ts__03.pa .ssw-faveiticon {
 width:100%;
 height:40px
}
#content_quickview .nt_add_w.ts__03.pa .ssw-faveiticon .ssw-icon-heart,
#content_quickview .nt_add_w.ts__03.pa .ssw-faveiticon .ssw-icon-heart-o {
 color:#222
}
#content_quickview .nt_add_w:hover .ssw-icon-heart,
#content_quickview .nt_add_w:hover .ssw-icon-heart-o {
 color:#cc9e6c!important
}
#content_quickview .nt_add_w.ts__03.pa .ssw-faveiticon .faves-count {
 display:none
}
#ssw-fave-page-side-bar {
 margin-top:20px
}
.nt_add_w .ssw-faveiticon {
 position:static
}
.variations_button .ssw-faveiticon>.faves-count {
 position:absolute;
 right:-22px;
 top:-4px
}
@media all and (min-width:1025px) {
 #widget-fave-html .ssw-fave-btn-content {
  font-size:11px
 }
}
@media all and (max-width:1024px) {
 #widget-fave-html {
  margin-left:0;
  margin-top:20px
 }
}
@media all and (max-width:767px) {
 .nt_action #ssw-topauth,
 .nt_action .ssw-link-fave-menu {
  display:none
 }
}
@media all and (min-width:768px) {
 .template-index .ssw-html-widget.ssw-instagram-widget>div:first-child:after,
 .template-index .ssw-html-widget.ssw-instagram-widget>div:first-child:before {
  width:60px
 }
 .template-index .ssw-instagram-grid-title {
  margin:0 30px
 }
}

.cat_grid_item__overlay,.hoverz_true .nt_promotion>a {
  -webkit-transition: .6s ease-in-out;
  -moz-transition: .6s ease-in-out;
  -o-transition: .6s ease-in-out;
  transition: .6s ease-in-out
}

.hoverz_true .cat_grid_item:hover .cat_grid_item__overlay,.hoverz_true .nt_promotion:hover>a {
  transform: scale(1.11);
  -webkit-transform: scale(1.11);
  -o-transform: scale(1.11);
  -moz-transform: scale(1.11)
}

.cat_grid_item__overlay.lazyloadt4sed,.nt_bg_lz.pr_lazy_img.lazyloadt4sed,.p-nav.p-nav-ready,.pr_lazy_img.main-img.lazyloadt4sed,.swatch__value.lazyloadt4sed,.swatch__value_pr.lazyloadt4sed,img.lz_op_ef.lazyloadt4sed {
  -webkit-animation: 1.25s cubic-bezier(.26,.54,.32,1) forwards ani-fadeIn;
  animation: 1.25s cubic-bezier(.26,.54,.32,1) forwards ani-fadeIn
}
}



@media (min-width: 1025px){
  .p-nav .n-item{
    transform: none !important;
  }
  .p-nav .flickity-slider{
    transform: none !important;
  }
}