// /------Review Slider Section Start --------/
.app-reviews__wrapper{
.review-slider{
    overflow-x: clip;
  }
  .carousel-control-prev-icon {
    color: #498352 !important;
  }
  
  .carousel-control-prev {
    color: #498352 !important;
  }
  
  .carousel-inner {
    background-color: transparent;
  }
  
  .review-slider .carousel-inner {
    overflow: inherit;
  }
  
  .review-slider .carousel-control.left,
  .review-slider .carousel-control.right {
    background-image: none;
  }
  
  .review-slider .review-box {
    background: #cfe4d2;
    text-align: center;
    padding: 40px 30px;
    border-radius: 10px;
    // margin-bottom: 25px;
    height: 100%;
  }
  
  .carousel-item {
    background-color: #cfe4d2;
  }
  
  .review-slider .review-box p {
    text-align: left;
    font-size: 27px;
    margin-top: 70px;
    min-height: 140px;
    text-align: center;
    color: black;
  }
  
  .review-slider .review-box .checked {
    color: orange;
    font-size: 30px;
  }
  
  .review-slider .review-box .name {
    display: block;
    font-weight: bold;
    font-size: 25px;
    padding-top: 10px;
    font-family: "playfair display";
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    i {
      font-size: 40px;
      color: #498352;
    }
  }
  
  .review-slider .review-box:before {
    content: "\201c";
    position: absolute;
    font-size: 118px;
    left: 45px;
    top: -80px;
    font-family: "FontAwesome";
    font-weight: bold;
    color: #498352;
  }
}
  
  @media (min-width: 768px) {
    .app-reviews__wrapper{
      .carousel-indicators {
        bottom: 0px;
      }
    }
  }
  
  // /------Review Slider Section End --------
  .app-reviews__wrapper{
    .review-heading {
      padding-bottom: 30px;
    }
    
    .reviews-content {
      font-family: Work Sans;
      font-size: 35px;
      font-weight: 400;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: left;
    }
    
    .active {
      color: black;
    }
  }
  
  
  @media only screen and (min-width: 768px) {
    .app-reviews__wrapper{
      .carousel-control-prev {
        margin-left: -120px ;
      }
      
      .carousel-control-next {
        margin-right: -120px;
      }
    }
  }