.header {
  box-shadow: 0px 0px 8px -1px #9e9e9e;
  display: flex;
  position: sticky;
  z-index: 2;
  top: 0;
  align-items: center;
  background-color: #f9f9f9;
  justify-content: space-around;
}

.leftHeaderContent {
  font-size: 30px;
}

.rightHeaderContent {
  list-style: none;
  display: flex;
  gap: 20px;
  font-size: 20px;
}

.rightHeaderContent a {
  text-decoration: none;
  color: inherit;
}

.rightHeaderContent a:hover {
  padding: 10px 0;
  color: darkgray;
  border-bottom-width: 19px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

@media (max-width: 782px) {
  .header {
    flex-direction: column;
    height: auto;
  }
}
