@import "../../css/home.css";
@import "../../css/photoDeliveryPage.css";
@import "../../css/paymentPictureSection";
@import "../../css/countingSection.css";
@import "../../css/photoPrintingPageNew.css";
@import "../../css/photoPrintingSection.css";
// import photo from "../../images/images/passport-size-photos-delivery-dubai.jpg";


.wrapper-banner{
  .homeSectionLeftContent .orderButton {
    
    width: max-content;
    z-index: 1;
    
    // background-color: #2b2626;
    color:white;
    font-size: 22px;
    border-radius: 10px;
    background-image: linear-gradient(to right bottom, #ffb30b, #167bf5);
    
    padding: 20px;
    // border-color: blueviolet;
    border: none;
    outline: none;
  }

  .active,
  .dot:hover {
    background-color: transparent !important;
  }

  .rules {
    padding: 55px;

  }
  h2 {
    font-weight: bold;
    font-family: "playfair display"
  }

  .homeSection::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    // background-color: rgb(0 0 0 / 36%);
    background-color: transparent !important;
  }

  .homeSectionLeftContent {
    color: #444 !important;
  }

  .homeSectionLeftContent{
    color: #fff;
    z-index: 1;
    margin: auto 0;
    padding: 70px;
  }

  .description {
    h2 {
      font-size: 23px;
    }
  }

  ::ng-deep {
    .brands-container {
      padding: 0 !important;
    }
  }
  .faqHeading {
    padding-left: 18px;
  }
}

.servics-tittle{
  .container{
    padding-top: 20px;
    padding-bottom:20px;
  }

  .p-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .free-shipping-extra-bold {
    font-family: "Playfair Display", serif;
    font-size: 32px;
    font-weight: 800 !important;
    line-height: 43px;
    letter-spacing: 0em;
  }
  .free-shipping-medium {
    font-family: "Playfair Display", serif;
    font-size: 32px;
    font-weight: 500 !important;
    line-height: 43px !important;
    letter-spacing: 0em !important;
  }
  
  .free-shipping-sub-heading {
    font-family: "Playfair Display", serif;
    font-size: 16px;
    font-weight: 800 !important;
    line-height: 21px;
    letter-spacing: 0em;
  }
}

