.homeSectionPassport {
  position: relative;
  height: 100vh;
  width: 100%;
  justify-content: left;
  display: flex;
  align-items: center;
  background-image: url("../images/images/online-photo-studio.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0px;
}

.homeSection::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgb(0 0 0 / 36%);
}

.homeSectionLeftContent {
  color: white;
  z-index: 1;
  margin: auto 0;
  padding: 70px;
}

.homeSectionLeftContent .heading {
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 63.75px;
  line-height: 57px;
  /* display: flex; */
  flex-direction: column;
  z-index: 1;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.homeSectionRightContent {
  z-index: 1;
  animation: 3s slideInFromLeft;
}

.homeSectionLeftContent .description {
  font-size: 25px;
  line-height: 28px;
  margin-top: 17px;
  margin-bottom: 39px;
  font-weight: 400;
  z-index: 1;
}

.homeSectionLeftContent .orderButton {
  width: max-content;
  z-index: 1;
  padding: 15px;
  /* border: 1px solid white; */
  background-color: #2b2626;
  color: white;
  font-size: 22px;
  border-radius: 5px;
  background-image: linear-gradient(
    to right bottom,
    rgb(255, 16, 83),
    rgb(52, 82, 255)
  );
  padding: 20px;
  border-color: blueviolet;
}

.homeContext {
  display: flex;
}

.homeSectionLeftContent .orderButton:hover {
  background-color: #0000008a;
  cursor: pointer;
}

.homeSectionRightImage {
  height: 570px;
}

@media (max-width: 815px) {
  .homeSectionRightImage {
    height: 415px;
  }
}

@media (max-width: 450px) {
  .serviceSectionLeftContent .heading {
    font-weight: 500;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .serviceSectionLeftContent p {
    font-size: 14px;
  }
}

@media (max-width: 782px) {
  .homeSectionPassport {
    height: 100%;
  }
  .homeSectionLeftContent .orderButton {
    width: max-content;
    z-index: 1;
    padding: 15px;
    border: 1px solid white;
    background-color: #2b2626;
    color: white;
    font-size: 20px;
  }
  .homeSectionLeftContent .description {
    font-size: 17px;
    line-height: 28px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 15px;
    font-weight: 400;
    z-index: 1;
  }
  .homeSectionLeftContent .heading {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 40.75px;
    line-height: 45px;
  }
  .homeSectionPassport {
    flex-direction: column;
    z-index: 1;
    padding-top: 70px;
  }
  .homeSectionRightContent {
    display: flex;
    justify-content: center;
    z-index: 1;
  }
  .homeSectionLeftContent {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding: unset;
    margin: 1rem 0;
  }
  .homeSectionRightImage {
    height: 500px;
  }
}
